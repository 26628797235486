import React from 'react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import Card from 'react-bootstrap/Card';
import { LinkedIn } from '@mui/icons-material';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import IconButton from '@mui/material/IconButton';
import { OverlayTooltip } from '../HoverOver/OverlayTooltip';
import { NotAvailable } from '../HoverOver/NotAvailable';
import { Spinner } from 'react-bootstrap/';
import { OverlayStickOnHover } from '../HoverOver/OverlayStickOnHover';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import externalNotAvailable from '../../images/external-notavailable.png';
const DOMPurify = require('dompurify');

export const formatWebsite = (website) => {
    if (website.includes('http://') || website.includes('https://')) {
        return website;
    } else {
        return `//${website}`;
    }
};

export const formatRevenue = (x) => {
    if (x < 9999) {
        return '$' + x;
    } else if (x < 1000000) {
        return '$' + Math.round(x / 1000) + 'K';
    } else if (x < 10000000) {
        return '$' + (x / 1000000).toFixed(2) + 'M';
    } else if (x < 1000000000) {
        return '$' + Math.round(x / 1000000) + 'M';
    } else if (x < 10000000000) {
        return '$' + (x / 1000000000).toFixed(2) + 'B';
    } else if (x < 1000000000000) {
        return '$' + Math.round(x / 1000000000) + 'B';
    } else {
        return '$' + Math.round(x / 1000000000000) + 'T';
    }
};

export const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatDate = (date) => {
    let shortMonth = {
        '01': 'Jan', //'January',
        '02': 'Feb', //'February',
        '03': 'Mar', //'March',
        '04': 'Apr', //'April',
        '05': 'May', //'May',
        '06': 'Jun', //'June',
        '07': 'Jul', //'July',
        '08': 'Aug', //'August',
        '09': 'Sep', //'September',
        10: 'Oct', //'October',
        11: 'Nov', //'November',
        12: 'Dec', //'December'
    };

    if (!date || date.substring(0, 4) === '0001') return null;
    return `${shortMonth[date.substring(5, 7)]} ${date.substring(
        8,
        10
    )}, ${date.substring(0, 4)}`;
};

export const formatMonthYearDate = (date) => {
    let shortMonth = {
        '01': 'Jan', //'January',
        '02': 'Feb', //'February',
        '03': 'Mar', //'March',
        '04': 'Apr', //'April',
        '05': 'May', //'May',
        '06': 'Jun', //'June',
        '07': 'Jul', //'July',
        '08': 'Aug', //'August',
        '09': 'Sep', //'September',
        10: 'Oct', //'October',
        11: 'Nov', //'November',
        12: 'Dec', //'December'
    };

    if (!date || date.substring(0, 4) === '0001') return null;
    return `${shortMonth[date.substring(5, 7)]} ${date.substring(0, 4)}`;
};

export const formatYear = (date) => {
    if (!date || date.substring(0, 4) === '0001') return null;
    return date.substring(0, 4);
};

export const formatText = (text, maxlength) => {
    if (!text) return '';

    if (text.length > maxlength) {
        return text.substring(0, maxlength) + '...';
    } else {
        return text;
    }
};

export const mailSubject = () => {
    let path = '';
    if (typeof window !== 'undefined') {
        path = window.location.pathname;
    }
    let subject = '';
    if (path.includes('/prospect/') || path.includes('/company/')) {
        subject = path.substring(path.lastIndexOf('/') + 1, path.length);
    } else if (path.includes('/search')) {
        subject = 'Search Page';
    } else {
        if (path.lastIndexOf('/') === path.length - 1) subject = 'Homepage';
    }
    return subject;
};

export const formatAccountName = (name) => {
    let index =
        name.indexOf(' - (') > 0 ? name.indexOf(' - (') : name.indexOf(' (');
    let companyName = index > 0 ? name.substring(0, index) : name;
    return companyName;
};

export const getUrlLink = (type, id) => {
    let clientSideSettings = JSON.parse(
        DOMPurify.sanitize(localStorage.getItem('ClientSideSettings'))
    );
    if (!clientSideSettings) {
        clientSideSettings = {
            sfdcUrl: 'https://protiviti.my.salesforce.com/',
            sfdcLightning: 'https://protiviti.lightning.force.com/lightning/r/',
            hubUrl: 'https://dev.hub-tst.protiviti.com/client/',
            iManageUrl:
                'https://dev.imanage-tst.protiviti.com/engagements/shellEdit/',
            iManageFilterList:
                'https://dev.imanage-tst.protiviti.com/engagements/shellFilter/',
            sfdcLightningUrl:
                'https://protiviti.lightning.force.com/one/one.app',
        };
    }

    if (!id && type === 'sfdcLightningUrl')
        return clientSideSettings.sfdcLightningUrl;

    if (id !== '' && id !== null) {
        if (type === 'sfdc') {
            return id !== null ? clientSideSettings.sfdcUrl + id : null;
        }
        if (type === 'sfdclightningAcc') {
            return id !== null
                ? clientSideSettings.sfdcLightning + 'Account/' + id + '/view'
                : null;
        }
        if (type === 'sfdclightningOpp') {
            return id !== null
                ? clientSideSettings.sfdcLightning +
                      'Opportunity/' +
                      id +
                      '/view'
                : null;
        }
        if (type === 'sfdclightningCon') {
            return id !== null
                ? clientSideSettings.sfdcLightning + 'Contact/' + id + '/view'
                : null;
        }
        if (type === 'hub') {
            return id !== null ? clientSideSettings.hubUrl + id : null;
        }
        if (type === 'imanage') {
            return id !== null ? clientSideSettings.iManageUrl + id : null;
        }
        if (type === 'imanageFilter') {
            return id !== null ? clientSideSettings.iManageFilterList : null;
        }
        if (type === 'sfdcopplist') {
            return 'https://protiviti.lightning.force.com/lightning/o/Opportunity/list?filterName=Recent';
        }
    }
    return null;
};

export const provideLinkToCompanyPage = (id) => {
    //protiviti alumni accounts
    const set = new Set();
    set.add('0018000000Q7Bu1AAF');
    set.add('0018000001YXtm3AAD');
    set.add('0013000000H902aAAB');
    set.add('0013000000KQyZpAAL');

    return !set.has(id);
};

export const showModifiedDate = (value) => {
    return value.map((v) => {
        let a = Object.assign({}, v);
        a.showLastModified = true;
        return a;
    });
};

export const filterModifiedDate = (data) => {
    let priorDate = new Date(new Date().setDate(new Date().getDate() - 30)); //30 days prior to current date

    return data
        .filter((_) => {
            let date = new Date(_.modifiedDate);
            return date >= priorDate;
        })
        .sort((a, b) => {
            return new Date(b.modifiedDate) - new Date(a.modifiedDate);
        });
};

export const renderSortArrow = (order, column) => {
    if (!order) return null;
    else if (order === 'asc') return <ExpandMore />;
    else if (order === 'desc') return <ExpandLess />;
};

export const setUpLinkedinUrl = (url, fromProspect) => {
    if (url) {
        url = url.toLowerCase();
        if (url.includes('linkedin.com')) {
            if (!url.startsWith('https://'))
                var linkedinUrl = 'https://'.concat(url);
            else linkedinUrl = url;
        } else if (url.startsWith('/in/')) {
            linkedinUrl = 'https://linkedin.com/'.concat(url);
        } else linkedinUrl = null;
    } else linkedinUrl = null;

    if (linkedinUrl)
        return (
            <Card.Link
                href={linkedinUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                <LinkedIn
                    className="linkedin-icon"
                    style={
                        fromProspect
                            ? { fontSize: 38, marginLeft: '5px' }
                            : { width: '1em', height: '1em' }
                    }
                />
            </Card.Link>
        );
    else return null;
};

export const renderOutlook = (email) => {
    return (
        <a href={`mailto:${email}`}>
            <svg
                className="connection-icons"
                alt="Outlook"
                viewBox="-0.12979372698077785 0 33.25199671942124 32"
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
            >
                <path
                    d="M28.596 2H11.404A1.404 1.404 0 0 0 10 3.404V5l9.69 3L30 5V3.404A1.404 1.404 0 0 0 28.596 2z"
                    fill="#0364b8"
                />
                <path
                    d="M31.65 17.405A11.341 11.341 0 0 0 32 16a.666.666 0 0 0-.333-.576l-.013-.008-.004-.002L20.812 9.24a1.499 1.499 0 0 0-.145-.083 1.5 1.5 0 0 0-1.334 0 1.49 1.49 0 0 0-.145.082L8.35 15.415l-.004.002-.012.007A.666.666 0 0 0 8 16a11.344 11.344 0 0 0 .35 1.405l11.492 8.405z"
                    fill="#0a2767"
                    fill="#0a2767"
                />
                <path d="M24 5h-7l-2.021 3L17 11l7 6h6v-6z" fill="#28a8ea" />
                <path d="M10 5h7v6h-7z" fill="#0078d4" />
                <path d="M24 5h6v6h-6z" fill="#50d9ff" />
                <path d="M24 17l-7-6h-7v6l7 6 10.832 1.768z" fill="#0364b8" />
                <path d="M10.031 5H30" fill="none" />
                <path d="M17 11h7v6h-7z" fill="#0078d4" />
                <path d="M10 17h7v6h-7z" fill="#064a8c" />
                <path d="M24 17h6v6h-6z" fill="#0078d4" />
                <path
                    d="M20.19 25.218l-11.793-8.6.495-.87s10.745 6.12 10.909 6.212a.528.528 0 0 0 .42-.012l10.933-6.23.496.869z"
                    fill="#0a2767"
                    opacity=".5"
                />
                <path
                    d="M31.667 16.577l-.014.008-.003.002-10.838 6.174a1.497 1.497 0 0 1-1.46.091l3.774 5.061 8.254 1.797v.004A1.498 1.498 0 0 0 32 28.5V16a.666.666 0 0 1-.333.577z"
                    fill="#1490df"
                />
                <path
                    d="M32 28.5v-.738l-9.983-5.688-1.205.687a1.497 1.497 0 0 1-1.46.091l3.774 5.061 8.254 1.797v.004A1.498 1.498 0 0 0 32 28.5z"
                    opacity=".05"
                />
                <path
                    d="M31.95 28.883L21.007 22.65l-.195.11a1.497 1.497 0 0 1-1.46.092l3.774 5.061 8.254 1.797v.004a1.501 1.501 0 0 0 .57-.83z"
                    opacity=".1"
                />
                <path
                    d="M8.35 16.59v-.01h-.01l-.03-.02A.65.65 0 0 1 8 16v12.5A1.498 1.498 0 0 0 9.5 30h21a1.503 1.503 0 0 0 .37-.05.637.637 0 0 0 .18-.06.142.142 0 0 0 .06-.02 1.048 1.048 0 0 0 .23-.13c.02-.01.03-.01.04-.03z"
                    fill="#28a8ea"
                />
                <path
                    d="M18 24.667V8.333A1.337 1.337 0 0 0 16.667 7H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 0 0 8 16v.005V16 26h8.667A1.337 1.337 0 0 0 18 24.667z"
                    opacity=".1"
                />
                <path
                    d="M17 25.667V9.333A1.337 1.337 0 0 0 15.667 8H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 0 0 8 16v.005V16 27h7.667A1.337 1.337 0 0 0 17 25.667z"
                    opacity=".2"
                />
                <path
                    d="M17 23.667V9.333A1.337 1.337 0 0 0 15.667 8H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 0 0 8 16v.005V16 25h7.667A1.337 1.337 0 0 0 17 23.667z"
                    opacity=".2"
                />
                <path
                    d="M16 23.667V9.333A1.337 1.337 0 0 0 14.667 8H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 0 0 8 16v.005V16 25h6.667A1.337 1.337 0 0 0 16 23.667z"
                    opacity=".2"
                />
                <path
                    d="M1.333 8h13.334A1.333 1.333 0 0 1 16 9.333v13.334A1.333 1.333 0 0 1 14.667 24H1.333A1.333 1.333 0 0 1 0 22.667V9.333A1.333 1.333 0 0 1 1.333 8z"
                    fill="#0078d4"
                />
                <path
                    d="M3.867 13.468a4.181 4.181 0 0 1 1.642-1.814A4.965 4.965 0 0 1 8.119 11a4.617 4.617 0 0 1 2.413.62 4.14 4.14 0 0 1 1.598 1.733 5.597 5.597 0 0 1 .56 2.55 5.901 5.901 0 0 1-.577 2.666 4.239 4.239 0 0 1-1.645 1.794A4.8 4.8 0 0 1 7.963 21a4.729 4.729 0 0 1-2.468-.627 4.204 4.204 0 0 1-1.618-1.736 5.459 5.459 0 0 1-.567-2.519 6.055 6.055 0 0 1 .557-2.65zm1.75 4.258a2.716 2.716 0 0 0 .923 1.194 2.411 2.411 0 0 0 1.443.435 2.533 2.533 0 0 0 1.541-.449 2.603 2.603 0 0 0 .897-1.197 4.626 4.626 0 0 0 .286-1.665 5.063 5.063 0 0 0-.27-1.686 2.669 2.669 0 0 0-.866-1.24 2.387 2.387 0 0 0-1.527-.473 2.493 2.493 0 0 0-1.477.439 2.741 2.741 0 0 0-.944 1.203 4.776 4.776 0 0 0-.007 3.44z"
                    fill="#fff"
                />
                <path d="M0 0h32v32H0z" fill="none" />
            </svg>
        </a>
    );
};

export const triggerFilter = (showFilters, setShowFilters) => {
    return (
        <OverlayTrigger
            key="top"
            placement="top"
            overlay={
                <Tooltip id="tooltip-top">
                    {showFilters ? 'Hide Filters' : 'Show Filters'}
                </Tooltip>
            }
        >
            <IconButton
                onClick={() => setShowFilters(!showFilters)}
                style={{
                    border: 'none',
                    outline: 'none',
                    borderRadius: '0',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                }}
                size="large"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="25px"
                    height="25px"
                >
                    <path
                        fill="#004068"
                        d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"
                    ></path>
                </svg>
            </IconButton>
        </OverlayTrigger>
    );
};

export const handleOnFilterTable = (filterArr, data, key) => {
    // custom filter logic for react-bootstrap-table2 -> accounts for Not Available
    if (filterArr.length !== 0) {
        return data.filter((row) => {
            // for Project Year Ended
            if (key === 'endedDate') {
                let value = row['endedDate'];
                let filterValue = filterArr.find((val) => {
                    if (
                        val === 'Ongoing' ||
                        val === 'On Hold' ||
                        val === 'Hold' ||
                        val === 'SIP'
                    ) {
                        value =
                            row.projectStatus !== 'Closed'
                                ? row.projectStatus
                                : value;
                        value = value === 'Active' ? 'Ongoing' : value; // if Active, value = 'Ongoing'
                    }
                    return val === value || value.includes(val);
                });
                return value === filterValue || value.includes(filterValue);
            } else if (key === 'source') {
                // for Interaction source
                let filterValue = filterArr.find((val) => {
                    return formatSourceReverse(val) === row[key];
                });
                return row[key] === formatSourceReverse(filterValue);
            } else if (key === 'connectionType') {
                // for Interaction type
                let filterValue = filterArr.find((val) => {
                    return formatTypeReverse(val) === row[key];
                });
                return row[key] === formatTypeReverse(filterValue);
            } else {
                // all other cases
                let filterValue = filterArr.find((val) => {
                    return val === row[key];
                });
                return row[key] === filterValue;
            }
        });
    }
    return data;
};

export const formatEndYear = (row) => {
    if (
        row.projectStatus === 'Closed' &&
        row.endedDate.substring(0, 4) !== '0001'
    ) {
        return row.endedDate.substring(0, 4);
    } else if (row.projectStatus === 'Active' || row.projectStatus === 'Open') {
        return 'Ongoing';
    } else if (row.projectStatus !== null && row.projectStatus !== 'Closed') {
        // SIP, On Hold, Hold
        return row.projectStatus;
    } else {
        return null;
    }
};

export const formatType = (value) => {
    // Microsoft Outlook
    if (value === 'Message') return 'Outlook Message';
    if (value === 'EventMessage') return 'Outlook Event';
    if (!value) return 'Not Available';
    return value;
};

export const formatSource = (value) => {
    if (value === '#Microsoft.Outlook') return 'Microsoft Outlook';
    if (value === 'Task' || value === 'Event') return 'Salesforce ' + value;

    return value;
};

export const formatTypeReverse = (value) => {
    if (value === 'Outlook Message') return 'Message';
    else if (value === 'Outlook Event') return 'EventMessage';
    else if (value === 'Not Available') return null;
    else return value;
};

export const formatSourceReverse = (value) => {
    if (value === 'Microsoft Outlook') return '#Microsoft.Outlook';
    else if (value === 'Salesforce Event') return 'Event';
    else if (value === 'Salesforce Task') return 'Task';
    else return value;
};

export const asteriskHoverover = (value, nonProtivitiEmployee) => {
    // nonProtivitiEmployee === true -> Non Protiviti Employees, nonProtivitiEmployee === false -> Protiviti Employee
    let tooltipText = nonProtivitiEmployee
        ? '* indicates "This user is an inactive contact within the Protiviti systems"'
        : '* indicates "This user is no longer with Protiviti"';
    return (
        <OverlayTooltip
            tooltipText={tooltipText}
            hoverElement={<span style={{ cursor: 'pointer' }}>{value}</span>}
            imageType="none"
        />
    );
};

export const getFormatCompanyLink = (id, name) => {
    let element = <div className="fields-style">{formatText(name, 40)}</div>;

    return formatText(name, 40).length > 40 ? (
        <OverlayTooltip
            tooltipText={name}
            hoverElement={element}
            imageType="none"
        />
    ) : (
        element
    );
};

export const getOnStaticOptionsUpdate = (
    staticColumn,
    filterKey,
    noFilters,
    staticOption
) => {
    if (noFilters) {
        let options = { ...staticOption };
        delete options[filterKey];
        return options;
    } else if (!staticOption.hasOwnProperty(filterKey)) {
        return { ...staticOption, ...{ [filterKey]: staticColumn } };
    }
};

export const getRelationshipStyle = (relationship) => {
    let color = '#00acc4';
    switch (relationship) {
        case 'A-List':
            color = '#329A3D';
            break;
        case 'QBR':
            color = '#CFA915';
            break;
        case 'RBC':
            color = '#E77B1B';
            break;
        case 'NR':
            color = '#D44622';
            break;
        case 'TR':
            color = '#2DA8B5';
            break;
        default:
            color = '#00acc4';
    }
    return color;
};

export const formatLastContacted = (date, type) => {
    //2020-02-07 00:00:00.0000000 -> Feb 2020 format
    const monthShort = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]; // for LastContacted date
    if (date && type) {
        return `${formatType(type)}, ${
            monthShort[date.substring(5, 7) - 1]
        } ${date.substring(0, 4)}`;
    } else if (date && !type) {
        return `${monthShort[date.substring(5, 7) - 1]} ${date.substring(
            0,
            4
        )}`;
    } else {
        return (
            <NotAvailable section="internal-field" showNotAvailable={true} />
        );
    }
};

export const renderTaggedRelationship = (relationShipStrength) => {
    return (
        <div
            className="relationship-tag"
            style={{
                backgroundColor: getRelationshipStyle(relationShipStrength),
                borderColor: getRelationshipStyle(relationShipStrength),
            }}
        >
            {relationShipStrength}
        </div>
    );
};

export const renderLoadingSpinner = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    );
};

export const mapValidationField = (field, isAccountType) => {
    let prospectMapping = {
        emailAddressExternal: 0,
        phoneExternal: 1,
        jobTitleExternal: 2,
    };

    let accountMapping = {};

    if (isAccountType) return accountMapping[field];
    return prospectMapping[field];
};

export const renderRestriction = (type) => {
    // MSA/Contact Restriction
    // Legal Sanction/OFAC

    let hoverText = null;
    let innerElement = null;

    if (type === 'msa') {
        hoverText = 'MSA/Contact Restrictions on Account';
        innerElement = (
            <div>
                Please reach out to Account MD if you would like to reach out to
                this contact.
            </div>
        );
    } else if (type === 'sanction') {
        hoverText = 'Legal Sanctions/OFAC on Account';
        innerElement = (
            <div>
                The company listed above or an individual or affiliates
                associated with the company are currently subject to trade
                sanctions by the U.S. or other countries. Trade sanctions
                prohibit direct or indirect contact with the companies or
                individuals subject to sanctions, or otherwise limit the ability
                to interact with such individuals or companies. DNU until
                further notice. If you have questions, please contact the Legal
                Department at +1-650-234-6000. More information on Robert Half's
                internal Global Sanctions Checks Program can be found on RH
                CONNECT at{' '}
                <a
                    href="https://roberthalf.sharepoint.com/sited/shared/workbench/hr-processes/Pages/global-sanctions-checks.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://roberthalf.sharepoint.com/sited/shared/workbench/hr-processes/Pages/global-sanctions-checks.aspx
                </a>
            </div>
        );
    }

    if (!hoverText || !innerElement) return null;
    return (
        <OverlayStickOnHover
            placement="bottom"
            onMouseEnter={() => {}}
            delay={200}
            component={<div>{innerElement}</div>}
        >
            <span className="restrictions">
                <ReportProblemOutlinedIcon fontSize="small" /> {hoverText}
            </span>
        </OverlayStickOnHover>
    );
};

export const renderExternalNotAvailable = (component) => {
    // component must be either: 'account' or 'contact'

    return (
        <div className="not-available-background">
            <div className="not-available-circle">
                {' '}
                <img
                    className="not-available-img"
                    src={externalNotAvailable}
                    alt={'not-available'}
                />{' '}
            </div>
            This {component} is not stored within Protiviti systems, therefore
            this section will not be populated. <br /> If you would like to add
            this {component} to our internal system, please visit{' '}
            <a
                href="https://protiviti.lightning.force.com/one/one.app"
                target="_blank"
                rel="noopener noreferrer"
            >
                {' '}
                Salesforce
            </a>
            .
        </div>
    );
};

export const checkAccountType = (accountType) => {
    return (
        accountType && (accountType === 'Key' || accountType === 'Strategic')
    );
};
