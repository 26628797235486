import React from 'react';
import '../Prospect/Overview.css';
import { getUrlLink, asteriskHoverover } from '../Shared/Util';
import { EmployeeLinks } from '../Shared/EmployeeLinks';
import { NotAvailable } from '../HoverOver/NotAvailable';

export const EmployeeDetails = (props) => {
    return (
        <span>
            {props.data && props.data.name ? (
                <span>
                    {!props.data.isTerminated ? (
                        <span>
                            <span>{props.data.name}</span>
                            {(props.data.title &&
                            props.data.title.indexOf(' (') > 0
                                ? ', ' +
                                  props.data.title.substring(
                                      0,
                                      props.data.title.indexOf(' (')
                                  )
                                : props.data.title) +
                                (props.data.title && props.data.city
                                    ? ', '
                                    : '') +
                                (props.data.city
                                    ? props.data.city + ' Office'
                                    : '')}
                            <div>
                                {' '}
                                <EmployeeLinks
                                    data={props.data}
                                    isPeopleSearchLive={
                                        props.isPeopleSearchLive
                                    }
                                    peopleSearchUrl={encodeURI(
                                        props.peopleSearchUrl
                                    )}
                                />{' '}
                            </div>
                        </span>
                    ) : (
                        <span>{asteriskHoverover(props.data.name, false)}</span>
                    )}
                </span>
            ) : props.isPMO ? (
                <NotAvailable section="internal" showNotAvailable={true} />
            ) : (
                <NotAvailable
                    section="internal-field"
                    showNotAvailable={true}
                    linkUrl={getUrlLink('sfdclightningAcc', props.id)}
                />
            )}
        </span>
    );
};
