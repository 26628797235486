import React, { useEffect, useState } from 'react';
import { App } from './App';
import config from './config';
import { authHeaders } from './auth/authHeaders';
import axios from 'axios';

function TimerRoot(props) {
    const [render, setRender] = useState(false);
    const [authData, setAuthData] = useState(null);

    useEffect(() => {
        var ClientSideSettings = localStorage.getItem('ClientSideSettings');
        if (!ClientSideSettings) {
            getConfig();
        } else {
            var Parsed = JSON.parse(ClientSideSettings);
            if (!Parsed['ConfigDate'] || Parsed['ConfigDate'] < Date.now())
                getConfig();
            else {
                setRender(true);
            }
        }

        let auth = {
            authenticationState: props.authenticationState,
            accountInfoUserName:
                props.accountInfo && props.accountInfo.account
                    ? props.accountInfo.account.userName
                    : props.accountInfo,
        };
        setAuthData(auth);
    }, []);

    const getConfig = async () => {
        let configUrl = config.azRedirectUri + '/api/config';
        let headers = await authHeaders();
        await axios.get(configUrl, headers).then((response) => {
            let data = response.data;
            data['ConfigDate'] = Date.now() + 86400000; //Now plus 24 hours - Only call Config file once a day and store in localStorage
            localStorage.setItem('ClientSideSettings', JSON.stringify(data));
            setRender(true);
        });
    };

    return <>{render ? <App authData={authData} /> : null}</>;
}
export default TimerRoot;
