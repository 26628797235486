import PowerBIcon from '../../images/powerbi-icon.png';
import config from '../../config';
import { OverlayStickOnHover } from '../HoverOver/OverlayStickOnHover';
import Button from 'react-bootstrap/Button';
const PremierRelationships = () => {
    return (
        <OverlayStickOnHover
            placement="bottom"
            onMouseEnter={() => {}}
            delay={200}
            component={
                <div>
                    Your access to this dashboard may be limited given your role
                    and region.
                </div>
            }
        >
            <Button
                className="hubBtn premierRelationships"
                style={{
                    float: 'right',
                    marginBottom: '10px',
                    fontSize: '90%',
                    marginLeft: '10px',
                }}
                href={config.premierRelationshipsLink}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    className="social-media-margin"
                    style={{
                        height: '25px',
                        borderRadius: '5px',
                        marginRight: '5px',
                    }}
                    src={PowerBIcon}
                />
                <p style={{ maxWidth: 'min-content' }}>
                    <span className="bi-subtitle">Premier Relationships</span>
                </p>
            </Button>
        </OverlayStickOnHover>
    );
};
export default PremierRelationships;
