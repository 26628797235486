import React, { useEffect, useState, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { GlobalContext } from '../src/context/GlobalState';
import config from '../src/config';
import { authHeaders } from '../src/auth/authHeaders';
import axios from 'axios';

const PrivateRoute = ({ children }) => {
    const { setAuthorized, setUserEmail, authentication, setIsProspectPage } =
        useContext(GlobalContext);
    const [authorizedState, setAuthorizedState] = useState(false);
    let location = useLocation();

    useEffect(() => {
        if (location) {
            setIsProspectPage(location.pathname.includes('/prospect/'));
        }
    }, [location]);

    useEffect(() => {
        if (authentication !== null) {
            getUser();
        }
    }, [authentication]);

    const getUser = async () => {
        let url = config.azRedirectUri + '/api/user';
        let headers = await authHeaders();
        await axios
            .get(url, headers)
            .then((response) => {
                setUserEmail(response.data);
                setAuthorized(true);
                setAuthorizedState(true);
            })
            .catch((error) => {
                // 403 Forbidden & 500
                setAuthorized(false);
                setAuthorizedState(false);
            });
    };

    return authentication &&
        authentication.authenticationState === 'Authenticated' ? (
        children
    ) : (
        <Navigate to="/unauthorized" />
    );
};

export default PrivateRoute;
