const hostname = window && window.location && window.location.hostname;
var mailBody =
    'This issue occurred here: (url). Please describe your issue below: '; //this includes url so that it can be replaced with the url of current page in the header component

var backendHost = `https://${hostname}`;
if (hostname === 'localhost') {
    backendHost = 'https://localhost:44356';
}

const config = {
    azRedirectUri: backendHost,
    supportEmail: 'ProConnectSupport@Protiviti.com',
    supportMailSubject: 'ProConnect Issue - ',
    supportMailBody: mailBody,
    requestEnhancedDataLink: 'https://forms.office.com/r/MQxP7Ffnzd',
    premierRelationshipsLink:
        'https://app.powerbi.com/groups/me/apps/76942b9a-dacb-489d-84cf-d546fa52b962/reports/232b11eb-b647-45a6-aa79-1f90e0854f11/ReportSection2bb27f049b308e501e42?bookmarkGuid=Bookmark54f1f79a390d9d6509a9',
};

module.exports = config;
