import React, { Component, Fragment } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './TelemetryService';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
    state = {
        initialized: false,
    };

    componentDidMount() {
        const { history } = this.props;
        const { initialized } = this.state;
        const appInsightsInstrumentationKey = this.props.instrumentationKey; // PUT YOUR KEY HERE
        const authData = this.props.authData;
        if (
            Boolean(authData) &&
            !Boolean(initialized) &&
            Boolean(appInsightsInstrumentationKey) &&
            Boolean(history)
        ) {
            ai.initialize(authData, appInsightsInstrumentationKey, history);
            this.setState({ initialized: true });
        }

        this.props.after();
    }

    render() {
        const { children } = this.props;
        return <Fragment>{children}</Fragment>;
    }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);
