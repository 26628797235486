import React, { useEffect, useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import './Overview.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from '@musicstory/react-bootstrap-table-next';
import paginationFactory from '@musicstory/react-bootstrap-table2-paginator';
import filterFactory, {
    customFilter,
    FILTER_TYPES,
} from '@musicstory/react-bootstrap-table2-filter';
import { VpnKeyOutlined } from '@mui/icons-material';
import { NotAvailable } from '../HoverOver/NotAvailable';
import { OverlayTooltip } from '../HoverOver/OverlayTooltip';
import {
    getUrlLink,
    formatDate,
    formatText,
    renderSortArrow,
    triggerFilter,
    handleOnFilterTable,
    asteriskHoverover,
    getOnStaticOptionsUpdate,
    renderExternalNotAvailable,
} from '../Shared/Util';
import { FilterMultiSelect } from '../Shared/FilterMultiSelect';

export const KeyBuyerHistory = () => {
    const { prospect } = useContext(GlobalContext);
    const [showMore, setShowMore] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [showFiltersModal, setShowFiltersModal] = useState(true);
    const [clientSideSettings] = useState(
        JSON.parse(localStorage.getItem('ClientSideSettings'))
    );
    const [currentFilters, setCurrentFilters] = useState({});
    const [staticOption, setStaticOption] = useState({});
    const [filteredData, setFilteredData] = useState([]);

    let nameFilter,
        companyFilter,
        dateFilter,
        solutionFilter,
        mdFilter,
        stageFilter;

    useEffect(() => {
        if (prospect) {
            setFilteredData(prospect.primaryKeyBuyerOf);
            if (
                prospect.primaryKeyBuyerOf &&
                prospect.primaryKeyBuyerOf.length < 2
            ) {
                setShowFilters(!showFilters);
            }
        }
    }, [prospect]);

    const clearAllFilters = () => {
        nameFilter([]);
        companyFilter([]);
        dateFilter([]);
        solutionFilter([]);
        mdFilter([]);
        stageFilter([]);

        setCurrentFilters({});
        setStaticOption({});
    };

    const onStaticOptionsUpdate = (staticColumn, filterKey, noFilters) => {
        if (noFilters || !staticOption.hasOwnProperty(filterKey))
            setStaticOption(
                getOnStaticOptionsUpdate(
                    staticColumn,
                    filterKey,
                    noFilters,
                    staticOption
                )
            );
    };

    const afterFilter = (newResult, newFilters) => {
        setCurrentFilters((prevData) => {
            if (JSON.stringify(prevData) !== JSON.stringify(newFilters)) {
                return newFilters;
            }
            return prevData;
        });

        setFilteredData((prevData) => {
            if (JSON.stringify(prevData) !== JSON.stringify(newResult)) {
                return newResult;
            }
            return prevData;
        });
    };

    const tableColumns = [
        {
            dataField: 'name',
            text: 'Opportunity',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (
                <React.Fragment>
                    {formatText(value, 30).length > 30 ? (
                        <OverlayTooltip
                            tooltipText={value}
                            hoverElement={
                                <Card.Link
                                    className="text-primary"
                                    href={encodeURI(
                                        `${clientSideSettings.sfdcLightningOpportunity}${row.id}/view`
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {formatText(value, 30)}
                                </Card.Link>
                            }
                        />
                    ) : (
                        <Card.Link
                            className="text-primary"
                            href={encodeURI(
                                `${clientSideSettings.sfdcLightningOpportunity}${row.id}/view`
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {formatText(value, 30)}
                        </Card.Link>
                    )}
                </React.Fragment>
            ),
            headerStyle: { fontWeight: '500', width: '180px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    nameFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'name'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={prospect.primaryKeyBuyerOf}
                    filterKey={'name'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'companyName',
            text: 'Company Name',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    formatText(value, 30).length > 30 ? (
                        <OverlayTooltip
                            tooltipText={value}
                            hoverElement={<span>{formatText(value, 30)}</span>}
                        />
                    ) : (
                        formatText(value, 30)
                    )
                ) : (
                    <NotAvailable
                        section="internal-field"
                        linkUrl={getUrlLink('sfdclightningOpp', row.id)}
                        showNotAvailable={true}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '180px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    companyFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'companyName'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={prospect.primaryKeyBuyerOf}
                    filterKey={'companyName'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'opportunityCloseDate',
            text: 'Closed Date',
            formatter: (value, row, rowIndex) =>
                value && value.substring(0, 4) !== '0001' ? (
                    formatDate(value)
                ) : (
                    <NotAvailable
                        section="internal-field"
                        showNotAvailable={true}
                    />
                ),
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '130px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    dateFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(
                        filterArr,
                        data,
                        'opportunityCloseDate'
                    ),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={prospect.primaryKeyBuyerOf}
                    filterKey={'opportunityCloseDate'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    format={'date'}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'solution',
            text: 'Solution',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable
                        section="internal-field"
                        linkUrl={getUrlLink('sfdclightningOpp', row.id)}
                        showNotAvailable={true}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '130px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    solutionFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'solution'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={prospect.primaryKeyBuyerOf}
                    filterKey={'solution'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'opportunityManagingDirector',
            text: 'Opportunity MD/D',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    row.opportunityManagingDirectorIsDeleted ? (
                        asteriskHoverover(value, false)
                    ) : (
                        value
                    )
                ) : (
                    <NotAvailable
                        section="internal-field"
                        linkUrl={getUrlLink('sfdclightningOpp', row.id)}
                        showNotAvailable={true}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '130px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    mdFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(
                        filterArr,
                        data,
                        'opportunityManagingDirector'
                    ),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={prospect.primaryKeyBuyerOf}
                    filterKey={'opportunityManagingDirector'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'opportunityStage',
            text: 'Stage',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable
                        section="internal-field"
                        linkUrl={getUrlLink('sfdclightningOpp', row.id)}
                        showNotAvailable={true}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '130px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    stageFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'opportunityStage'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={prospect.primaryKeyBuyerOf}
                    filterKey={'opportunityStage'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'id',
            hidden: true,
        },
    ];

    const handleShowMore = () => {
        setShowMore(!showMore);
        clearAllFilters();
    };

    const showMoreModal = () => {
        return (
            <Modal
                show={showMore}
                onHide={handleShowMore}
                dialogClassName="custom-dialog"
            >
                <Modal.Header
                    closeButton
                    className="card-style"
                    style={{ borderTopColor: '#00A28F' }}
                >
                    <Modal.Title>
                        <h4 className="card-title-modal">
                            <div className="contact-name">{prospect.name} </div>
                            <span>
                                <VpnKeyOutlined fontSize="large" /> Key Buyer
                                History
                            </span>
                        </h4>
                    </Modal.Title>
                    <div className="trigger-filter-modal">
                        <div className="filter-button">
                            <div>
                                {triggerFilter(
                                    showFiltersModal,
                                    setShowFiltersModal
                                )}
                            </div>
                            <div className="clear-filter">
                                <div
                                    className="text-primary"
                                    onClick={clearAllFilters}
                                    style={{
                                        visibility: showFiltersModal
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                >
                                    Clear Filters
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sfdc-contact-owner">
                        <div className="employee-name">
                            Total # of Opps:{' '}
                            {prospect.numberOfPrimaryKeyBuyerOf}
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body
                    style={{
                        overflowX: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingTop: '0px',
                        paddingBottom: '80px',
                    }}
                >
                    {
                        <BootstrapTable
                            keyField="id"
                            data={prospect.primaryKeyBuyerOf}
                            columns={tableColumns}
                            striped={true}
                            bordered={false}
                            hover={true}
                            filter={filterFactory({
                                afterFilter,
                            })}
                        />
                    }
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <Card>
            <Card.Body
                className="card-style"
                style={{
                    borderTopColor: '#00A28F',
                    overflowY: 'hidden',
                    overflowX:
                        prospect.primaryKeyBuyerOf &&
                        prospect.primaryKeyBuyerOf.length > 0
                            ? 'auto'
                            : 'none',
                }}
            >
                <div className="space-between">
                    <h4 className="card-title">
                        <div className="contact-name">{prospect.name} </div>
                        <span>
                            <VpnKeyOutlined fontSize="large" /> Key Buyer
                            History{' '}
                        </span>
                        {(prospect.primaryKeyBuyerOf &&
                            prospect.primaryKeyBuyerOf.length > 0) ||
                        !prospect.isInSalesforce ? null : (
                            <NotAvailable
                                section="internal-section"
                                linkUrl={getUrlLink(
                                    'imanageFilter',
                                    prospect.id
                                )}
                                showNotAvailable={false}
                            />
                        )}
                    </h4>
                    <div style={{ display: 'flex' }}>
                        {prospect.primaryKeyBuyerOf &&
                        prospect.primaryKeyBuyerOf.length > 1 ? (
                            <div className="filter-button">
                                <div>
                                    {triggerFilter(showFilters, setShowFilters)}
                                </div>
                                <div className="clear-filter">
                                    <div
                                        className="text-primary"
                                        onClick={clearAllFilters}
                                        style={{
                                            visibility: showFilters
                                                ? 'visible'
                                                : 'hidden',
                                        }}
                                    >
                                        Clear Filters
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="sfdc-contact-owner">
                            <div className="employee-name">
                                Total # of Opps:{' '}
                                {prospect.numberOfPrimaryKeyBuyerOf}
                            </div>
                        </div>
                    </div>
                </div>
                {prospect.primaryKeyBuyerOf &&
                prospect.primaryKeyBuyerOf.length > 0 ? (
                    <div className="result-table">
                        <BootstrapTable
                            keyField="id"
                            data={prospect.primaryKeyBuyerOf}
                            columns={tableColumns}
                            striped={true}
                            bordered={false}
                            hover={true}
                            pagination={paginationFactory({
                                page: 1,
                                sizePerPage: 5,
                                hideSizePerPage: true,
                                hidePageListOnlyOnePage: true,
                            })}
                            filter={filterFactory({
                                afterFilter,
                            })}
                        />
                        <Button
                            style={{
                                float: 'right',
                                margin: '2%',
                                display:
                                    prospect.primaryKeyBuyerOf.length <= 5
                                        ? 'none'
                                        : null,
                            }}
                            onClick={handleShowMore}
                            variant="outline-secondary"
                        >
                            {'View More'}
                        </Button>
                        {showMoreModal()}
                    </div>
                ) : (
                    <>
                        {prospect.isInSalesforce ? (
                            <>
                                <hr />
                                <div className="not-available">
                                    This person has not been a key buyer with
                                    Protiviti
                                </div>
                            </>
                        ) : (
                            renderExternalNotAvailable('contact')
                        )}
                    </>
                )}
            </Card.Body>
        </Card>
    );
};
