import React, { useContext } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { CardGroup, Card, Button } from 'react-bootstrap/';
import { GlobalContext } from '../../context/GlobalState';
import { formatAccountName, formatDate } from '../Shared/Util';

export const AccountCards = (props) => {
    const { followingAccounts, followAccount } = useContext(GlobalContext);

    const isFollowing = (id) => {
        const followingId = followingAccounts.map((_) => _.id);
        return (
            <div>
                <Button
                    className={
                        followingId.includes(id)
                            ? 'following-active'
                            : 'following-inactive'
                    }
                    onClick={(e) => triggerFollow(id, e)}
                >
                    {followingId.includes(id) ? 'Following' : 'Follow'}
                </Button>
            </div>
        );
    };

    const triggerFollow = (id, e) => {
        e.preventDefault();
        followAccount(id);
    };

    return (
        <CardGroup>
            {props.cards.map((result, index) => {
                return (
                    <div key={index} className="search-result-link-follow">
                        <Card
                            className="search-result-card"
                            style={{ textAlign: 'left' }}
                        >
                            <Card.Body
                                className="card-style"
                                style={{
                                    borderTopColor: '#5d7b9a',
                                }}
                            >
                                <div className="prospect-background-follow">
                                    {/* Removed Company Logo Here */}

                                    <div
                                        className="prospect-detail-follow"
                                        style={{
                                            paddingTop: '10px',
                                        }}
                                    >
                                        <h5>
                                            <Link
                                                className="prospect-name"
                                                to={`/company/${result.id}`}
                                            >
                                                {result.name
                                                    ? formatAccountName(
                                                          result.name
                                                      )
                                                    : null}
                                            </Link>
                                        </h5>
                                        <div
                                            style={{
                                                float: 'left',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {isFollowing(result.id)}
                                            {result.showLastModified
                                                ? formatDate(
                                                      result.modifiedDate
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                );
            })}
        </CardGroup>
    );
};
