import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from '@musicstory/react-bootstrap-table-next';
import paginationFactory from '@musicstory/react-bootstrap-table2-paginator';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { formatRevenue, renderSortArrow, formatYear } from '../Shared/Util';
import { NotAvailable } from '../HoverOver/NotAvailable';
import { ExternalDetail } from '../HoverOver/ExternalDetail';

export const Funding = () => {
    const { account } = useContext(GlobalContext);
    const [showMore, setShowMore] = useState(false);
    const [companyFunding, setCompanyFunding] = useState([]);

    useEffect(() => {
        if (
            account &&
            account.externalAccountView &&
            account.externalAccountView.companyFunding &&
            account.externalAccountView.companyFunding.length > 0
        ) {
            let companyFundingWithId =
                account.externalAccountView.companyFunding.map((_, i) => {
                    _.id = i;
                    return _;
                });
            setCompanyFunding(companyFundingWithId);
        }
    }, [account]);

    const tableColumns = [
        {
            dataField: 'date',
            text: 'Funding Date',
            formatter: (value, row) =>
                value && value.substring(0, 4) !== '0001' ? (
                    formatYear(value)
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '150px' },
        },
        {
            dataField: 'type',
            text: 'Round',
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '300px' },
        },
        {
            dataField: 'amount',
            text: 'Amount',
            formatter: (value, row) =>
                value ? (
                    formatRevenue(value)
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '200px' },
        },
        {
            dataField: 'id',
            text: 'key',
            hidden: true,
        },
    ];

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    if (!account) return null;

    const showMoreModal = () => {
        return (
            <Modal
                show={showMore}
                onHide={handleShowMore}
                dialogClassName="custom-dialog"
            >
                <Modal.Header
                    closeButton
                    className="card-style"
                    style={{ borderTopColor: '#F6871F' }}
                >
                    <Modal.Title>
                        <h4
                            className="card-title"
                            style={{ paddingTop: '10px' }}
                        >
                            <span>
                                <MonetizationOnIcon
                                    fontSize="large"
                                    style={{
                                        marginRight: '12px',
                                    }}
                                />
                                {account.name} Funding History{' '}
                                <span style={{ lineHeight: 2 }}>
                                    {' '}
                                    <ExternalDetail />{' '}
                                </span>{' '}
                            </span>
                            <div className="project-subtitle">
                                These represent any funding history associated
                                to this account
                            </div>
                        </h4>
                    </Modal.Title>
                    <div className="trigger-filter-modal">
                        <div className="sfdc-contact-owner">
                            {account.externalAccountView &&
                            account.externalAccountView.totalFundingAmount ? (
                                <div className="employee-name">
                                    Total Funding Amount:{' '}
                                    {formatRevenue(
                                        account.externalAccountView
                                            .totalFundingAmount
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body
                    style={{
                        overflowX: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingTop: '0px',
                        paddingBottom: '80px',
                    }}
                >
                    {
                        <BootstrapTable
                            keyField="id"
                            data={companyFunding}
                            columns={tableColumns}
                            striped={true}
                            bordered={false}
                            hover={true}
                        />
                    }
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <Card>
            <Card.Body
                className="card-style"
                style={{
                    borderTopColor: '#F6871F',
                    overflowY: 'hidden',
                    overflowX:
                        companyFunding && companyFunding.length > 0
                            ? 'auto'
                            : 'none',
                }}
            >
                <div className="space-between">
                    <h4 className="card-title">
                        <span>
                            <MonetizationOnIcon
                                fontSize="large"
                                style={{ marginRight: '12px' }}
                            />
                            {account.name} Funding History{' '}
                        </span>
                        {companyFunding && companyFunding.length > 0 ? (
                            <span style={{ lineHeight: 2 }}>
                                {' '}
                                <ExternalDetail />{' '}
                            </span>
                        ) : (
                            <NotAvailable
                                section="external"
                                showNotAvailable={false}
                            />
                        )}
                        <div className="project-subtitle">
                            These represent any funding history associated to
                            this account
                        </div>
                    </h4>
                    <div className="sfdc-contact-owner">
                        {account.externalAccountView &&
                        account.externalAccountView.totalFundingAmount ? (
                            <div className="employee-name">
                                Total Funding Amount:{' '}
                                {formatRevenue(
                                    account.externalAccountView
                                        .totalFundingAmount
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
                {companyFunding && companyFunding.length > 0 ? (
                    <div className="result-table">
                        <BootstrapTable
                            keyField="id"
                            data={companyFunding}
                            columns={tableColumns}
                            striped={true}
                            bordered={false}
                            hover={true}
                            pagination={paginationFactory({
                                page: 1,
                                sizePerPage: 10,
                                hideSizePerPage: true,
                                hidePageListOnlyOnePage: true,
                            })}
                        />
                        <Button
                            style={{
                                float: 'right',
                                margin: '2%',
                                display:
                                    companyFunding.length <= 10 ? 'none' : null,
                            }}
                            onClick={handleShowMore}
                            variant="outline-secondary"
                        >
                            {'View More'}
                        </Button>
                        {showMoreModal()}
                    </div>
                ) : (
                    <>
                        <hr />
                        <div className="not-available">Not Available</div>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};
