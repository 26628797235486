import React from 'react';
import ReactDOM from 'react-dom';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import TimerRoot from './TimerRoot';
import { authProvider } from './auth/authProvider';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const container = document.getElementById('root');

ReactDOM.render(
    <AzureAD provider={authProvider} forceLogin={true}>
        {({ login, logout, authenticationState, error, accountInfo }) => {
            switch (authenticationState) {
                case AuthenticationState.Authenticated:
                    return (
                        <TimerRoot
                            authenticationState={authenticationState}
                            accountInfo={accountInfo}
                        />
                    );
                case AuthenticationState.Unauthenticated:
                    return (
                        <div>
                            {error && (
                                <p>
                                    <span>
                                        An error occured during authentication,
                                        please try again!
                                    </span>
                                </p>
                            )}
                        </div>
                    );
            }
        }}
    </AzureAD>,
    container
);
