import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import './Overview.css';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { NotAvailable } from '../HoverOver/NotAvailable';
import Button from 'react-bootstrap/Button';
import { OverlayTooltip } from '../HoverOver/OverlayTooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    getUrlLink,
    provideLinkToCompanyPage,
    setUpLinkedinUrl,
    formatDate,
    formatWebsite,
    renderRestriction,
} from '../Shared/Util';
import DefaultPhoto from '../Shared/DefaultPhoto';
import questionMark from '../../images/questionmark-icon.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import instagram from '../../images/instagram-icon.svg';
import { PowerBI } from '../Shared/PowerBI';
import { ExternalDetail } from '../HoverOver/ExternalDetail';
import infoIcon from '../../images/i-icon.png';
import { OverlayStickOnHover } from '../HoverOver/OverlayStickOnHover';

export const ProspectBackground = () => {
    const { prospect, following, followProspect } = useContext(GlobalContext);
    const [clientSideSettings] = useState(
        JSON.parse(localStorage.getItem('ClientSideSettings'))
    );
    const [showEducation, setShowEducation] = useState(false);
    const [showEmployment, setEmployment] = useState(false);
    const [showActivities, setShowActivities] = useState(false);
    const [showAwards, setShowAwards] = useState(false);

    const [activitiesShowMore, setActivitiesShowMore] = useState(false);
    const [load, setLoad] = useState(5);

    const isFollowing = (id) => {
        const followingId = following.map((_) => _.id);
        return (
            <div className="following-button" style={{ position: 'absolute' }}>
                <Button
                    className={
                        followingId.includes(id)
                            ? 'following-active'
                            : 'following-inactive'
                    }
                    onClick={() => followProspect(id)}
                >
                    {followingId.includes(id) ? 'Following' : 'Follow'}
                </Button>
            </div>
        );
    };

    const getPKBStatus = () => {
        if (prospect.isPrimaryKeyBuyer === true) {
            if (prospect.primaryKeyBuyerOf.length > 0) {
                if (
                    prospect.primaryKeyBuyerOf[0].companyId ===
                    prospect.company.id
                ) {
                    return (
                        <Card.Link
                            className="text-primary"
                            href={encodeURI(
                                clientSideSettings.sfdcUrl + prospect.id
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Yes
                        </Card.Link>
                    );
                } else {
                    let companyName =
                        prospect.primaryKeyBuyerOf[0].companyName.length > 10
                            ? 'Yes, ' +
                              prospect.primaryKeyBuyerOf[0].companyName.substring(
                                  0,
                                  10
                              ) +
                              '...'
                            : 'Yes, ' +
                              prospect.primaryKeyBuyerOf[0].companyName;

                    return (
                        <Card.Link
                            className="text-primary"
                            href={encodeURI(
                                clientSideSettings.sfdcUrl + prospect.id
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <OverlayTooltip
                                tooltipText={
                                    prospect.primaryKeyBuyerOf[0].companyName
                                }
                                hoverElement={<span>{companyName}</span>}
                            />
                            <OverlayTooltip
                                tooltipText="Previous Employer"
                                hoverElement={
                                    <HelpOutlineIcon className="notavailable-icon" />
                                }
                            />
                        </Card.Link>
                    );
                }
            } else {
                return (
                    <Card.Link
                        className="text-primary"
                        href={encodeURI(
                            clientSideSettings.sfdcUrl + prospect.id
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Yes
                    </Card.Link>
                );
            }
        }

        return 'No';
    };

    const renderAwards = () => {
        return (
            <div>
                {prospect &&
                prospect.externalProspectView &&
                prospect.externalProspectView.awards &&
                prospect.externalProspectView.awards.length !== 0 ? (
                    prospect.externalProspectView.awards.map((item, key) => {
                        return (
                            <div key={`${key}_${item.institutionName}`}>
                                <div className="external-info">
                                    {item.institutionName}{' '}
                                </div>
                                <div className="external-info">
                                    {' '}
                                    {item.certification}{' '}
                                    {item.endDate
                                        ? '- ' + formatDate(item.endDate)
                                        : null}
                                </div>
                                <div className="inner-info">
                                    {' '}
                                    {item.certificationDescription}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                )}
            </div>
        );
    };

    const renderEmployment = () => {
        return (
            <div>
                {prospect &&
                prospect.externalProspectView &&
                prospect.externalProspectView.employment &&
                prospect.externalProspectView.employment.length !== 0 ? (
                    prospect.externalProspectView.employment.map((item, i) => {
                        return (
                            <div key={`${i}_${item.company.companyName}`}>
                                <div className="external-info">
                                    {item.company.companyName}{' '}
                                </div>
                                <div className="inner-info"> {item.title}</div>
                            </div>
                        );
                    })
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                )}
            </div>
        );
    };

    const handleShowMore = () => {
        setActivitiesShowMore(!activitiesShowMore);
        if (activitiesShowMore) {
            setLoad(prospect.externalProspectView.activities.length);
        } else {
            setLoad(5);
        }
    };

    const renderActivities = () => {
        return (
            <div>
                {prospect &&
                prospect.externalProspectView &&
                prospect.externalProspectView.activities &&
                prospect.externalProspectView.activities.length !== 0 ? (
                    <div>
                        {prospect.externalProspectView.activities
                            .filter((item, idx) => idx < load)
                            .map((item) => {
                                return (
                                    <div>
                                        <div className="external-info">
                                            {item.companyName}{' '}
                                        </div>
                                        <div className="external-info">
                                            {item.title}{' '}
                                            {item.EndDate
                                                ? '- ' +
                                                  formatDate(item.EndDate)
                                                : null}{' '}
                                        </div>
                                        <div className="inner-info">
                                            {' '}
                                            {item.description}
                                        </div>
                                    </div>
                                );
                            })}
                        <Button
                            style={{
                                float: 'center',
                                margin: '2%',
                                display:
                                    prospect.externalProspectView.activities
                                        .length <= 5
                                        ? 'none'
                                        : null,
                            }}
                            onClick={handleShowMore}
                            variant="outline-secondary"
                        >
                            {load <
                            prospect.externalProspectView.activities.length
                                ? 'View More'
                                : 'View Less'}
                        </Button>
                    </div>
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                )}
            </div>
        );
    };

    const renderEducation = () => {
        return (
            <div>
                {prospect &&
                prospect.externalProspectView &&
                prospect.externalProspectView.education &&
                prospect.externalProspectView.education.length !== 0 ? (
                    prospect.externalProspectView.education.map((item, key) => {
                        return (
                            <div key={key}>
                                <div className="external-info">
                                    {item.school}
                                </div>
                                <div
                                    className="inner-info"
                                    style={{
                                        paddingBottom: '0px',
                                    }}
                                >
                                    {item.educationDegree
                                        ? item.educationDegree.areaOfStudy
                                        : null}
                                </div>
                                <div className="inner-info">
                                    {item.educationDegree
                                        ? item.educationDegree.degree
                                        : null}
                                </div>
                                <div className="external-info">
                                    {item.institutionName}{' '}
                                    {item.EndDate
                                        ? '- ' + formatDate(item.EndDate)
                                        : null}{' '}
                                </div>
                                <div className="inner-info">
                                    {item.certification}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                )}
            </div>
        );
    };

    const renderExternal = (title, setShowFunction, show, renderFunction) => {
        return (
            <div>
                <div className="prospect-fields">
                    <span
                        className="external-header"
                        onClick={() => setShowFunction(!show)}
                    >
                        {' '}
                        {title}{' '}
                        <span
                            style={{
                                marginRight: 'auto',
                                marginLeft: '10px',
                            }}
                        >
                            <ExternalDetail field={'prospectExternal'} />{' '}
                        </span>{' '}
                        {show ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </span>
                </div>
                <div
                    style={
                        show
                            ? {
                                  display: 'block',
                                  marginBottom: '20px',
                              }
                            : { display: 'none' }
                    }
                >
                    {renderFunction()}
                </div>
            </div>
        );
    };

    const notInSalesForce = () => {
        return (
            <>
                <> No </>
                <OverlayStickOnHover
                    placement="bottom"
                    onMouseEnter={() => {}}
                    delay={200}
                    component={
                        <div>
                            This contact data is from an external vendor, as
                            this contact is not currently in Salesforce. If you
                            would like to add this contact to our internal
                            system, please visit{' '}
                            <a
                                href={getUrlLink('sfdcLightningUrl', null)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {' '}
                                Salesforce.
                            </a>
                        </div>
                    }
                >
                    <img
                        className="notavailable-icon"
                        src={infoIcon}
                        alt="Information"
                    />
                </OverlayStickOnHover>
            </>
        );
    };

    return (
        <Card>
            <Card.Body
                className="card-style"
                style={{ borderTopColor: '#F6871F' }}
            >
                <div className="prospect-background">
                    <div
                        style={{
                            position: 'relative',
                            marginBottom: '10px',
                        }}
                    >
                        {/*{prospect.photoUrl ?
                            <img
                                className='profile-picture'
                                src={prospect.photoUrl}
                                alt='Profile Picture'
                            />
                            :
                            <DefaultPhoto/>
                        }*/}
                        {prospect.photoUrl ? (
                            <div
                                className="profile-pic"
                                style={{
                                    backgroundImage: `url(${prospect.photoUrl})`,
                                }}
                            ></div>
                        ) : (
                            <DefaultPhoto />
                        )}
                        {isFollowing(prospect.id)}
                    </div>
                    <h3 className="prospect-name">{prospect.name}</h3>
                    <div className="prospect-fields">
                        <span className="header-fields">Last Updated: </span>
                        <span>{formatDate(prospect.modifiedDate)}</span>
                    </div>
                    {prospect.title ? (
                        <div className="prospect-fields">
                            <div className="header-fields">
                                Title (Salesforce): {prospect.title}
                            </div>
                        </div>
                    ) : null}
                    {prospect.externalProspectView &&
                    prospect.externalProspectView.title &&
                    prospect.externalProspectView.title !== prospect.title ? (
                        <div className="prospect-fields">
                            <span className="header-fields">
                                Title (External):{' '}
                                {prospect.externalProspectView.title}
                            </span>
                            <ExternalDetail
                                data={prospect.externalProspectView.title}
                                zoomInfoId={prospect.zoomInfoContactId}
                                type={0}
                                field={'jobTitleExternal'}
                            />
                        </div>
                    ) : null}
                    {provideLinkToCompanyPage(prospect.company.id) ? (
                        <Link to={`/company/${prospect.company.id}`}>
                            <h5
                                className="company-name"
                                style={{ marginTop: '20px' }}
                            >
                                {prospect.company.name}
                            </h5>
                        </Link>
                    ) : (
                        <h5
                            className="company-name"
                            style={{ marginTop: '20px' }}
                        >
                            {prospect.company.name}
                        </h5>
                    )}
                    {prospect.company.isMSA ? (
                        <div>{renderRestriction('msa')}</div>
                    ) : null}
                    {prospect.company.isSanction ? (
                        <div>{renderRestriction('sanction')}</div>
                    ) : null}
                    <div
                        className="prospect-fields"
                        style={{ marginTop: '15px' }}
                    >
                        <span className="header-fields">Location: </span>
                        {prospect.isInSalesforce ? (
                            prospect.location ? (
                                prospect.location
                            ) : (
                                <NotAvailable
                                    section="internal-field"
                                    showNotAvailable={true}
                                    linkUrl={getUrlLink(
                                        'sfdclightningCon',
                                        prospect.id
                                    )}
                                />
                            )
                        ) : prospect.externalProspectView &&
                          prospect.externalProspectView.location ? (
                            prospect.externalProspectView.location
                        ) : (
                            <NotAvailable
                                section="external"
                                showNotAvailable={true}
                            />
                        )}
                    </div>
                    <div className="prospect-fields">
                        <span className="header-fields">In Salesforce?: </span>
                        {prospect.isInSalesforce ? (
                            <Card.Link
                                className="text-primary"
                                href={encodeURI(
                                    clientSideSettings.sfdcUrl + prospect.id
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Yes
                            </Card.Link>
                        ) : (
                            notInSalesForce()
                        )}
                    </div>
                    <div className="prospect-fields">
                        <span className="header-fields">
                            Protiviti Alumni?:{' '}
                        </span>
                        {prospect.isProtivitiAlumni ? 'Yes' : 'No'}
                    </div>
                    {/*<div className='prospect-fields'>
                        <span className='header-fields'>Key Buyer?: </span>
                        {getPKBStatus()}
                    </div>*/}
                    {prospect.isInSalesforce ? (
                        <div className="prospect-fields">
                            <span className="header-fields">
                                Contact at Robert Half?:{' '}
                            </span>
                            {prospect.hasRoberthalfContact
                                ? prospect.hasRoberthalfContact
                                : 'No'}
                            {prospect.hasRoberthalfContact &&
                            prospect.hasRoberthalfContact === 'Yes' ? (
                                <PowerBI
                                    smaller={true}
                                    url={encodeURI(
                                        clientSideSettings.powerBIUrlRobertHalfContact
                                    )}
                                    toolTipText={
                                        'Your access to this dashboard may be limited given on your role and region. Note, the default on this Dashboard is Microsoft- please filter for the specific Contact/Account you would like to see.'
                                    }
                                />
                            ) : null}
                        </div>
                    ) : null}
                    {prospect.phone &&
                    !prospect.company.isMSA &&
                    !prospect.company.isSanction ? (
                        <div className="prospect-fields">
                            <span className="header-fields">
                                Phone (Salesforce): {prospect.phone}{' '}
                            </span>
                        </div>
                    ) : null}
                    {prospect.externalProspectView &&
                    prospect.externalProspectView.phone &&
                    prospect.externalProspectView.phone !== prospect.phone &&
                    !prospect.company.isMSA &&
                    !prospect.company.isSanction ? (
                        <div className="prospect-fields">
                            <span className="header-fields">
                                Phone (External):{' '}
                                {prospect.externalProspectView.phone}
                            </span>
                            <ExternalDetail
                                data={prospect.externalProspectView.phone}
                                zoomInfoId={prospect.zoomInfoContactId}
                                type={0}
                                field={'phoneExternal'}
                            />
                        </div>
                    ) : null}
                    {prospect.emailAddress &&
                    !prospect.company.isMSA &&
                    !prospect.company.isSanction ? (
                        <div className="prospect-fields">
                            <span className="header-fields">
                                Email (Salesforce):{' '}
                            </span>
                            <Card.Link
                                className="text-primary"
                                href={`mailto:${prospect.emailAddress}`}
                            >
                                {prospect.emailAddress}{' '}
                            </Card.Link>
                            {prospect.emailBounceback ? (
                                <OverlayTooltip
                                    tooltipText={
                                        'This prospect has been sent marketing materials and we received an undeliverable error message/ bounce back email from their email listed. If you know this information, please update within the system (e.g., SalesForce, iManage, etc.)'
                                    }
                                    hoverElement={
                                        <Card.Link
                                            href={encodeURI(
                                                clientSideSettings.sfdcUrl +
                                                    prospect.id
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                marginLeft: '0px',
                                            }}
                                        >
                                            <img
                                                src={questionMark}
                                                className="notavailable-icon"
                                                alt="Question"
                                            />
                                        </Card.Link>
                                    }
                                    imageType={'question'}
                                />
                            ) : null}
                        </div>
                    ) : null}
                    {prospect.externalProspectView &&
                    prospect.externalProspectView.emailAddress &&
                    prospect.externalProspectView.emailAddress !==
                        prospect.emailAddress &&
                    !prospect.company.isMSA &&
                    !prospect.company.isSanction ? (
                        <div className="prospect-fields">
                            <span className="header-fields">
                                Email (External):{' '}
                            </span>
                            <Card.Link
                                className="text-primary"
                                href={`mailto:${prospect.externalProspectView.emailAddress}`}
                            >
                                {prospect.externalProspectView.emailAddress}{' '}
                            </Card.Link>
                            <ExternalDetail
                                data={
                                    prospect.externalProspectView.emailAddress
                                }
                                zoomInfoId={prospect.zoomInfoContactId}
                                type={0}
                                field={'emailAddressExternal'}
                            />
                        </div>
                    ) : null}

                    <div
                        style={{
                            display: 'flex',
                            placeContent: 'center',
                            marginTop: '20px',
                            alignItems: 'center',
                        }}
                    >
                        {prospect.isInSalesforce ? (
                            prospect.instagramUrl ? (
                                <a href={formatWebsite(prospect.instagramUrl)}>
                                    {' '}
                                    <img
                                        className="social-media-margin"
                                        style={{
                                            height: '25px',
                                        }}
                                        src={instagram}
                                    />{' '}
                                </a>
                            ) : null
                        ) : prospect.externalProspectView &&
                          prospect.externalProspectView.instagramUrl ? (
                            <a
                                href={formatWebsite(
                                    prospect.externalProspectView.instagramUrl
                                )}
                            >
                                {' '}
                                <img
                                    className="social-media-margin"
                                    style={{ height: '25px' }}
                                    src={instagram}
                                />{' '}
                            </a>
                        ) : null}
                        {prospect.isInSalesforce ? (
                            prospect.facebookUrl ? (
                                <a href={formatWebsite(prospect.facebookUrl)}>
                                    {' '}
                                    <FacebookIcon className="social-media-margin" />{' '}
                                </a>
                            ) : null
                        ) : prospect.externalProspectView &&
                          prospect.externalProspectView.facebookUrl ? (
                            <a
                                href={formatWebsite(
                                    prospect.externalProspectView.facebookUrl
                                )}
                            >
                                {' '}
                                <FacebookIcon className="social-media-margin" />{' '}
                            </a>
                        ) : null}
                        {prospect.isInSalesforce ? (
                            prospect.twitterUrl ? (
                                <a href={formatWebsite(prospect.twitterUrl)}>
                                    {' '}
                                    <TwitterIcon
                                        className="social-media-margin"
                                        style={{
                                            fill: 'deepskyblue',
                                        }}
                                    />{' '}
                                </a>
                            ) : null
                        ) : prospect.externalProspectView &&
                          prospect.externalProspectView.twitterUrl ? (
                            <a
                                href={formatWebsite(
                                    prospect.externalProspectView.twitterUrl
                                )}
                            >
                                {' '}
                                <TwitterIcon
                                    className="social-media-margin"
                                    style={{
                                        fill: 'deepskyblue',
                                    }}
                                />{' '}
                            </a>
                        ) : null}
                        {prospect.isInSalesforce ? (
                            prospect.youtubeUrl ? (
                                <a href={formatWebsite(prospect.youtubeUrl)}>
                                    {' '}
                                    <YouTubeIcon
                                        className="social-media-margin"
                                        style={{
                                            fill: 'red',
                                        }}
                                    />{' '}
                                </a>
                            ) : null
                        ) : prospect.externalProspectView &&
                          prospect.externalProspectView.youtubeUrl ? (
                            <a
                                href={formatWebsite(
                                    prospect.externalProspectView.youtubeUrl
                                )}
                            >
                                {' '}
                                <YouTubeIcon
                                    className="social-media-margin"
                                    style={{ fill: 'red' }}
                                />{' '}
                            </a>
                        ) : null}
                        {prospect.isInSalesforce
                            ? setUpLinkedinUrl(prospect.linkedinUrl, true)
                            : prospect.externalProspectView
                            ? setUpLinkedinUrl(
                                  prospect.externalProspectView.linkedinUrl,
                                  true
                              )
                            : null}
                        {prospect.emailAddress &&
                        !prospect.company.isMSA &&
                        !prospect.company.isSanction ? (
                            <Card.Link
                                href={`mailto:${prospect.emailAddress}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    marginRight: '10px',
                                    marginLeft: '0',
                                }}
                            >
                                <svg
                                    alt="Outlook"
                                    viewBox="-0.12979372698077785 0 33.25199671942124 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30px"
                                    height="30px"
                                >
                                    <path
                                        d="M28.596 2H11.404A1.404 1.404 0 0 0 10 3.404V5l9.69 3L30 5V3.404A1.404 1.404 0 0 0 28.596 2z"
                                        fill="#0364b8"
                                    />
                                    <path
                                        d="M31.65 17.405A11.341 11.341 0 0 0 32 16a.666.666 0 0 0-.333-.576l-.013-.008-.004-.002L20.812 9.24a1.499 1.499 0 0 0-.145-.083 1.5 1.5 0 0 0-1.334 0 1.49 1.49 0 0 0-.145.082L8.35 15.415l-.004.002-.012.007A.666.666 0 0 0 8 16a11.344 11.344 0 0 0 .35 1.405l11.492 8.405z"
                                        fill="#0a2767"
                                    />
                                    <path
                                        d="M24 5h-7l-2.021 3L17 11l7 6h6v-6z"
                                        fill="#28a8ea"
                                    />
                                    <path d="M10 5h7v6h-7z" fill="#0078d4" />
                                    <path d="M24 5h6v6h-6z" fill="#50d9ff" />
                                    <path
                                        d="M24 17l-7-6h-7v6l7 6 10.832 1.768z"
                                        fill="#0364b8"
                                    />
                                    <path d="M10.031 5H30" fill="none" />
                                    <path d="M17 11h7v6h-7z" fill="#0078d4" />
                                    <path d="M10 17h7v6h-7z" fill="#064a8c" />
                                    <path d="M24 17h6v6h-6z" fill="#0078d4" />
                                    <path
                                        d="M20.19 25.218l-11.793-8.6.495-.87s10.745 6.12 10.909 6.212a.528.528 0 0 0 .42-.012l10.933-6.23.496.869z"
                                        fill="#0a2767"
                                        opacity=".5"
                                    />
                                    <path
                                        d="M31.667 16.577l-.014.008-.003.002-10.838 6.174a1.497 1.497 0 0 1-1.46.091l3.774 5.061 8.254 1.797v.004A1.498 1.498 0 0 0 32 28.5V16a.666.666 0 0 1-.333.577z"
                                        fill="#1490df"
                                    />
                                    <path
                                        d="M32 28.5v-.738l-9.983-5.688-1.205.687a1.497 1.497 0 0 1-1.46.091l3.774 5.061 8.254 1.797v.004A1.498 1.498 0 0 0 32 28.5z"
                                        opacity=".05"
                                    />
                                    <path
                                        d="M31.95 28.883L21.007 22.65l-.195.11a1.497 1.497 0 0 1-1.46.092l3.774 5.061 8.254 1.797v.004a1.501 1.501 0 0 0 .57-.83z"
                                        opacity=".1"
                                    />
                                    <path
                                        d="M8.35 16.59v-.01h-.01l-.03-.02A.65.65 0 0 1 8 16v12.5A1.498 1.498 0 0 0 9.5 30h21a1.503 1.503 0 0 0 .37-.05.637.637 0 0 0 .18-.06.142.142 0 0 0 .06-.02 1.048 1.048 0 0 0 .23-.13c.02-.01.03-.01.04-.03z"
                                        fill="#28a8ea"
                                    />
                                    <path
                                        d="M18 24.667V8.333A1.337 1.337 0 0 0 16.667 7H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 0 0 8 16v.005V16 26h8.667A1.337 1.337 0 0 0 18 24.667z"
                                        opacity=".1"
                                    />
                                    <path
                                        d="M17 25.667V9.333A1.337 1.337 0 0 0 15.667 8H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 0 0 8 16v.005V16 27h7.667A1.337 1.337 0 0 0 17 25.667z"
                                        opacity=".2"
                                    />
                                    <path
                                        d="M17 23.667V9.333A1.337 1.337 0 0 0 15.667 8H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 0 0 8 16v.005V16 25h7.667A1.337 1.337 0 0 0 17 23.667z"
                                        opacity=".2"
                                    />
                                    <path
                                        d="M16 23.667V9.333A1.337 1.337 0 0 0 14.667 8H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 0 0 8 16v.005V16 25h6.667A1.337 1.337 0 0 0 16 23.667z"
                                        opacity=".2"
                                    />
                                    <path
                                        d="M1.333 8h13.334A1.333 1.333 0 0 1 16 9.333v13.334A1.333 1.333 0 0 1 14.667 24H1.333A1.333 1.333 0 0 1 0 22.667V9.333A1.333 1.333 0 0 1 1.333 8z"
                                        fill="#0078d4"
                                    />
                                    <path
                                        d="M3.867 13.468a4.181 4.181 0 0 1 1.642-1.814A4.965 4.965 0 0 1 8.119 11a4.617 4.617 0 0 1 2.413.62 4.14 4.14 0 0 1 1.598 1.733 5.597 5.597 0 0 1 .56 2.55 5.901 5.901 0 0 1-.577 2.666 4.239 4.239 0 0 1-1.645 1.794A4.8 4.8 0 0 1 7.963 21a4.729 4.729 0 0 1-2.468-.627 4.204 4.204 0 0 1-1.618-1.736 5.459 5.459 0 0 1-.567-2.519 6.055 6.055 0 0 1 .557-2.65zm1.75 4.258a2.716 2.716 0 0 0 .923 1.194 2.411 2.411 0 0 0 1.443.435 2.533 2.533 0 0 0 1.541-.449 2.603 2.603 0 0 0 .897-1.197 4.626 4.626 0 0 0 .286-1.665 5.063 5.063 0 0 0-.27-1.686 2.669 2.669 0 0 0-.866-1.24 2.387 2.387 0 0 0-1.527-.473 2.493 2.493 0 0 0-1.477.439 2.741 2.741 0 0 0-.944 1.203 4.776 4.776 0 0 0-.007 3.44z"
                                        fill="#fff"
                                    />
                                    <path d="M0 0h32v32H0z" fill="none" />
                                </svg>
                            </Card.Link>
                        ) : null}
                    </div>
                    {renderExternal(
                        'Past Job Experience',
                        setEmployment,
                        showEmployment,
                        renderEmployment
                    )}
                    {renderExternal(
                        'Education',
                        setShowEducation,
                        showEducation,
                        renderEducation
                    )}
                    {renderExternal(
                        'Activities',
                        setShowActivities,
                        showActivities,
                        renderActivities
                    )}
                    {renderExternal(
                        'Awards',
                        setShowAwards,
                        showAwards,
                        renderAwards
                    )}
                </div>
            </Card.Body>
        </Card>
    );
};
