import React, { useState, useRef, useEffect } from 'react';
import '../Prospect/Overview.css';
import Select, { components } from 'react-select';
import {
    formatDate,
    formatMonthYearDate,
    formatEndYear,
    formatType,
    formatSource,
} from '../Shared/Util';

export const FilterMultiSelect = (props) => {
    const selectRef = useRef(null);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (props.column.filter.props.getFilter) {
            props.column.filter.props.getFilter((filterVal) => {
                if (filterVal.length === 0) {
                    setSelected([]);
                    props.onFilter([]);
                    props.onStaticOptionsUpdate([], props.filterKey, true);
                } else {
                    props.onFilter(filterVal);
                }
            });
        }
    });

    const onFilterChange = (filters) => {
        let filtersArr = filters
            ? [...new Set(filters.map((x) => x[props.filterKey]))]
            : [];

        setSelected(filters);
        props.onFilter(filtersArr);

        // set static when first option has been selected from this column
        if (
            filtersArr.length === 0 ||
            ((!selected || selected.length === 0) && filtersArr.length > 0)
        ) {
            let staticOptions = [
                ...new Set(
                    props.filteredData.map((_) =>
                        checkFormat(_, props.filterKey)
                    )
                ),
            ]; // new set -> removes duplicates
            props.onStaticOptionsUpdate(
                staticOptions,
                props.filterKey,
                filtersArr.length === 0
            );
        }
    };

    const Menu = (props) => {
        return (
            <components.Menu {...props}>
                <div>
                    <div>{props.children}</div>
                    <div className="filter-clear" onClick={onClear}>
                        Clear
                    </div>
                </div>
            </components.Menu>
        );
    };

    const onClear = () => {
        setSelected([]);
        props.onFilter([]);
        props.onStaticOptionsUpdate([], props.filterKey, true);
        selectRef.current?.blur();
    };

    const getLabel = (option) => {
        // if props.format === 'date' -> formatDate
        // if props.format === 'date-month-year' -> formatMonthYearDate

        let value = option[props.filterKey]; // Bracket notation for variable key

        if (props.format && props.format === 'date') {
            let date = formatDate(value);
            return date ? date : 'Not Available';
        } else if (props.format && props.format === 'date-month-year') {
            let date = formatMonthYearDate(value);
            return date ? date : 'Not Available';
        } else {
            return value === null ? 'Not Available' : value; // label as Not Available if null
        }
    };

    const getValue = (option) => {
        //if (props.format && props.format === 'date-month-year') {
        //    let date = formatMonthYearDate(option[props.filterKey]);
        //    return date ? date : 'Not Available';
        //}
        //else {
        //    return option[props.filterKey];
        //}
        return option[props.filterKey];
    };

    const formatOptions = (a) => {
        if (props.format && props.format === 'date-year') {
            // Project Year Ended -> if format === 'date-year', value = formatEndYear
            return { [props.filterKey]: formatEndYear(a) };
        } else if (props.format && props.format === 'interaction-source') {
            return { [props.filterKey]: formatSource(a.source) };
        } else if (props.format && props.format === 'interaction-type') {
            return { [props.filterKey]: formatType(a.connectionType) };
        } else {
            return { [props.filterKey]: a[props.filterKey] };
        }
    };

    const getOptions = () => {
        let sourceOptions = [...props.options];

        // dynamic filtering, selected column stay static, non-selected dynamically changes
        if (Object.keys(props.currentFilters).length > 0) {
            let currFilters = Object.keys(props.currentFilters).reduce(
                (acc, key) => {
                    acc[key] = props.currentFilters[key]['filterVal'].map((_) =>
                        checkFormatText(_, key)
                    );
                    return acc;
                },
                {}
            );

            sourceOptions = props.options.filter((option) => {
                if (
                    Object.keys(props.staticOption).length > 0 &&
                    props.staticOption.hasOwnProperty(props.filterKey)
                ) {
                    let value = checkFormat(option, props.filterKey);
                    return props.staticOption[props.filterKey].includes(value);
                } else {
                    return Object.keys(currFilters).every((key) => {
                        let value = checkFormat(option, key);
                        return currFilters[key].includes(value);
                    });
                }
            });
        }

        // reformat options for React Select options & remove duplicates
        let options = Array.from(
            new Set(sourceOptions.map((a) => formatOptions(a)))
        ).filter(
            (key, index, self) =>
                self.findIndex(
                    (a) => a[props.filterKey] === key[props.filterKey]
                ) === index
        ); // remove duplicates

        // sort options
        if (
            props.format &&
            (props.format === 'date-year' ||
                props.format === 'date' ||
                props.format === 'date-month-year' ||
                props.format === 'number')
        ) {
            // desc order
            return options.sort(sortingOptions(false));
        }

        // asc order
        return options.sort(sortingOptions(true));
    };

    const sortingOptions = (ascending) => {
        return (a, b) => {
            // equal items sort equally
            if (a[props.filterKey] === b[props.filterKey]) {
                return 0;
            }
            // nulls sort after anything else
            else if (a[props.filterKey] === null) {
                return 1;
            } else if (b[props.filterKey] === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (ascending) {
                return a[props.filterKey] < b[props.filterKey] ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
                return a[props.filterKey] < b[props.filterKey] ? 1 : -1;
            }
        };
    };

    const checkFormat = (option, key) => {
        if (key === 'endedDate') {
            return formatEndYear(option);
        } else {
            return checkFormatText(option[key], key);
        }
    };

    const checkFormatText = (text, key) => {
        if (key === 'opportunityCloseDate' && props.from === 'key-buyer') {
            return formatMonthYearDate(text);
        } else if (
            key === 'opportunityCloseDate' ||
            key === 'campaignDateSent' ||
            key === 'lastContacted' ||
            key === 'lastOpportunityWonDate'
        ) {
            return formatDate(text);
        } else if (key === 'source') {
            return formatSource(text);
        } else if (key === 'formatType') {
            return formatType(text);
        } else {
            return text;
        }
    };

    const selectStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menu: (provided) => ({
            ...provided,
            zIndex: '9999 !important',
            top: 'auto',
            width: 'auto',
            minWidth: '100px',
        }),
        container: (base) => ({ ...base, position: 'auto' }),
        control: (base) => ({ ...base, border: 0, boxShadow: 'none' }), // disable blue border
        valueContainer: (base) => ({
            ...base,
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }),
    };

    if (!props.showFilters) {
        return null;
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Select
                styles={selectStyles}
                ref={selectRef}
                isMulti
                options={getOptions()}
                onChange={onFilterChange}
                value={selected}
                hideSelectedOptions={false}
                getOptionLabel={(option) => getLabel(option)}
                getOptionValue={(option) => getValue(option)}
                components={{
                    Menu,
                    ValueContainer,
                    IndicatorsContainer,
                }}
                placeholder="All"
                isSearchable={false}
                closeMenuOnSelect={false}
                passProps={{
                    // passes data to ValueContainer via selectProps
                    format: props.format,
                }}
            />
        </div>
    );
};

// Custom component must be declared outside of render due to closeMenuOnSelect props
const ValueContainer = ({ children, getValue, ...props }) => {
    // Changes to custom label for input textbox
    let values = getValue();
    let valueLabel = '';

    if (values.length > 0) {
        let firstValue = 'Not Available';

        let value = values[0][Object.keys(values[0])[0]];
        if (value) {
            // no need to deal with Year Ended
            if (
                props.selectProps.passProps.format &&
                props.selectProps.passProps.format === 'date'
            ) {
                firstValue = formatDate(value);
            } else if (
                props.selectProps.passProps.format &&
                props.selectProps.passProps.format === 'date-month-year'
            ) {
                firstValue = formatMonthYearDate(value);
            } else if (
                props.selectProps.passProps.format &&
                props.selectProps.passProps.format === 'interaction-source'
            ) {
                firstValue = formatSource(value);
            } else if (
                props.selectProps.passProps.format &&
                props.selectProps.passProps.format === 'interaction-type'
            ) {
                firstValue = formatType(value);
            } else if (
                props.selectProps.passProps.format &&
                props.selectProps.passProps.format === 'number'
            ) {
                firstValue = value;
            } else {
                // limit 30 since input not long enough -> fixes text positioning issue for long filter options
                firstValue = value.slice(0, 30);
            }
        }

        valueLabel = `${firstValue}`;
    }

    // Keep standard placeholder and input from react-select

    // Doesn't work due to minification, trying reverse filtering as shown below
    //let childsToRender = React.Children.toArray(children).filter(child => ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0);

    // Reverse filtering
    let childsToRender = React.Children.map(children, (child) =>
        child &&
        [components.SingleValue, components.MultiValue].indexOf(child.type) ===
            -1
            ? child
            : null
    );

    return (
        <components.ValueContainer {...props}>
            {!props.selectProps.inputValue && valueLabel}
            {childsToRender}
        </components.ValueContainer>
    );
};

// for the +number of filters
const IndicatorsContainer = (props) => {
    let values = props.getValue();
    let valueLabel = '';

    if (values.length > 1) valueLabel = `(+${values.length - 1})`;

    return (
        components.IndicatorsContainer && (
            <components.IndicatorsContainer {...props}>
                {valueLabel} {props.children}
            </components.IndicatorsContainer>
        )
    );
};
