import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../context/GlobalState';
import './SearchResult.css';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import {
    Card,
    CardGroup,
    Button,
    Col,
    Row,
    Form,
    DropdownButton,
    Dropdown,
} from 'react-bootstrap/';
import queryString from 'query-string';
import { authHeaders } from '../auth/authHeaders';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    formatAccountName,
    provideLinkToCompanyPage,
} from '../../src/components/Shared/Util';
import DefaultPhoto from './Shared/DefaultPhoto';

export const SearchResult = () => {
    const { searchSetting, setSearchSetting, searchFacet, setSearchFacet } =
        useContext(GlobalContext);
    const [keyword, setKeyword] = useState('');
    const [results, setResult] = useState(null);
    const [loading, setLoading] = useState(true);

    // Return to Search
    const location = useLocation();
    const [fromReturn, setFromReturn] = useState(false);

    // paging
    const top = 12;
    const [skip, setSkip] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    // sort
    const [sortBy, setSortBy] = useState(null);
    const sortOptions = {
        FirstName: 'First Name',
        LastName: 'Last Name',
        CompanyName: 'Company',
        Title: 'Position',
    };

    // filters
    const [facet, setFacet] = useState(null);
    const [filters, setFilters] = useState({});

    // filter show/hide fields
    const [showCompany, setShowCompany] = useState(false);
    const [showPosition, setShowPosition] = useState(false);
    const [showFunction, setShowFunction] = useState(false);
    const [showLevel, setShowLevel] = useState(false);
    const [showMoreCompany, setShowMoreCompany] = useState(false);
    const [showMorePosition, setShowMorePosition] = useState(false);
    const [showMoreFunction, setShowMoreFunction] = useState(false);
    const [showMoreLevel, setShowMoreLevel] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        let locationValue = queryString.parse(location.search);
        setKeyword(locationValue.q);
        setSkip(0);
        setTotalCount(0);
        setFacet(null);

        if (
            location.state &&
            location.state.returnbutton &&
            Object.keys(searchSetting).length !== 0
        ) {
            setFromReturn(true);
        } else {
            setFilters({});
            setSortBy(null);
            getResult(locationValue.q, 0, {}, null);
        }
    }, [navigate]);

    useEffect(() => {
        if (fromReturn) {
            let locationValue = queryString.parse(location.search);
            let filterValue = searchSetting.filter ? searchSetting.filter : {};
            let sortByValue = searchSetting.sortBy
                ? searchSetting.sortBy
                : null;
            setFilters(filterValue);
            setSortBy(sortByValue);
            getResult(locationValue.q, 0, filterValue, sortByValue);

            // to expand the filter options
            if (Object.keys(filterValue).length !== 0) {
                if (filterValue.hasOwnProperty('CompanyName')) {
                    setShowCompany(true);
                    if (
                        searchSetting.showMoreFilter &&
                        searchSetting.showMoreFilter.showMoreCompany
                    ) {
                        setShowMoreCompany(true);
                    }
                }
                if (filterValue.hasOwnProperty('Title')) {
                    setShowPosition(true);
                    if (
                        searchSetting.showMoreFilter &&
                        searchSetting.showMoreFilter.showMorePosition
                    ) {
                        setShowMorePosition(true);
                    }
                }
                if (filterValue.hasOwnProperty('Level')) {
                    setShowLevel(true);
                    if (
                        searchSetting.showMoreFilter &&
                        searchSetting.showMoreFilter.showMoreLevel
                    ) {
                        setShowMoreLevel(true);
                    }
                }
                if (filterValue.hasOwnProperty('Function')) {
                    setShowFunction(true);
                    if (
                        searchSetting.showMoreFilter &&
                        searchSetting.showMoreFilter.showMoreFunction
                    ) {
                        setShowMoreFunction(true);
                    }
                }
            }
        }
    }, [fromReturn]);

    useEffect(() => {
        if (facet) {
            setSearchFacet(facet);
        }
    }, [facet]);

    const getResult = async (keyword, skipParam, filtersParam, sortParam) => {
        let staticFilterArr = [];

        if (keyword === '') {
            setResult(null);
            setLoading(false);
            setSkip(0);
            setTotalCount(0);
            setFacet(null);
            setFilters({});
            setSortBy(null);
            setSearchSetting({});
            setSearchFacet(null);
        } else {
            let url =
                config.azRedirectUri +
                '/api/prospects?search=' +
                keyword +
                '&top=' +
                top +
                '&skip=' +
                skipParam;

            if (Object.keys(filtersParam).length !== 0) {
                staticFilterArr = Object.keys(filtersParam); // get currently filtered catagory keys -> for static facet after selected -> for multiselect

                url += '&filter=';
                let count = 0;
                for (let key in filtersParam) {
                    if (count !== 0 && filtersParam[key].length > 0)
                        url += ' and ';

                    if (filtersParam[key].length === 1) {
                        url +=
                            key +
                            ' eq ' +
                            "'" +
                            encodeURIComponent(filtersParam[key][0]) +
                            "'";
                        count++;
                    }
                    if (filtersParam[key].length > 1) {
                        url += '(';
                        for (let filterKey of filtersParam[key]) {
                            url +=
                                key +
                                ' eq ' +
                                "'" +
                                encodeURIComponent(filterKey) +
                                "'";
                            if (
                                filterKey !==
                                filtersParam[key][filtersParam[key].length - 1]
                            ) {
                                url += ' or ';
                            }
                        }
                        url += ')';
                        count++;
                    }
                }
            }

            if (sortParam) {
                url += '&orderby=' + sortParam;
            }

            let headers = await authHeaders();
            await axios
                .get(url, headers)
                .then(function (response) {
                    setResult(response.data.value);
                    setTotalCount(response.data['@odata.count']);
                    setLoading(false);

                    if (fromReturn) {
                        setFacet(searchFacet);
                        setFromReturn(false);
                    } else {
                        if (Object.keys(filtersParam).length === 0) {
                            let facetObj = {
                                CompanyName:
                                    response.data['@search.facets'].CompanyName,
                                Title: response.data['@search.facets'].Title,
                                Function:
                                    response.data['@search.facets'].Function,
                                Level: response.data['@search.facets'].Level,
                            };
                            setFacet(facetObj);
                        } else {
                            // based on currently filtered catagory keys -> static facet after selected -> for multiselect
                            setFacet((prevState) => ({
                                CompanyName:
                                    prevState &&
                                    staticFilterArr.includes('CompanyName')
                                        ? prevState.CompanyName
                                        : response.data['@search.facets']
                                              .CompanyName,
                                Title:
                                    prevState &&
                                    staticFilterArr.includes('Title')
                                        ? prevState.Title
                                        : response.data['@search.facets'].Title,
                                Function:
                                    prevState &&
                                    staticFilterArr.includes('Function')
                                        ? prevState.Function
                                        : response.data['@search.facets']
                                              .Function,
                                Level:
                                    prevState &&
                                    staticFilterArr.includes('Level')
                                        ? prevState.Level
                                        : response.data['@search.facets'].Level,
                            }));
                        }

                        setSearchSetting({
                            filter: filtersParam,
                            sortBy: sortParam,
                            showMoreFilter: {
                                showMoreCompany,
                                showMorePosition,
                                showMoreLevel,
                                showMoreFunction,
                            },
                        });
                    }
                })
                .catch(function (error) {
                    setResult(null);
                    setLoading(false);
                    setTotalCount(0);
                    setSkip(0);

                    if (staticFilterArr.length === 0) {
                        setFacet(null);
                        setFilters({});
                        setSearchSetting({});
                        setSearchFacet(null);
                    }
                });
        }
    };

    const changePage = (action) => {
        if (action === 'previous') {
            getResult(keyword, skip - top, filters, sortBy);
            setSkip((prevState) => {
                return prevState - top;
            });
        } else {
            getResult(keyword, skip + top, filters, sortBy);
            setSkip((prevState) => {
                return prevState + top;
            });
        }
        window.scrollTo(0, 0);
    };

    const renderFilter = (facet, catagory) => {
        return (
            <div className="filter-checkbox">
                {facet.map((option, index) =>
                    option.value ? (
                        <Form.Check
                            key={index}
                            type="checkbox"
                            label={
                                <span>
                                    {option.value}{' '}
                                    {!filters.hasOwnProperty(catagory) ? (
                                        <b>({option.count})</b>
                                    ) : null}
                                </span>
                            }
                            onChange={() =>
                                filterResults(option.value, catagory)
                            }
                            value={option.value}
                            checked={
                                typeof filters[catagory] !== 'undefined'
                                    ? filters[catagory].find(
                                          (element) => element === option.value
                                      )
                                        ? true
                                        : false
                                    : false
                            }
                        />
                    ) : null
                )}
            </div>
        );
    };

    const filterResults = (value, catagory) => {
        let filtersDupe = filters;

        if (filtersDupe[catagory]) {
            let index = filtersDupe[catagory].indexOf(value); // check if catagory has already been checked
            if (index >= 0) {
                // uncheck
                filtersDupe[catagory].splice(index, 1); // removes index that was checked
                if (filtersDupe[catagory].length === 0) {
                    delete filtersDupe[catagory]; // delete object when empty
                }
            } else {
                // check
                filtersDupe[catagory].push(value);
            }
        } else {
            // first time filtering/checking catagory
            filtersDupe = { ...filtersDupe, [catagory]: [value] };
        }

        setFilters(filtersDupe);
        setSkip(0);
        setTotalCount(0);
        getResult(keyword, 0, filtersDupe, sortBy);
    };

    const clearFilters = () => {
        setFilters({});
        setSortBy(null);
        setSkip(0);
        setTotalCount(0);
        getResult(keyword, 0, {}, null);
    };

    const sortResults = (value) => {
        setSortBy(value);
        setSkip(0);
        setTotalCount(0);
        getResult(keyword, 0, filters, value);
    };

    const filterRender = (
        field,
        title,
        setShowFunction,
        show,
        setShowMoreFunction,
        showMore,
        titlePlural
    ) => {
        return facet[field] && facet[field].length > 0 ? (
            <div>
                <h6
                    className={
                        Object.keys(filters).length !== 0 && filters[field]
                            ? 'filter-options filter-selected'
                            : 'filter-options'
                    }
                    onClick={() => setShowFunction(!show)}
                >
                    <span>
                        {title}{' '}
                        {filters[field] ? (
                            <b>({filters[field].length})</b>
                        ) : null}
                    </span>
                    <span>
                        {show ? (
                            <KeyboardArrowDownIcon />
                        ) : (
                            <KeyboardArrowRightIcon />
                        )}
                    </span>
                </h6>
                <div
                    style={
                        show
                            ? {
                                  display: 'block',
                                  marginBottom: '20px',
                              }
                            : { display: 'none' }
                    }
                >
                    {renderFilter(
                        showMore ? facet[field] : facet[field].slice(0, 10),
                        field
                    )}
                    {facet[field].length > 10 ? (
                        <div
                            className="filter-see-more"
                            onClick={() => {
                                setShowMoreFunction(!showMore);
                            }}
                        >
                            <span>
                                {showMore ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </span>
                            <span>{`${
                                showMore ? 'Show Less' : 'Show More'
                            } ${titlePlural}`}</span>
                        </div>
                    ) : null}
                </div>
            </div>
        ) : null;
    };

    const removeDuplicates = (results) => {
        let flags = {};
        let newResults = results.filter(function (entry) {
            if (flags[entry.document.companyName]) {
                return false;
            }
            flags[entry.document.companyName] = true;
            return true;
        });
        return newResults;
    };

    //return nothing while fetching data
    if (loading) {
        return null;
    }

    return (
        <div className="container-fluid search-result">
            <h4 className="prospect-name search-title">Search Results</h4>
            <Row className="row-results">
                {facet ? (
                    <>
                        <Col lg={2} className="filter-section">
                            <h3 className="filter-title">Filter</h3>
                            <h3 className="help-text">
                                Filtering removes unwanted data from the results
                                displayed
                            </h3>
                            <h6
                                className={
                                    Object.keys(filters).length === 0
                                        ? 'filter-options filter-selected'
                                        : 'filter-options'
                                }
                                onClick={() => clearFilters()}
                            >
                                {Object.keys(filters).length === 0
                                    ? 'Default'
                                    : 'Clear All Filters'}
                            </h6>
                            {filterRender(
                                'CompanyName',
                                'Company',
                                setShowCompany,
                                showCompany,
                                setShowMoreCompany,
                                showMoreCompany,
                                'Companies'
                            )}
                            {filterRender(
                                'Title',
                                'Position',
                                setShowPosition,
                                showPosition,
                                setShowMorePosition,
                                showMorePosition,
                                'Positions'
                            )}
                            {filterRender(
                                'Function',
                                'Function',
                                setShowFunction,
                                showFunction,
                                setShowMoreFunction,
                                showMoreFunction,
                                'Functions'
                            )}
                            {filterRender(
                                'Level',
                                'Level',
                                setShowLevel,
                                showLevel,
                                setShowMoreLevel,
                                showMoreLevel,
                                'Levels'
                            )}
                        </Col>
                        {results ? (
                            <Col className="search-result-section">
                                <div className="sort-result-section">
                                    <div
                                        style={{
                                            paddingTop: '5px',
                                        }}
                                    >
                                        Showing{' '}
                                        <span className="bold-500">
                                            {skip + 1}
                                        </span>{' '}
                                        -{' '}
                                        <span className="bold-500">
                                            {top * (skip / top + 1) >=
                                            totalCount
                                                ? totalCount
                                                : top * (skip / top + 1)}
                                        </span>{' '}
                                        of{' '}
                                        <span className="bold-500">
                                            {totalCount}
                                        </span>{' '}
                                        results
                                    </div>
                                    <div className="sortby-section">
                                        <div
                                            style={{
                                                paddingTop: '5px',
                                                marginRight: '10px',
                                            }}
                                        >
                                            Sort by
                                        </div>
                                        <DropdownButton
                                            title={
                                                <span>
                                                    {sortOptions[sortBy] ||
                                                        'Default'}{' '}
                                                    <KeyboardArrowDownIcon />
                                                </span>
                                            }
                                            id="dropdown-menu-align-right"
                                            className="sortby-dropdown"
                                        >
                                            <Dropdown.Item
                                                eventKey="1"
                                                onClick={() =>
                                                    sortResults(null)
                                                }
                                            >
                                                Default
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="2"
                                                onClick={() =>
                                                    sortResults('FirstName')
                                                }
                                            >
                                                First Name
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="3"
                                                onClick={() =>
                                                    sortResults('LastName')
                                                }
                                            >
                                                Last Name
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="4"
                                                onClick={() =>
                                                    sortResults('CompanyName')
                                                }
                                            >
                                                Company
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="5"
                                                onClick={() =>
                                                    sortResults('Title')
                                                }
                                            >
                                                Position
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </div>
                                <div className="result-cards">Accounts</div>
                                <CardGroup>
                                    {removeDuplicates(results).map(
                                        (result, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="search-result-link"
                                                >
                                                    <Card className="search-result-card">
                                                        <Card.Body
                                                            className="card-style"
                                                            style={{
                                                                borderTopColor:
                                                                    '#5d7b9a',
                                                            }}
                                                        >
                                                            <div className="prospect-background">
                                                                {/* Removed Company Logo Here */}

                                                                <h5
                                                                    style={{
                                                                        marginTop:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    {provideLinkToCompanyPage(
                                                                        result
                                                                            .document
                                                                            .accountId
                                                                    ) ? (
                                                                        <Link
                                                                            to={{
                                                                                pathname: `/company/${result.document.accountId}`,
                                                                            }}
                                                                            state={{
                                                                                prevPath:
                                                                                    location,
                                                                            }}
                                                                            className="prospect-name"
                                                                        >
                                                                            {result
                                                                                .document
                                                                                .companyName
                                                                                ? formatAccountName(
                                                                                      result
                                                                                          .document
                                                                                          .companyName
                                                                                  )
                                                                                : null}
                                                                        </Link>
                                                                    ) : (
                                                                        <p className="prospect-name">
                                                                            {result
                                                                                .document
                                                                                .companyName
                                                                                ? formatAccountName(
                                                                                      result
                                                                                          .document
                                                                                          .companyName
                                                                                  )
                                                                                : null}
                                                                        </p>
                                                                    )}
                                                                </h5>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            );
                                        }
                                    )}
                                </CardGroup>
                                <div
                                    className="result-cards"
                                    style={{
                                        paddingTop: '30px',
                                    }}
                                >
                                    Contacts
                                </div>
                                <CardGroup>
                                    {results.map((result, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="search-result-link"
                                            >
                                                <Card className="search-result-card">
                                                    <Card.Body
                                                        className="card-style"
                                                        style={{
                                                            borderTopColor:
                                                                '#F6871F',
                                                        }}
                                                    >
                                                        <div className="prospect-background">
                                                            {/*{result.document.photoUrl ?
                                                                <img
                                                                    className='search-profile-picture'
                                                                    src={result.document.photoUrl}
                                                                    alt='Profile Picture'
                                                                />
                                                                :
                                                                <DefaultPhoto/>
                                                            }*/}
                                                            {result.document
                                                                .photoUrl ? (
                                                                <div
                                                                    className="profile-pic"
                                                                    style={{
                                                                        backgroundImage: `url(${result.document.photoUrl})`,
                                                                    }}
                                                                ></div>
                                                            ) : (
                                                                <DefaultPhoto />
                                                            )}
                                                            <h5
                                                                style={{
                                                                    marginTop:
                                                                        '10px',
                                                                }}
                                                            >
                                                                <Link
                                                                    className="prospect-name"
                                                                    to={{
                                                                        pathname: `/prospect/${result.document.id}`,
                                                                    }}
                                                                    state={{
                                                                        prevPath:
                                                                            location,
                                                                    }}
                                                                >
                                                                    {
                                                                        result
                                                                            .document
                                                                            .name
                                                                    }
                                                                </Link>
                                                            </h5>
                                                            <div className="current-title">
                                                                {
                                                                    result
                                                                        .document
                                                                        .title
                                                                }
                                                            </div>
                                                            {provideLinkToCompanyPage(
                                                                result.document
                                                                    .accountId
                                                            ) ? (
                                                                <Link
                                                                    to={{
                                                                        pathname: `/company/${result.document.accountId}`,
                                                                    }}
                                                                    state={{
                                                                        prevPath:
                                                                            location,
                                                                    }}
                                                                    className="company-name"
                                                                >
                                                                    {result
                                                                        .document
                                                                        .companyName
                                                                        ? formatAccountName(
                                                                              result
                                                                                  .document
                                                                                  .companyName
                                                                          )
                                                                        : null}
                                                                </Link>
                                                            ) : (
                                                                <p className="company-name">
                                                                    {result
                                                                        .document
                                                                        .companyName
                                                                        ? formatAccountName(
                                                                              result
                                                                                  .document
                                                                                  .companyName
                                                                          )
                                                                        : null}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        );
                                    })}
                                </CardGroup>
                                <div className="search-result-paging">
                                    {totalCount !== 0 && skip !== 0 ? (
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() =>
                                                changePage('previous')
                                            }
                                        >
                                            Previous
                                        </Button>
                                    ) : (
                                        <div></div>
                                    )}
                                    {totalCount !== 0 &&
                                    Math.ceil(totalCount / top) !==
                                        (skip + top) / top ? (
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => changePage('next')}
                                        >
                                            Next
                                        </Button>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </Col>
                        ) : (
                            <div className="no-result">
                                Search for "{keyword}"
                                <br />
                                Sorry, there are no records found for this
                                person or company based on the filters selected.
                            </div>
                        )}
                    </>
                ) : (
                    <div className="no-result">
                        Search for "{keyword}"
                        <br />
                        Sorry, there are no records found for this person or
                        company.
                    </div>
                )}
            </Row>
        </div>
    );
};
