import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import './CompanyOverview.css';
import Card from 'react-bootstrap/Card';
import { NotAvailable } from '../HoverOver/NotAvailable';
import BootstrapTable from '@musicstory/react-bootstrap-table-next';
import paginationFactory from '@musicstory/react-bootstrap-table2-paginator';
import DefaultPhoto from '../Shared/DefaultPhoto';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EmployeeLinks } from '../Shared/EmployeeLinks';
import filterFactory, {
    customFilter,
    FILTER_TYPES,
} from '@musicstory/react-bootstrap-table2-filter';
import {
    getUrlLink,
    formatDate,
    formatMonthYearDate,
    renderSortArrow,
    triggerFilter,
    handleOnFilterTable,
    asteriskHoverover,
    getOnStaticOptionsUpdate,
    renderExternalNotAvailable,
} from '../Shared/Util';
import { FilterMultiSelect } from '../Shared/FilterMultiSelect';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export const Alumni = () => {
    const { account } = useContext(GlobalContext);
    const [alumni, setAlumni] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [showFiltersModal, setShowFiltersModal] = useState(true);
    const [clientSideSettings] = useState(
        JSON.parse(localStorage.getItem('ClientSideSettings'))
    );
    const [currentFilters, setCurrentFilters] = useState({});
    const [staticOption, setStaticOption] = useState({});
    const [filteredData, setFilteredData] = useState([]);

    let nameFilter, titleFilter;

    const clearAllFiltersPKB = () => {
        nameFilter([]);
        titleFilter([]);
        setCurrentFilters({});
        setStaticOption({});
    };

    useEffect(() => {
        if (
            account &&
            account.protivitiAlumni &&
            account.protivitiAlumni.length !== 0
        ) {
            setAlumni(account.protivitiAlumni);
            setFilteredData(account.protivitiAlumni);

            if (account.protivitiAlumni.length < 2) {
                setShowFilters(!showFilters);
            }
        }
    }, [account]);

    const onStaticOptionsUpdate = (staticColumn, filterKey, noFilters) => {
        if (noFilters || !staticOption.hasOwnProperty(filterKey))
            setStaticOption(
                getOnStaticOptionsUpdate(
                    staticColumn,
                    filterKey,
                    noFilters,
                    staticOption
                )
            );
    };

    const afterFilter = (newResult, newFilters) => {
        setCurrentFilters((prevData) => {
            if (JSON.stringify(prevData) !== JSON.stringify(newFilters)) {
                return newFilters;
            }
            return prevData;
        });

        setFilteredData((prevData) => {
            if (JSON.stringify(prevData) !== JSON.stringify(newResult)) {
                return newResult;
            }
            return prevData;
        });
    };

    const tableColumn = [
        {
            dataField: 'photoUrl',
            text: '',
            sort: false,
            formatter: (value, row) =>
                value ? (
                    <img
                        className="internal-connection-picture"
                        src={value}
                        alt="Profile"
                        style={{
                            borderColor: '#00acc4',
                            width: '60px',
                            height: '60px',
                        }}
                    />
                ) : (
                    <DefaultPhoto
                        format="square"
                        style={{
                            borderColor: '#00acc4',
                            width: '60px',
                            height: '60px',
                        }}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '40px' },
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortCaret: renderSortArrow,
            sortValue: (value, row) => value.split(' ')[1],
            formatter: (value, row) => (
                <div>
                    <div>
                        <Link
                            className="card-title-company-name"
                            style={{ paddingBottom: '10px' }}
                            to={`/prospect/${row.id}`}
                        >
                            {value}
                        </Link>
                    </div>
                    {!row.isDeleted ? (
                        <EmployeeLinks
                            data={row}
                            isPeopleSearchLive={
                                clientSideSettings.isPeopleSearchLive
                            }
                            peopleSearchUrl={encodeURI(
                                clientSideSettings.peopleSearchUrl
                            )}
                            external={true}
                        />
                    ) : null}
                </div>
            ),
            headerStyle: { fontWeight: '500', width: '190px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    nameFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'name'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={alumni}
                    filterKey={'name'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable section="internal" showNotAvailable={true} />
                ),
            headerStyle: { fontWeight: '500', width: '250px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    titleFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'title'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={alumni}
                    filterKey={'title'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'id',
            text: 'key',
            hidden: true,
        },
    ];

    const padZero = (e) => {
        return e < 10 ? '0' + e : e;
    };

    const handleShowMore = () => {
        setShowMore(!showMore);
        clearAllFiltersPKB();
    };

    const showMoreModal = () => {
        return (
            <Modal
                show={showMore}
                onHide={handleShowMore}
                dialogClassName="custom-dialog"
                data-testid="alumni-table-modal"
            >
                <Modal.Header
                    closeButton
                    className="card-style"
                    style={{ borderTopColor: '#5D7B9A' }}
                >
                    <Modal.Title>
                        <div>
                            <h4 className="card-title-modal">
                                <PeopleAltIcon fontSize="large" /> Protiviti
                                Alumni{' '}
                                {alumni.length > 0 ? null : (
                                    <NotAvailable
                                        section="internal-section"
                                        linkUrl={getUrlLink(
                                            'imanageFilter',
                                            account.id
                                        )}
                                        showNotAvailable={false}
                                    />
                                )}
                            </h4>
                        </div>
                    </Modal.Title>
                    <div className="trigger-filter-modal">
                        <div className="filter-button">
                            <div>
                                {triggerFilter(
                                    showFiltersModal,
                                    setShowFiltersModal
                                )}
                            </div>
                            <div className="clear-filter">
                                <div
                                    className="text-primary"
                                    onClick={clearAllFiltersPKB}
                                    style={{
                                        visibility: showFiltersModal
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                >
                                    Clear Filters
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body-class">
                    {
                        <BootstrapTable
                            keyField="id"
                            data={alumni}
                            columns={tableColumn}
                            striped={true}
                            bordered={false}
                            hover={true}
                            defaultSorted={[
                                {
                                    dataField: 'name',
                                    order: 'asc',
                                },
                            ]}
                            pagination={paginationFactory({
                                page: 1,
                                sizePerPage: 25,
                                hideSizePerPage: true,
                                hidePageListOnlyOnePage: true,
                            })}
                            filter={filterFactory({
                                afterFilter,
                            })}
                        />
                    }
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <Card data-testid="alumni">
            <Card.Body
                className="card-style"
                style={{
                    borderTopColor: '#5D7B9A',
                    overflowY: 'hidden',
                    overflowX: alumni.length > 0 ? 'auto' : 'none',
                }}
            >
                <div className="space-between">
                    <div>
                        <h4
                            className="card-title"
                            style={{
                                paddingBottom: '0px',
                                marginBottom: '0px',
                                alignSelf: 'center',
                            }}
                        >
                            <PeopleAltIcon fontSize="large" /> Protiviti Alumni
                        </h4>
                    </div>
                    {alumni.length > 1 ? (
                        <div className="filter-button">
                            <div>
                                {triggerFilter(showFilters, setShowFilters)}
                            </div>
                            <div className="clear-filter">
                                <div
                                    className="text-primary"
                                    onClick={clearAllFiltersPKB}
                                    style={{
                                        visibility: showFilters
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                >
                                    Clear Filters
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                {alumni.length > 0 ? (
                    <div className="result-table" data-testid="alumni-table">
                        <BootstrapTable
                            keyField="id"
                            data={alumni}
                            columns={tableColumn}
                            striped={true}
                            bordered={false}
                            hover={true}
                            defaultSorted={[
                                {
                                    dataField: 'name',
                                    order: 'asc',
                                },
                            ]}
                            pagination={paginationFactory({
                                page: 1,
                                sizePerPage: 5,
                                hideSizePerPage: true,
                                hidePageListOnlyOnePage: true,
                            })}
                            filter={filterFactory({
                                afterFilter,
                            })}
                        />
                        <Button
                            style={{
                                float: 'right',
                                margin: '2%',
                                display: alumni.length <= 5 ? 'none' : null,
                            }}
                            onClick={handleShowMore}
                            variant="outline-secondary"
                        >
                            {'View More'}
                        </Button>
                        {showMoreModal()}
                    </div>
                ) : account.isExternalOnly ? (
                    renderExternalNotAvailable('account')
                ) : (
                    <>
                        <hr />
                        <div className="not-available">Not Available</div>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};
