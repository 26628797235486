import React, {useState} from 'react';
import BootstrapTable from '@musicstory/react-bootstrap-table-next';
import ToolkitProvider from '@musicstory/react-bootstrap-table2-toolkit';
import excelIcon from '../../images/Excel-Workbook.png';
import filterFactory, {
  customFilter,
  FILTER_TYPES,
} from '@musicstory/react-bootstrap-table2-filter';


export const Export = (myProps) => {
  const [currentFilters, setCurrentFilters] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const ExportCSVButton = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div className='excel' style={{
        width: "70px",
        display: "flex",
        justifyContent: "center",
        marginTop: "2px"
      }}>
        <img onClick={ handleClick } style={{width:"32px", height:"32px", cursor: "pointer"}} src={excelIcon}></img>
      </div>
    );
  };

  const afterFilter = (newResult, newFilters) => {
    setCurrentFilters((prevData) => {
        if (JSON.stringify(prevData) !== JSON.stringify(newFilters)) {
            return newFilters;
        }
        return prevData;
    });

    setFilteredData((prevData) => {
        if (JSON.stringify(prevData) !== JSON.stringify(newResult)) {
            return newResult;
        }
        return prevData;
    });
  }

  return ( 
      <ToolkitProvider
              keyField={myProps.keyField}
              data={myProps.data}
              columns={myProps.columns}
              exportCSV={{fileName:`${myProps.accountName}`+ `_${myProps.exportTableType}.csv`}}
              >
              {props => (
                      <div style={{
                          width: '70px',
                          margin: '22px 0px 0px 2px'
                      }}>
                          <div style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginTop: "-4px",
                              marginRight: "30px",
                          }}>
                              <div style={{
                                  width:"32px", 
                                  height: "32px",
                                  display: "flex",
                                  justifyContent: "center",
                                  marginBottom: "2px"}}>
                                    <ExportCSVButton { ...props.csvProps }></ExportCSVButton>
                              </div>
                              <p className="card-link" style={{color:'#808080', fontStyle:'italic', marginTop:'3px'}}>Export</p>
                          <div style={{display: "none"}}>
                          <BootstrapTable
                                        filter={filterFactory({
                                          afterFilter,
                                      })} 
                                      {...props.baseProps}/>
                          </div>
                      </div>
                      </div>
                  )
              }
        </ToolkitProvider>
    );
}
