import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from '@musicstory/react-bootstrap-table-next';
import paginationFactory from '@musicstory/react-bootstrap-table2-paginator';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import { NotAvailable } from '../HoverOver/NotAvailable';
import { renderSortArrow, formatDate, getFormatCompanyLink } from './Util';
import infoIcon from '../../images/i-icon.png';
import { OverlayTooltip } from '../HoverOver/OverlayTooltip';
import { ExternalDetail } from '../HoverOver/ExternalDetail';
import { Export } from './Export';

export const ScoopsIntents = () => {
    const { account, scoops, intents } = useContext(GlobalContext);
    const [showMore, setShowMore] = useState(false);
    const [componentState, setComponentState] = useState('scoops');
    const [scoopLastModifiedDate, setScoopLastModifiedDate] = useState(null);
    const [intentLastModifiedDate, setIntentLastModifiedDate] = useState(null);

    useEffect(() => {
        if (scoops && scoops.length > 0) {
            setScoopLastModifiedDate(scoops[0].publishedDate)
        }
        if (intents && intents.length > 0) {
            setIntentLastModifiedDate(intents[0].signalDate)
        }
    });

    const ScoopsTableColumns = [
        {
            dataField: 'publishedDate',
            text: 'Published Date',
            formatter: (value, row) =>
                value && value.substring(0, 4) !== '0001' ? (
                    formatDate(value)
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '100px' },
            csvFormatter: (value, row) =>
                value && value.substring(0, 4) !== '0001'
                    ? formatDate(value)
                    : "Not Applicable/Not Available"
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            headerStyle: { fontWeight: '500', width: '500px' },
            csvFormatter: (value, row) =>
                value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'topic',
            text: 'Topic',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            headerStyle: { fontWeight: '500', width: '100px' },
            csvFormatter: (value, row) =>
                value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'type',
            text: 'Types',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            headerStyle: { fontWeight: '500', width: '100px' },
            csvFormatter: (value, row) =>
                value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'contact',
            text: 'Contacts',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            headerStyle: { fontWeight: '500', width: '100px' },
            csvFormatter: (value, row) =>
                value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'id',
            hidden: true,
            csvExport: false
        },
    ];

    const IntentTableColumns = [
        {
            dataField: 'signalDate',
            text: 'Signal Dates',
            formatter: (value, row) =>
                value && value.substring(0, 4) !== '0001' ? (
                    formatDate(value)
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '100px' },
            csvFormatter: (value, row) =>
                value && value.substring(0, 4) !== '0001'
                    ? formatDate(value)
                    : "Not Applicable/Not Available"
        },
        {
            dataField: 'category',
            text: 'Category',
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            sort: false,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '100px' },
            csvFormatter: (value, row) =>
                value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'topic',
            text: 'Topic',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            headerStyle: { fontWeight: '500', width: '100px' },
            csvFormatter: (value, row) =>
                value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'signalScore',
            text: 'Score',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            headerStyle: { fontWeight: '500', width: '100px' },
            hidden: true,
            csvExport: false,
        },
        {
            dataField: 'audienceStrength',
            text: 'Audience Strength',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    audienceStrengthMap(value)
                ) : (
                    <NotAvailable section="external" showNotAvailable={true} />
                ),
            headerStyle: { fontWeight: '500', width: '100px' },
            headerFormatter: (column) => (
                <span>
                    {column.text}{' '}
                    <OverlayTooltip
                        tooltipText={
                            'How large the audience was that conducted the research, with a larger group represented by a 5.'
                        }
                        hoverElement={
                            <img src={infoIcon} className="notavailable-icon" />
                        }
                    />
                </span>
            ),
            csvFormatter: (value, row) =>
                value ? audienceStrengthMap(value) : "Not Applicable/Not Available"
        },
        {
            dataField: 'recommendedContacts',
            text: 'Recommended Contacts',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (
                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                    {value && value.map((element) => (
                        <li key={element.id}>
                            {element.firstName + ' ' + element.lastName}
                        </li>
                    ))}
                </ul>
            ),
            headerStyle: { fontWeight: '500', width: '150px' },
            csvFormatter: (value, row) => {
                return value.map(element => element.firstName + ' ' + element.lastName)
            }
        },
        {
            dataField: 'intentId',
            hidden: true,
            csvExport: false
        },
    ];

    const audienceStrengthMap = (letter) => {
        // A=5, B=4, C=3
        if (letter === 'A') return '5';
        else if (letter === 'B') return '4';
        else if (letter === 'C') return '3';
        else return <NotAvailable section="external" showNotAvailable={true} />;
    };

    const handleComponentStateChange = (from) => {
        if (componentState !== from) {
            if (componentState === 'scoops') {
                setComponentState('intents');
            } else {
                setComponentState('scoops');
            }
        }
    };

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const showMoreModal = () => {
        return (
            <Modal
                show={showMore}
                onHide={handleShowMore}
                dialogClassName="custom-dialog"
            >
                <Modal.Header
                    closeButton
                    className="card-style"
                    style={{ borderTopColor: '#00A28F' }}
                >
                    <Modal.Title>
                        {componentState === 'scoops' ? (
                            scoops && scoops.length > 0 ? (
                                <h4 className="card-title-modal">
                                    <span>
                                        <SpeakerNotesOutlinedIcon fontSize="large" />{' '}
                                        Scoops{' '}
                                        <span
                                            style={{
                                                lineHeight: 2,
                                            }}
                                        >
                                            {' '}
                                            <ExternalDetail />{' '}
                                        </span>{' '}
                                    </span>
                                    <div
                                        className="solution-subtitle-modal"
                                        style={{
                                            paddingLeft: '0px',
                                        }}
                                    >
                                        Proprietary surveys and phone interviews
                                        that are conducted by ZoomInfo's
                                        research team, as well as news from the
                                        general web.
                                    </div>
                                </h4>
                            ) : null
                        ) : intents && intents.length > 0 ? (
                            <h4 className="card-title-modal">
                                <span>
                                    <SpeakerNotesOutlinedIcon fontSize="large" />{' '}
                                    Intents{' '}
                                    <span
                                        style={{
                                            lineHeight: 2,
                                        }}
                                    >
                                        {' '}
                                        <ExternalDetail />{' '}
                                    </span>{' '}
                                </span>
                                <div
                                    className="solution-subtitle-modal"
                                    style={{
                                        paddingLeft: '0px',
                                    }}
                                >
                                    Topics that companies are actively
                                    researching, beyond two standards of
                                    deviation of standard research activity by
                                    the company.
                                </div>
                            </h4>
                        ) : null}
                    </Modal.Title>
                    <div className="trigger-filter-modal">
                        <div className="sfdc-contact-owner">
                            {getFormatCompanyLink(account.id, account.name)}
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body
                    style={{
                        overflowX: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingTop: '0px',
                        paddingBottom: '80px',
                    }}
                >
                    {
                        <BootstrapTable
                            keyField="id"
                            data={
                                componentState === 'scoops' ? scoops : intents
                            }
                            columns={
                                componentState === 'scoops'
                                    ? ScoopsTableColumns
                                    : IntentTableColumns
                            }
                            striped={true}
                            bordered={false}
                            hover={true}
                        />
                    }
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <Card>
            <Card.Body
                className="card-style"
                style={{
                    borderTopColor: '#00A28F',
                    overflowY: 'hidden',
                    overflowX:
                        (scoops && scoops.length > 0) ||
                            (intents && intents.length > 0)
                            ? 'auto'
                            : 'none',
                }}
            >
                <div className="space-between" style={{ height: '100px' }}>
                    <div className="card-title">
                        <div>
                            <SpeakerNotesOutlinedIcon
                                fontSize="large"
                                style={{ float: 'left' }}
                            />
                            <button
                                className="opportunity-title"
                                onClick={(e) =>
                                    handleComponentStateChange('scoops', e)
                                }
                                style={{
                                    float: 'left',
                                    fontWeight:
                                        componentState === 'scoops'
                                            ? 'bold'
                                            : null,
                                    borderBottom:
                                        componentState === 'scoops'
                                            ? '3px solid #F6871F'
                                            : 'none',
                                    color: '#004068',
                                }}
                            >
                                <span>
                                    {' '}
                                    Scoops{' '}
                                    <span
                                        style={{
                                            lineHeight: 2,
                                        }}
                                    >
                                        {' '}
                                        <ExternalDetail />{' '}
                                    </span>{' '}
                                </span>
                                {!scoops || scoops.length === 0 ? (
                                    <NotAvailable
                                        section="external"
                                        showNotAvailable={false}
                                    />
                                ) : null}
                            </button>
                            <div
                                style={{
                                    height: 60,
                                    width: 1,
                                    background: '#000',
                                    float: 'left',
                                    marginLeft: 30,
                                    marginRight: 30,
                                    marginTop: -10,
                                }}
                            ></div>
                            <button
                                className="opportunity-title"
                                onClick={(e) =>
                                    handleComponentStateChange('intents', e)
                                }
                                style={{
                                    float: 'center',
                                    fontWeight:
                                        componentState === 'intents'
                                            ? 'bold'
                                            : null,
                                    borderBottom:
                                        componentState === 'intents'
                                            ? '3px solid #F6871F'
                                            : 'none',
                                    marginLeft: '0',
                                    color: '#004068',
                                }}
                            >
                                <span>
                                    {' '}
                                    Intents{' '}
                                    <span
                                        style={{
                                            lineHeight: 2,
                                        }}
                                    >
                                        {' '}
                                        <ExternalDetail />{' '}
                                    </span>{' '}
                                </span>
                                {!intents || intents.length === 0 ? (
                                    <NotAvailable
                                        section="external"
                                        showNotAvailable={false}
                                    />
                                ) : null}
                            </button>
                        </div>
                        <div
                            className="solution-subtitle"
                            style={{
                                float: 'left',
                                paddingLeft: '0px',
                            }}
                        >
                            {componentState === 'scoops'
                                ? "Proprietary surveys and phone interviews that are conducted by ZoomInfo's research team, as well as news from the general web."
                                : 'Topics that companies are actively researching, beyond two standards of deviation of standard research activity by the company.'}
                        </div>
                    </div>

                    <div className="sfdc-contact-owner" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '45px' }}>
                        {getFormatCompanyLink(account.id, account.name)}
                        {componentState === 'scoops' && scoops && scoops.length > 0
                            ? <Export
                                keyField="id"
                                data={scoops}
                                columns={ScoopsTableColumns}
                                accountName={account.name}
                                exportTableType="Scoops"
                            ></Export>
                            : null}

                        {componentState === 'intents' && intents && intents.length > 0
                            ? <Export
                                keyField="id"
                                data={intents}
                                columns={IntentTableColumns}
                                accountName={account.name}
                                exportTableType="Intents"
                            ></Export>
                            : null}
                    </div>
                </div>
                {componentState === 'scoops' ? (
                    scoops && scoops.length > 0 ? (
                        <div className="result-table">
                            <div style={{ padding: '10px' }}>
                                <span className="header-fields">
                                    Last Updated:{' '}
                                </span>
                                {scoopLastModifiedDate
                                    ? formatDate(scoopLastModifiedDate)
                                    : 'Not Available'}
                            </div>
                            <BootstrapTable
                                keyField="id"
                                data={scoops}
                                columns={ScoopsTableColumns}
                                striped={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({
                                    page: 1,
                                    sizePerPage: 5,
                                    hideSizePerPage: true,
                                    hidePageListOnlyOnePage: true,
                                })}
                            />
                            <Button
                                style={{
                                    float: 'right',
                                    margin: '2%',
                                    display:
                                        scoops && scoops.length <= 5
                                            ? 'none'
                                            : null,
                                }}
                                onClick={handleShowMore}
                                variant="outline-secondary"
                            >
                                {'View More'}
                            </Button>
                            {showMoreModal()}
                        </div>
                    ) : (
                        <>
                            <hr />
                            <div className="not-available">Not Available</div>
                        </>
                    )
                ) : intents && intents.length > 0 ? (
                    <div className="result-table">
                        <div style={{ padding: '10px' }}>
                            <span className="header-fields">
                                Last Updated:{' '}
                            </span>
                            {intentLastModifiedDate
                                ? formatDate(intentLastModifiedDate)
                                : 'Not Available'}
                        </div>
                        <BootstrapTable
                            keyField="id"
                            data={intents}
                            columns={IntentTableColumns}
                            striped={true}
                            bordered={false}
                            hover={true}
                            pagination={paginationFactory({
                                page: 1,
                                sizePerPage: 5,
                                hideSizePerPage: true,
                                hidePageListOnlyOnePage: true,
                            })}
                        />
                        <Button
                            style={{
                                float: 'right',
                                margin: '2%',
                                display:
                                    intents && intents.length <= 5
                                        ? 'none'
                                        : null,
                            }}
                            onClick={handleShowMore}
                            variant="outline-secondary"
                        >
                            {'View More'}
                        </Button>
                        {showMoreModal()}
                    </div>
                ) : (
                    <>
                        <hr />
                        <div className="not-available">Not Available</div>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};
