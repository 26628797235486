import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import Card from 'react-bootstrap/Card';
import protivitiLogo from '../../images/protiviti-logo.png';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import { NotAvailable } from '../HoverOver/NotAvailable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DropdownButton, Dropdown } from 'react-bootstrap/';
import { getUrlLink, renderExternalNotAvailable } from '../Shared/Util';

export const ProtivitiWorkSolution = () => {
    const { account } = useContext(GlobalContext);
    const [solutionPieChart, setPieChart] = useState([]);
    const [solutionTime, setSolutionTime] = useState('All');
    const solutionColor = [
        '#00ACC4',
        '#F6871F',
        '#00A28F',
        '#D74B29',
        '#3C3D3F',
        '#004068',
    ]; // first 6 colors predetermined
    const [noSolution] = useState([
        {
            name: 'noSolution',
            value: 1,
            color: '#c8c9c7',
        },
    ]);
    const [projectCount, setProjectCount] = useState(0);

    useEffect(() => {
        if (account) {
            getSolutionPieChart('All');
        }
    }, [account]);

    const getSolutionPieChart = (time) => {
        let solutions = [];
        let counts = [];

        switch (time) {
            case 'Rolling 12 Months':
                solutions = account.solutionNames1;
                counts = account.solutionCounts1;
                break;
            case 'Past 3 Years':
                solutions = account.solutionNames3;
                counts = account.solutionCounts3;
                break;
            case 'Past 5 Years':
                solutions = account.solutionNames5;
                counts = account.solutionCounts5;
                break;
            default:
                solutions = account.solutionNamesA
                    ? account.solutionNamesA
                    : [];
                counts = account.solutionCountsA ? account.solutionCountsA : [];
        }

        let pieChart = [];

        if (solutions.length > 0 && counts.length > 0) {
            const sortedSolutionObject = solutions
                .map((solution, index) => ({
                    solutionNames: solution,
                    solutionCounts: counts[index],
                }))
                .sort((a, b) => {
                    return b.solutionCounts - a.solutionCounts;
                });

            let solutionNotAvailable = null;
            let numberOfProjects = 0;

            for (let i = 0; i < sortedSolutionObject.length; i++) {
                if (
                    sortedSolutionObject[i].solutionNames ===
                    'Solution Not Available'
                ) {
                    solutionNotAvailable = {
                        name: 'Solution Not Available',
                        value: sortedSolutionObject[i].solutionCounts,
                        color: '#c8c9c7',
                    };
                } else {
                    let data = {
                        name: sortedSolutionObject[i].solutionNames,
                        value: sortedSolutionObject[i].solutionCounts,
                        color:
                            i < 6
                                ? solutionColor[i]
                                : 'rgb(' +
                                  (Math.floor(Math.random() * 255) + 1) +
                                  ',' +
                                  (Math.floor(Math.random() * 255) + 1) +
                                  ',' +
                                  (Math.floor(Math.random() * 255) + 1) +
                                  ')',
                    };
                    pieChart.push(data);
                }
                numberOfProjects += sortedSolutionObject[i].solutionCounts;
            }

            setProjectCount(numberOfProjects);

            // Solution Not Available last
            if (solutionNotAvailable) {
                pieChart.push(solutionNotAvailable);
            }
        }

        setPieChart(pieChart);
    };

    const CustomTooltip = (e) => {
        if (!e.active) {
            return null;
        }
        return <div className="solution-tooltip">{e.payload[0].value}</div>;
    };

    const onSolutionTimeChange = (time) => {
        setSolutionTime(time);
        getSolutionPieChart(time);
    };

    const pieChartRender = (data) => {
        return (
            <ResponsiveContainer height={300} width="100%">
                <PieChart
                    margin={{
                        top: 30,
                        right: 20,
                        left: 20,
                        bottom: 30,
                    }}
                >
                    <Pie
                        dataKey="value"
                        data={data}
                        innerRadius={120}
                        outerRadius={150}
                    >
                        {data.map((entry, i) => (
                            <Cell key={i} fill={entry.color} />
                        ))}
                    </Pie>
                    {data[0].name === 'noSolution' ? null : (
                        <Tooltip content={CustomTooltip} />
                    )}
                </PieChart>
            </ResponsiveContainer>
        );
    };

    return (
        <Card>
            <Card.Body
                className="card-style"
                style={{ borderTopColor: '#F6871F' }}
            >
                <h4
                    className="card-title"
                    id="card-title-id"
                    style={{
                        paddingBottom: '0px',
                        marginBottom: '0px',
                    }}
                >
                    <DonutSmallOutlinedIcon fontSize="large" /> Protiviti Work
                    by Solution{' '}
                    {(account.solutionNamesA &&
                        account.solutionNamesA.length > 0 &&
                        solutionPieChart.length > 0) ||
                    account.isExternalOnly ? null : (
                        <NotAvailable
                            section="solution"
                            showNotAvailable={false}
                            linkUrl={getUrlLink('imanageFilter', account.id)}
                        />
                    )}
                </h4>
                {account.solutionNamesA && account.solutionNamesA.length > 0 ? (
                    <div data-testid="protiviti-work-solution-graph">
                        <div
                            className="solution-subtitle"
                            style={{ paddingBottom: '0px' }}
                        >
                            These are projects that have incurred fees over the
                            given time period.
                        </div>
                        <div className="sfdc-contact-owner">
                            <div className="employee-name">
                                Total # of Projects: {projectCount}
                            </div>
                        </div>
                        <div className="solution-pie">
                            {solutionPieChart.length > 0
                                ? pieChartRender(solutionPieChart)
                                : pieChartRender(noSolution)}
                            <div>
                                <img
                                    className="icon-protiviti-logo"
                                    src={protivitiLogo}
                                    alt="Protiviti"
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    placeContent: 'center',
                                }}
                            >
                                {solutionPieChart.length > 0 ? (
                                    <div className="solution-guide">
                                        {solutionPieChart.map((solution, i) => (
                                            <div
                                                className="solution-guide-item"
                                                key={i}
                                            >
                                                <div
                                                    className="solution-guide-color"
                                                    style={{
                                                        backgroundColor: `${solution.color}`,
                                                    }}
                                                ></div>
                                                <div>{solution.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                                <div
                                    style={{
                                        maxWidth: '45%',
                                        margin: '30px 20px 40px 20px',
                                    }}
                                >
                                    <div>Time Period</div>
                                    <div>
                                        <DropdownButton
                                            align="end"
                                            title={
                                                <span>
                                                    {solutionTime || 'All'}{' '}
                                                    <KeyboardArrowDownIcon />
                                                </span>
                                            }
                                            id="dropdown-menu-align-right"
                                            className="sortby-dropdown"
                                        >
                                            <Dropdown.Item
                                                eventKey="0"
                                                onClick={() =>
                                                    onSolutionTimeChange('All')
                                                }
                                            >
                                                All
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="1"
                                                onClick={() =>
                                                    onSolutionTimeChange(
                                                        'Rolling 12 Months'
                                                    )
                                                }
                                            >
                                                Rolling 12 Months
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="2"
                                                onClick={() =>
                                                    onSolutionTimeChange(
                                                        'Past 3 Years'
                                                    )
                                                }
                                            >
                                                Past 3 Years
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="3"
                                                onClick={() =>
                                                    onSolutionTimeChange(
                                                        'Past 5 Years'
                                                    )
                                                }
                                            >
                                                Past 5 Years
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : account.isExternalOnly ? (
                    renderExternalNotAvailable('account')
                ) : (
                    <>
                        <hr />
                        <div className="not-available">Not Available</div>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};
