import React, { useState, forwardRef, useImperativeHandle } from 'react';
import './Overview.css';
import Modal from 'react-bootstrap/Modal';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { authHeaders } from '../../auth/authHeaders';
import axios from 'axios';
import config from '../../config';
import BootstrapTable from '@musicstory/react-bootstrap-table-next';
import paginationFactory from '@musicstory/react-bootstrap-table2-paginator';
import '@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '@musicstory/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '@musicstory/react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { NotAvailable } from '../HoverOver/NotAvailable';
import {
    formatDate,
    renderSortArrow,
    triggerFilter,
    handleOnFilterTable,
    formatType,
    formatSource,
    getOnStaticOptionsUpdate,
} from '../Shared/Util';
import { FilterMultiSelect } from '../Shared/FilterMultiSelect';
import filterFactory, {
    customFilter,
    FILTER_TYPES,
} from '@musicstory/react-bootstrap-table2-filter';

export const NumberOfInteractions = forwardRef((props, ref) => {
    let sourceFilter, typeFilter, contactedFilter;
    const [type, setType] = useState();
    const [showFilters, setShowFilters] = useState(true);
    const [currentFilters, setCurrentFilters] = useState({});
    const [staticOption, setStaticOption] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [outlookMessagesCount, setOutlookMessagesCount] = useState(0);
    const [outlookEventCount, setOutlookEventCount] = useState(0);
    const [sfdcEventCount, setSfdcEventCount] = useState(0);
    const [sfdcTaskCount, setSfdcTaskCount] = useState(0);
    const [interactionDetail, setInteractionDetail] = useState({
        openModal: false,
        id: null,
        employeeId: null,
        emloyeeName: null,
        interactions: [],
        sourcefilter: null,
        typefilter: null,
        distinctSources: [],
        disctinctTypes: [],
        interactionGroups: [],
    });

    const clearAllFilters = () => {
        sourceFilter([]);
        typeFilter([]);
        contactedFilter([]);

        setCurrentFilters({});
        setStaticOption({});
    };

    const onStaticOptionsUpdate = (staticColumn, filterKey, noFilters) => {
        if (noFilters || !staticOption.hasOwnProperty(filterKey))
            setStaticOption(
                getOnStaticOptionsUpdate(
                    staticColumn,
                    filterKey,
                    noFilters,
                    staticOption
                )
            );
    };

    const afterFilter = (newResult, newFilters) => {
        setCurrentFilters((prevData) => {
            if (JSON.stringify(prevData) !== JSON.stringify(newFilters)) {
                return newFilters;
            }
            return prevData;
        });

        setFilteredData((prevData) => {
            if (JSON.stringify(prevData) !== JSON.stringify(newResult)) {
                return newResult;
            }
            return prevData;
        });
    };

    const InteractionTableColumns = [
        {
            dataField: 'id',
            text: 'key',
            hidden: true,
        },
        {
            dataField: 'source',
            text: 'Source',
            hidden: false,
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    formatSource(value)
                ) : (
                    <NotAvailable
                        section="internal-field"
                        showNotAvailable={true}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '140px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    sourceFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'source'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={interactionDetail.interactions}
                    filterKey={'source'}
                    showFilters={showFilters}
                    format={'interaction-source'}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'connectionType',
            text: 'Type',
            hidden: false,
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    formatType(value)
                ) : (
                    <NotAvailable
                        section="internal-field"
                        showNotAvailable={true}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '140px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    typeFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'connectionType'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={interactionDetail.interactions}
                    filterKey={'connectionType'}
                    showFilters={showFilters}
                    format={'interaction-type'}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'lastContacted',
            text: 'Last Contacted',
            hidden: false,
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value) =>
                value && value.substring(0, 4) !== '0001' ? (
                    formatDate(value)
                ) : (
                    <NotAvailable
                        section="internal-field"
                        showNotAvailable={true}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '180px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    contactedFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'lastContacted'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={interactionDetail.interactions}
                    filterKey={'lastContacted'}
                    showFilters={showFilters}
                    format={'date'}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
    ];

    useImperativeHandle(ref, () => ({
        openModal(
            id,
            type,
            employeeid,
            employeename,
            sourcefilter,
            typefilter
        ) {
            openmodalwithitem(
                id,
                type,
                employeeid,
                employeename,
                sourcefilter,
                typefilter
            );
        },
    }));

    const openmodalwithitem = async (
        id,
        type,
        employeeid,
        employeename,
        sourcefilter,
        typefilter
    ) => {
        let headers = await authHeaders();
        var interactions = [];
        var distinctSources = null;
        var disctinctTypes = null;
        var interactionGroups = null;
        let url = '';
        setType(type);
        if (type === 'prospect')
            url =
                config.azRedirectUri +
                '/api/prospects/intereactions/' +
                id +
                '/' +
                employeeid +
                '?sourcefilter=' +
                sourcefilter +
                '&typefilter=' +
                typefilter;
        else if (type === 'account')
            url =
                config.azRedirectUri +
                '/api/accounts/intereactions/' +
                id +
                '/' +
                employeeid +
                '?sourcefilter=' +
                sourcefilter +
                '&typefilter=' +
                typefilter;
        await axios.get(url, headers).then(function (response) {
            interactions = response.data.item1;
            interactionGroups = response.data.item2;
            distinctSources = response.data.item3;
            disctinctTypes = response.data.item4;
            if (interactions.length < 2) {
                setShowFilters(false);
            } else {
                setShowFilters(true);
            }
        });

        setInteractionDetail({
            openModal: true,
            id: id,
            employeeid: employeeid,
            employeeName: employeename,
            interactions: interactions,
            sourcefilter: sourcefilter,
            typefilter: typefilter,
            distinctSources: distinctSources,
            disctinctTypes: disctinctTypes,
            interactionGroups: interactionGroups,
        });

        setFilteredData(interactions);

        setOutlookMessagesCount(
            summaryCount('outlook-messages', interactionGroups)
        );
        setOutlookEventCount(summaryCount('outlook-events', interactionGroups));
        setSfdcEventCount(summaryCount('sfdc-events', interactionGroups));
        setSfdcTaskCount(summaryCount('sfdc-tasks', interactionGroups));
    };

    const summaryCount = (summary, interactionGroups) => {
        let count = 0;
        switch (summary) {
            case 'outlook-messages':
                let interationMessages = interactionGroups.find(
                    (x) =>
                        x.source === '#Microsoft.Outlook' &&
                        x.connectionType === 'Message'
                );
                if (interationMessages) count = interationMessages.count;
                break;
            case 'outlook-events':
                var interactionEvents = interactionGroups.find(
                    (x) =>
                        x.source === '#Microsoft.Outlook' &&
                        x.connectionType === 'EventMessage'
                );
                if (interactionEvents) count = interactionEvents.count;
                break;
            case 'sfdc-events':
                interactionGroups.forEach((x) => {
                    if (x.source === 'Event') count += x.count;
                });
                break;
            case 'sfdc-tasks':
                interactionGroups.forEach((x) => {
                    if (x.source === 'Task') count += x.count;
                });
                break;
            default:
                count = 0;
        }
        return count;
    };

    return (
        <Modal
            className="internal-connection-modal"
            show={interactionDetail.openModal}
            onHide={() => {
                setInteractionDetail({
                    openmodal: false,
                    id: '',
                    employeeid: '',
                    interactions: [],
                    sourcefilter: '',
                    typefilter: '',
                    distinctSources: [],
                    distinctTypes: [],
                    interactionGroups: [],
                });
            }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header
                closeButton
                className="card-style"
                style={{ borderTopColor: '#5D7B9A' }}
            >
                <Modal.Title id="example-custom-modal-styling-title">
                    <h4 className="card-title-modal">
                        <ForumOutlinedIcon fontSize="large" />{' '}
                        {type === 'prospect'
                            ? 'Interactions'
                            : 'Connected Colleagues'}{' '}
                    </h4>
                    {type === 'account' ? (
                        <div
                            className="solution-subtitle"
                            style={{ paddingBottom: '0px' }}
                        >
                            {' '}
                            Based on Salesforce events from the previous week{' '}
                        </div>
                    ) : null}
                </Modal.Title>
                {interactionDetail.interactions.length > 1 ? (
                    <div className="trigger-filter-modal">
                        <div className="filter-button">
                            <div>
                                {triggerFilter(showFilters, setShowFilters)}
                            </div>
                            <div className="clear-filter">
                                <div
                                    className="text-primary"
                                    onClick={clearAllFilters}
                                    style={{
                                        visibility: showFilters
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                >
                                    Clear Filters
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </Modal.Header>
            <Modal.Body
                style={{
                    overflowY: 'hidden',
                    overflowX:
                        interactionDetail.interactionGroups.length > 0
                            ? 'auto'
                            : 'none',
                }}
            >
                <div className="fields-style" style={{ fontWeight: 'bold' }}>
                    Summary
                </div>
                {interactionDetail.interactionGroups.length > 0 ? (
                    <>
                        <ul
                            className="list-group row"
                            style={{
                                marginBottom: '10px',
                                marginTop: '5px',
                            }}
                        >
                            <li
                                className="list-group-item"
                                style={{ fontSize: '1rem' }}
                            >
                                <span className="fields-style">
                                    Employee Name:
                                </span>{' '}
                                {interactionDetail.employeeName}
                            </li>
                            {outlookMessagesCount > 0 ? (
                                <li
                                    className="list-group-item"
                                    style={{
                                        fontSize: '1rem',
                                    }}
                                >
                                    <span className="fields-style">
                                        Outlook Messages:
                                    </span>{' '}
                                    {outlookMessagesCount}{' '}
                                </li>
                            ) : null}
                            {outlookEventCount > 0 ? (
                                <li
                                    className="list-group-item"
                                    style={{
                                        fontSize: '1rem',
                                    }}
                                >
                                    <span className="fields-style">
                                        Outlook Events:
                                    </span>{' '}
                                    {outlookEventCount}
                                </li>
                            ) : null}
                            {sfdcEventCount > 0 ? (
                                <li
                                    className="list-group-item"
                                    style={{
                                        fontSize: '1rem',
                                    }}
                                >
                                    <span className="fields-style">
                                        Salesforce Events:
                                    </span>{' '}
                                    {sfdcEventCount}
                                </li>
                            ) : null}
                            {sfdcTaskCount > 0 ? (
                                <li
                                    className="list-group-item"
                                    style={{
                                        fontSize: '1rem',
                                    }}
                                >
                                    <span className="fields-style">
                                        Salesforce Tasks:
                                    </span>{' '}
                                    {sfdcTaskCount}
                                </li>
                            ) : null}
                        </ul>
                    </>
                ) : null}
                {
                    <BootstrapTable
                        keyField="id"
                        data={interactionDetail.interactions}
                        columns={InteractionTableColumns}
                        striped={true}
                        bordered={false}
                        pagination={
                            interactionDetail.interactions.length > 10
                                ? paginationFactory()
                                : null
                        }
                        hover={true}
                        filter={filterFactory({ afterFilter })}
                    />
                }
            </Modal.Body>
        </Modal>
    );
});
