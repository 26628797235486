import React from 'react';
import '../Prospect/Overview.css';
import Card from 'react-bootstrap/Card';
import { OverlayTooltip } from '../HoverOver/OverlayTooltip';

export const PowerBI = (props) => {
    return (
        <div style={{ display: 'inline' }}>
            <Card.Link
                className="text-primary"
                style={{ paddingLeft: '5px' }}
                href={props.url}
                target="_blank"
                rel="noopener noreferrer"
            >
                <OverlayTooltip
                    tooltipText={props.toolTipText}
                    imageType="none"
                    hoverElement={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            width={props.smaller ? '30' : '50'}
                            height={props.smaller ? '30' : '50'}
                        >
                            <path
                                fill="#ffc107"
                                d="M40 41H8c-2.206 0-4-1.794-4-4V11c0-2.206 1.794-4 4-4h32c2.206 0 4 1.794 4 4v26c0 2.206-1.794 4-4 4z"
                            />
                            <path
                                fill="#212121"
                                d="M34 12.98H14.02c-2.2 0-4 1.79-4 4V30c0 1.86 1.27 3.42 2.98 3.86v-2.14c-.59-.35-.98-.99-.98-1.72V16.98c0-1.1.9-2 2-2H34c1.1 0 2 .9 2 2V30c0 .74-.4 1.38-1 1.73v2.14c1.73-.44 3-2.01 3-3.87V16.98c0-2.21-1.79-4-4-4z"
                            />
                            <path
                                fill="#212121"
                                d="M16.5 28c.828 0 1.5.672 1.5 1.5v5c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5v-5c0-.828.672-1.5 1.5-1.5zM21.5 22c.828 0 1.5.672 1.5 1.5v11c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5v-11c0-.828.672-1.5 1.5-1.5zM26.5 25c.828 0 1.5.672 1.5 1.5v8c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5v-8c0-.828.672-1.5 1.5-1.5zM31.5 18c.828 0 1.5.672 1.5 1.5v15c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5v-15c0-.828.672-1.5 1.5-1.5z"
                            />
                        </svg>
                    }
                ></OverlayTooltip>
            </Card.Link>
        </div>
    );
};
