import React, { useContext, useEffect, useState } from 'react';
import './Home.css';
import '../SearchResult.css';
import { Link, useParams } from 'react-router-dom';
import {
    Container,
    CardGroup,
    Button,
    Form,
    FormControl,
    ToggleButtonGroup,
    ToggleButton,
} from 'react-bootstrap/';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Search } from '@mui/icons-material';
import { GlobalContext } from '../../context/GlobalState';
import { ProspectCards } from './ProspectCards';
import { AccountCards } from './AccountCards';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UpdateIcon from '@mui/icons-material/Update';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import {
    showModifiedDate,
    filterModifiedDate,
    getRelationshipStyle,
} from '../Shared/Util';
import './ViewMore.css';

export const ViewMore = () => {

    const { field } = useParams();

    const {
        following,
        followingAccounts,
        relationshipLead,
        updatedFollowing,
        updatedFollowingAccounts,
        setUpdatedFollowing,
        setUpdatedFollowingAccounts,
        getRelationshipLead,
        taggedRelationships,
        getTaggedRelationships,
    } = useContext(GlobalContext);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filterParam, setFilterParam] = useState('');
    const [results, setResults] = useState([]); // cards shown on current page
    const [allResults, setAllResults] = useState([]); // all cards returned within all pages, changes based on what is being searched
    const [data, setData] = useState([]); // all cards, everything, initial data
    const [type, setType] = useState(null);
    const [title, setTitle] = useState('');
    const [iconElement, setIconElement] = useState(null);
    const [helpText, setHelpText] = useState('');

    useEffect(() => {
        setFilterParam('All');
        if (field && field !== undefined) {
            switch (field) {
                case 'contacts':
                    setType('contact');
                    setTitle('Contacts You Follow');
                    setIconElement(<PersonAddIcon fontSize="large" />);
                    break;
                case 'contacts-updated':
                    setType('contact');
                    setTitle('Recently Updated Contacts You Follow');
                    setHelpText(
                        'This is the most recent date that a field within this page has been updated with new information from our systems.'
                    );
                    setIconElement(<UpdateIcon fontSize="large" />);
                    break;
                case 'accounts':
                    setType('account');
                    setTitle('Accounts You Follow');
                    setIconElement(<BusinessIcon fontSize="large" />);
                    break;
                case 'accounts-updated':
                    setType('account');
                    setTitle('Recently Updated Accounts You Follow');
                    setHelpText(
                        'This is the most recent date that a field within this page has been updated with new information from our systems.'
                    );
                    setIconElement(<UpdateIcon fontSize="large" />);
                    break;
                case 'relationshipLead':
                    setType('contact');
                    setTitle('Contacts You Lead In Salesforce');
                    setHelpText(
                        'These are contacts that you are tagged as the relationship lead for in Salesforce'
                    );
                    setIconElement(<PersonAddIcon fontSize="large" />);
                    break;
                case 'taggedRelationships':
                    setType('contact');
                    setTitle('My Relationships');
                    setHelpText(
                        'These are Salesforce contacts that you have a tagged relationship with.'
                    );
                    setIconElement(<PeopleIcon fontSize="large" />);
                    break;
            }
        }
    }, []);

    useEffect(() => {
        if (field && field !== undefined) {
            if (field === 'contacts' && following.length !== 0) {
                setData(following);
            }
            if (
                field === 'contacts-updated' &&
                following.length !== 0 &&
                updatedFollowing.length === 0
            ) {
                let filtered = filterModifiedDate(following);
                setUpdatedFollowing(filtered);
            }
        }
    }, [following]);

    useEffect(() => {
        if (field && field !== undefined) {
            if (
                field === 'contacts-updated' &&
                updatedFollowing.length !== 0
            ) {
                let updatedFollowingDate = showModifiedDate(updatedFollowing);
                setData(updatedFollowingDate);
            }
        }
    }, [updatedFollowing]);

    useEffect(() => {
        if (field && field !== undefined) {
            if (field === 'accounts' && followingAccounts.length !== 0) {
                setData(followingAccounts);
            }
            if (
                field === 'accounts-updated' &&
                followingAccounts.length !== 0 &&
                updatedFollowingAccounts.length === 0
            ) {
                let filtered = filterModifiedDate(followingAccounts);
                setUpdatedFollowingAccounts(filtered);
            }
        }
    }, [followingAccounts]);

    useEffect(() => {
        if (field && field !== undefined) {
            if (
                field === 'accounts-updated' &&
                updatedFollowingAccounts.length !== 0
            ) {
                let updatedFollowingAccountDate = showModifiedDate(
                    updatedFollowingAccounts
                );
                setData(updatedFollowingAccountDate);
            }
        }
    }, [updatedFollowingAccounts]);

    useEffect(() => {
        if (field && field !== undefined) {
            if (
                field === 'relationshipLead' &&
                relationshipLead.length !== 0
            ) {
                setData(relationshipLead);
            }
            if (
                field === 'relationshipLead' &&
                relationshipLead.length === 0
            ) {
                getRelationshipLead();
            }
        }
    }, [relationshipLead]);

    useEffect(() => {
        if (field && field !== undefined) {
            if (
                field === 'taggedRelationships' &&
                taggedRelationships.length !== 0
            ) {
                setData(taggedRelationships);
            }
            if (
                field === 'taggedRelationships' &&
                taggedRelationships.length === 0
            ) {
                getTaggedRelationships();
            }
        }
    }, [taggedRelationships]);

    useEffect(() => {
        if (data.length !== 0) {
            setAllResults(data);
            let cards = data.slice(0, 18);
            setResults(cards);
            setPage(0);
            setSearch('');
            setSearchTerm('');
        }
    }, [data]);

    const changePage = (action) => {
        let newPage = 0;
        if (action === 'previous') {
            newPage = page - 1;
        } else {
            newPage = page + 1;
        }
        let cards = allResults.slice(newPage * 18, newPage * 18 + 18);
        setResults(cards);
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (search) {
            searchResults(search);
        }
    };

    const clearSearch = () => {
        if (search) {
            setSearch('');
            searchResults('');
        }
    };

    const searchResults = (term, filter) => {
        setSearchTerm(term);
        const filterParameter = filter ? filter : filterParam;
        let lowerSearchTerm = term.toLowerCase();
        let searchResult = [];
        if (type) {
            if (type === 'contact') {
                // search contacts
                searchResult = data.filter((result) => {
                    if (result.relationShipStrength === filterParameter) {
                        return (
                            (result.companyName &&
                                result.companyName
                                    .toLowerCase()
                                    .includes(lowerSearchTerm)) ||
                            (result.name &&
                                result.name
                                    .toLowerCase()
                                    .includes(lowerSearchTerm)) ||
                            (result.title &&
                                result.title
                                    .toLowerCase()
                                    .includes(lowerSearchTerm))
                        );
                    } else if (filterParameter === 'All') {
                        return (
                            (result.companyName &&
                                result.companyName
                                    .toLowerCase()
                                    .includes(lowerSearchTerm)) ||
                            (result.name &&
                                result.name
                                    .toLowerCase()
                                    .includes(lowerSearchTerm)) ||
                            (result.title &&
                                result.title
                                    .toLowerCase()
                                    .includes(lowerSearchTerm))
                        );
                    }
                });
            } else {
                // search accounts
                searchResult = data.filter((result) => {
                    return (
                        result.name &&
                        result.name.toLowerCase().includes(lowerSearchTerm)
                    );
                });
            }
        }
        setAllResults(searchResult);
        let cards = searchResult.slice(0, 18);
        setResults(cards);
        setPage(0);
        window.scrollTo(0, 0);
    };

    const filterResults = (term, e) => {
        e.preventDefault();
        if(term){
            setFilterParam(term);
            searchResults(search, term);           
        }
    };

    if (!data || data.length === 0) {
        return null;
    }

    return (
        <Container fluid className="search-result" data-testid="view-more-home">
            <Link className="back-button" to="/">
                <DoubleArrowIcon className="back-arrow" />
                <span className="back-text">Return to Homepage</span>
            </Link>
            <div
                className="row-results"
                style={{ padding: '30px 10px 30px 10px' }}
            >
                <div style={{ marginLeft: '30px' }}>
                    <h4
                        className="prospect-name"
                        style={{ marginBottom: '5px' }}
                    >
                        {iconElement} {title}
                    </h4>
                    <div
                        className="solution-subtitle"
                        style={{ paddingLeft: '0px' }}
                    >
                        {' '}
                        {helpText}{' '}
                    </div>
                    <div style={{ color: 'gray', fontSize: '14px' }}>
                        Search {title}
                    </div>
                    <div
                        className="searchbar-input-button"
                        style={{ marginTop: '0px' }}
                    >
                        <Form onSubmit={handleSubmit}>
                            <div
                                className="searchbar-section"
                                style={{ minWidth: '50%' }}
                            >
                                <FormControl
                                    id="header-searchbar-id"
                                    className="mr-sm-2 header-searchbar"
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                    data-testid="view-more-search-bar"
                                />
                            </div>
                            <Button
                                className="header-searchbar-button"
                                type="submit"
                                data-testid="view-more-search-button"
                            >
                                <Search
                                    style={{
                                        fontSize: '32px',
                                    }}
                                />
                            </Button>
                            <span
                                className="clear-search"
                                onClick={clearSearch}
                                style={{
                                    display: search ? null : 'none',
                                }}
                            >
                                X Clear Search
                            </span>
                        </Form>
                        {title === 'My Relationships' ? (                            
                            <ToggleButtonGroup
                                type="radio"
                                name="options"
                                style={{ marginTop: 10 }}
                            >
                                <ToggleButton
                                    value={'All'}
                                    style={{
                                        backgroundColor:
                                            filterParam === 'All'
                                                ? getRelationshipStyle('All')
                                                : '#FFFFFF',
                                        borderColor:
                                            getRelationshipStyle('All'),
                                        color:
                                            filterParam === 'All'
                                                ? '#FFFFFF'
                                                : getRelationshipStyle('All'),
                                    }}
                                    onClick={(e) => filterResults('All', e)}
                                >
                                    All
                                </ToggleButton>
                                <ToggleButton
                                    value={'A-List'}
                                    style={{
                                        backgroundColor:
                                            filterParam === 'A-List'
                                                ? getRelationshipStyle('A-List')
                                                : '#FFFFFF',
                                        borderColor:
                                            getRelationshipStyle('A-List'),
                                        color:
                                            filterParam === 'A-List'
                                                ? '#FFFFFF'
                                                : getRelationshipStyle(
                                                    'A-List'
                                                ),
                                    }}
                                    onClick={(e) => filterResults('A-List', e)}
                                >
                                    A-List
                                </ToggleButton>
                                <ToggleButton
                                    value={'QBR'}
                                    style={{
                                        backgroundColor:
                                            filterParam === 'QBR'
                                                ? getRelationshipStyle('QBR')
                                                : '#FFFFFF',
                                        borderColor:
                                            getRelationshipStyle('QBR'),
                                        color:
                                            filterParam === 'QBR'
                                                ? '#FFFFFF'
                                                : getRelationshipStyle('QBR'),
                                    }}
                                    onClick={(e) => filterResults('QBR', e)}
                                >
                                    QBR
                                </ToggleButton>
                                <ToggleButton
                                    value={'RBC'}
                                    style={{
                                        backgroundColor:
                                            filterParam === 'RBC'
                                                ? getRelationshipStyle('RBC')
                                                : '#FFFFFF',
                                        borderColor:
                                            getRelationshipStyle('RBC'),
                                        color:
                                            filterParam === 'RBC'
                                                ? '#FFFFFF'
                                                : getRelationshipStyle('RBC'),
                                    }}
                                    onClick={(e) => filterResults('RBC', e)}
                                >
                                    RBC
                                </ToggleButton>
                                <ToggleButton
                                    value={'TR'}
                                    style={{
                                        backgroundColor:
                                            filterParam === 'TR'
                                                ? getRelationshipStyle('TR')
                                                : '#FFFFFF',
                                        borderColor: getRelationshipStyle('TR'),
                                        color:
                                            filterParam === 'TR'
                                                ? '#FFFFFF'
                                                : getRelationshipStyle('TR'),
                                    }}
                                    onClick={(e) => filterResults('TR', e)}
                                >
                                    TR
                                </ToggleButton>
                                <ToggleButton
                                    value={'NR'}
                                    style={{
                                        backgroundColor:
                                            filterParam === 'NR'
                                                ? getRelationshipStyle('NR')
                                                : '#FFFFFF',
                                        borderColor: getRelationshipStyle('NR'),
                                        color:
                                            filterParam === 'NR'
                                                ? '#FFFFFF'
                                                : getRelationshipStyle('NR'),
                                    }}
                                    onClick={(e) => filterResults('NR', e)}
                                >
                                    NR
                                </ToggleButton>
                            </ToggleButtonGroup>
                        ) : null}
                    </div>
                </div>
                <hr style={{ margin: '30px 30px 10px 30px' }} />
                {results.length !== 0 ? (
                    <div>
                        <div
                            style={{
                                marginLeft: '30px',
                                marginBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            {search
                                ? `${results.length} records found.`
                                : `You have ${allResults.length} ${title}.`}
                        </div>
                        <CardGroup>
                            {type && type === 'account' ? (
                                <AccountCards cards={results} />
                            ) : (
                                <ProspectCards cards={results} />
                            )}
                        </CardGroup>
                        <div className="search-result-paging">
                            {allResults.length > 18 && page !== 0 ? (
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => changePage('previous')}
                                >
                                    Previous
                                </Button>
                            ) : (
                                <div></div>
                            )}
                            {allResults.length > 18 &&
                                Math.ceil(allResults.length / 18) !==
                                (page * 18 + 18) / 18 ? (
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => changePage('next')}
                                >
                                    Next
                                </Button>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="no-result">
                        {searchTerm !== '' ? (
                            <span> Search for "{searchTerm}" </span>
                        ) : null}
                        <br />
                        Sorry, there are no records found.
                    </div>
                )}
            </div>
        </Container>
    );
};
