import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import './Overview.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { InternalConnections } from './InternalConnections';
import { ProspectBackground } from './ProspectBackground';
import { CompanyDescription } from '../Shared/CompanyDescription';
import { ProtivitiWork } from '../Shared/ProtivitiWork';
import { ProtivitiWorkSolution } from '../Shared/ProtivitiWorkSolution';
import { Opportunity } from '../Shared/Opportunity';
import { CompanyNews } from '../Shared/CompanyNews';
import { KeyBuyerHistory } from './KeyBuyerHistory';
import { MarketingCampaigns } from './MarketingCampaigns';
import { MarketingSolution } from '../Shared/MarketingSolution';
import { ScoopsIntents } from '../Shared/ScoopsIntents';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { getUrlLink, checkAccountType } from '../Shared/Util';

export const Overview = () => {
    const { prospect, account, getProspect, getValidation } =
        useContext(GlobalContext);
    const [prevPathSearch, setPrevPathSearch] = useState(false);
    const [prevPathReturnHome, setPrevPathReturnHome] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [marketingSolutionData, setMarketingSolutionData] = useState(null);
    const [clientSideSettings] = useState(
        JSON.parse(localStorage.getItem('ClientSideSettings'))
    );
    const navigate = useNavigate();
    const location = useLocation();
    const { prospectId } = useParams();

    useEffect(() => {
        setLoaded(false);
        fetchData();

        if (location.state && location.state.prevPath.pathname === '/search') {
            setPrevPathSearch(true);
            setPrevPathReturnHome(false);
        } else if (
            location.state &&
            location.state.prevPath.pathname.includes('/connections')
        ) {
            setPrevPathReturnHome(true);
            setPrevPathSearch(false);
        } else {
            setPrevPathSearch(false);
            setPrevPathReturnHome(false);
        }
    }, [location]);

    useEffect(() => {
        if (prospect) {
            let marketingSolution = {
                id: prospect.id,
                marketingSolutionA: prospect.marketingSolutionA,
                marketingSolution1Year: prospect.marketingSolution1Year,
                campaignsActionsA: prospect.campaignsActionsA,
            };
            setMarketingSolutionData(marketingSolution);

            if (prospect.id && prospect.zoomInfoContactId) {
                getValidation(prospect.zoomInfoContactId, 0);
            }
        }
    }, [prospect, navigate]);

    const fetchData = async () => {
        if (prospectId) {
            await getProspect(prospectId);
            setLoaded(true);
        }
    };

    const goBackPrevSearch = () => {
        navigate({
            pathname: location.state.prevPath.pathname,
            search: location.state.prevPath.search,
            state: { returnbutton: true },
        });
    };

    function goBackHome() {
        navigate({
            pathname: '/',
        });
    }

    function goBack() {
        navigate(-1);
    }

    const returnPreviousPage = (e) => {
        e.preventDefault();
        return prevPathReturnHome ? goBackHome() : goBack();
    };

    if (!prospect && loaded) {
        return (
            <div className="container-fluid search-result">
                <Row className="row-results">
                    <div className="no-result">
                        This record does not exist or has since been removed
                        from the ProConnect system. Please visit
                        <a
                            href={getUrlLink('sfdcLightningUrl', null)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Salesforce
                        </a>
                        <span> to add this record back into the system.</span>
                    </div>
                </Row>
            </div>
        );
    }
    // renders nothing until data is loaded
    if (!loaded) {
        return null;
    }

    return (
        <div className="container-fluid overview-style">
            {clientSideSettings &&
            clientSideSettings.showContactGuidance &&
            account ? (
                <Alert variant="info">
                    <div className="contact-help-text">
                        <p>
                            Prior to any contact, ensure you comply with{' '}
                            <b>local security and privacy requirements</b>{' '}
                            (CCPA, GDPR, etc.), follow applicable protocols, and
                            coordinate with the{' '}
                            {checkAccountType(account.accountType)
                                ? 'account lead'
                                : 'account MD/D'}
                            .
                        </p>
                    </div>
                </Alert>
            ) : null}

            {window.history && window.history.length > 1 ? (
                prevPathSearch ? (
                    <div className="back-button" onClick={goBackPrevSearch}>
                        <DoubleArrowIcon className="back-arrow" />
                        <span className="back-text">Return to Search</span>
                    </div>
                ) : (
                    <div className="back-button" onClick={returnPreviousPage}>
                        <DoubleArrowIcon className="back-arrow" />
                        <span className="back-text">
                            Return to Previous Page <b>{prevPathReturnHome}</b>
                        </span>
                    </div>
                )
            ) : null}

            <Row>
                <Col lg={4}>
                    <ProspectBackground />
                </Col>
                <Col>
                    <CompanyDescription />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InternalConnections />
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <ProtivitiWork />
                </Col>
                <Col lg={4}>
                    <ProtivitiWorkSolution />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Opportunity />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <ScoopsIntents />
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <KeyBuyerHistory />
                </Col>
                <Col lg={4}>
                    <CompanyNews view="prospect" />
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <MarketingCampaigns />
                </Col>
                <Col lg={4}>
                    <MarketingSolution
                        marketingSolutionData={marketingSolutionData}
                    />
                </Col>
            </Row>
        </div>
    );
};
