import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import Card from 'react-bootstrap/Card';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import { NotAvailable } from '../HoverOver/NotAvailable';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { formatDate } from '../Shared/Util';

export const CompanyNews = (props) => {
    const { newsFeed } = useContext(GlobalContext);
    const [mostRecentFeed, setMostRecentFeed] = useState([]);
    //const [isActive, setActive] = useState(true);
    const [isActive] = useState(true);
    const [showMoreNews, setShowMoreNews] = useState(false);

    useEffect(() => {
        setMostRecentFeed(newsFeed.slice(0, 5));
    }, [newsFeed]);

    //const toggleNews = (e) => {
    //    var cardHeadings = document.getElementsByClassName('card-title');
    //    for (let ele of cardHeadings) {
    //        if (ele === e.currentTarget && !e.currentTarget.children[0].classList.contains('card-title-active'))
    //            setActive(!isActive);
    //    }
    //}

    const handleShowMoreNews = () => setShowMoreNews(!showMoreNews);
    const showMoreModal = () => {
        return (
            <Modal
                show={showMoreNews}
                onHide={handleShowMoreNews}
                dialogClassName="custom-dialog"
            >
                <Modal.Header
                    closeButton
                    className="card-style"
                    style={{ borderTopColor: '#5D7B9A' }}
                >
                    <Modal.Title>
                        <h4 className="card-title-modal">
                            <AnnouncementOutlinedIcon fontSize="large" />{' '}
                            Company News
                        </h4>
                    </Modal.Title>
                    <Modal.Body></Modal.Body>
                </Modal.Header>
                <Modal.Body>
                    <ul className="newsFeed">
                        {newsFeed.map((item, i) => (
                            <li
                                key={i}
                                style={{
                                    paddingLeft: '2%',
                                    paddingRight: '2%',
                                }}
                            >
                                <a
                                    className="text-primary card-link"
                                    target="_blank"
                                    href={item.link}
                                    rel="noopener noreferrer"
                                >
                                    {' '}
                                    <p>{item.title} </p>{' '}
                                </a>
                                <p>{formatDate(item.pubDate)}</p>
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <Card>
            <Card.Body
                className="card-style"
                style={{ borderTopColor: '#5D7B9A' }}
            >
                {props.view.toLowerCase() === 'account' ? (
                    <>
                        <div style={{ display: 'flex' }}>
                            <h6
                                style={{ fontSize: 'larger' }}
                                className="card-title"
                            >
                                <span
                                    className={
                                        isActive
                                            ? 'card-text card-title-active'
                                            : 'card-text'
                                    }
                                >
                                    {' '}
                                    Company News{' '}
                                </span>{' '}
                                {newsFeed.length > 0 ? null : (
                                    <NotAvailable
                                        section="companynews"
                                        showNotAvailable={false}
                                    />
                                )}
                            </h6>
                        </div>
                    </>
                ) : (
                    <>
                        <h4 className="card-title">
                            <AnnouncementOutlinedIcon fontSize="large" />{' '}
                            Company News{' '}
                            {newsFeed.length > 0 ? null : (
                                <NotAvailable
                                    section="companynews"
                                    showNotAvailable={false}
                                />
                            )}
                        </h4>
                    </>
                )}

                {newsFeed.length > 0 ? (
                    <>
                        <ul className="newsFeed">
                            {mostRecentFeed.map((item, i) => (
                                <li
                                    key={i}
                                    style={{
                                        paddingLeft: '2%',
                                        paddingRight: '2%',
                                    }}
                                >
                                    <a
                                        className="text-primary card-link"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={item.link}
                                    >
                                        {' '}
                                        <p>{item.title} </p>{' '}
                                    </a>
                                    <p>{formatDate(item.pubDate)}</p>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <>
                        <hr />
                        <div className="not-available">Not Available</div>
                    </>
                )}
                <Button
                    style={{
                        float: 'right',
                        margin: '2%',
                        display: newsFeed.length <= 5 ? 'none' : null,
                    }}
                    onClick={handleShowMoreNews}
                    variant="outline-secondary"
                >
                    {'View More'}
                </Button>
                {showMoreModal()}
            </Card.Body>
        </Card>
    );
};
