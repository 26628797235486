import React, { useEffect, useState } from 'react';
import '../Prospect/Overview.css';
import { OverlayTooltip } from './OverlayTooltip';
import { OverlayStickOnHover } from './OverlayStickOnHover';
import Card from 'react-bootstrap/Card';
import infoIcon from '../../images/i-icon.png';
import { getUrlLink } from '../Shared/Util';

export const NotAvailable = (props) => {
    // props.showNotAvailable -> displays "Not Available" text
    // props.section -> text within popup
    // props.linkUrl -> click icon to open link

    const [tooltipText, setTooltipText] = useState('');

    useEffect(() => {
        let text = '';

        switch (props.section) {
            case 'external':
                text =
                    'This information is not available in the vendor database.';
                break;
            case 'internal':
                text =
                    'This information is not available within our internal Protiviti data.';
                break;
            case 'internal-section':
                text =
                    'There is no data currently stored within our internal Protiviti data for this section. If you know this information, please update within the system (e.g., SalesForce, iManage, etc.)';
                break;
            case 'internal-field':
                text =
                    'This information is not available within our internal Protiviti data. If you know the information, please update in the internal system. Note: ProConnect follows the same security protocols as our other Protiviti internal applications. Therefore, your access to update may be restricted based on your role and region.';
                break;
            case 'deck':
                text =
                    'There is no account deck stored in our internal systems for this account.';
                break;
            case 'ownership':
                text =
                    'If this information is not accurate, please update in the internal system. Note: ProConnect follows the same security protocols as our other Protiviti internal applications. Therefore, your access to update may be restricted based on your role and region.';
                break;
            case 'companynews':
                text =
                    'No news articles were found for this company at this time.';
                break;
            case 'solution':
                text =
                    'There are no projects with incurred fees for this client in the given time period selected.';
                break;
            case 'external-only':
                text = (
                    <span>
                        This field is specific to Salesforce and this account is
                        not currently in Salesforce. If you would like to add
                        this account to our internal system, please visit{' '}
                        <a
                            href={getUrlLink('sfdcLightningUrl', null)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Salesforce
                        </a>
                        .
                    </span>
                );
                break;
        }

        setTooltipText(text);
    }, [props]);

    const notAvailableIcon = () => {
        return <img src={infoIcon} className="notavailable-icon" />;
    };

    if (props.section === 'external-only') {
        return (
            <>
                {props.showNotAvailable ? <span>Not Available </span> : null}
                <OverlayStickOnHover
                    placement="bottom"
                    onMouseEnter={() => {}}
                    delay={200}
                    component={<div>{tooltipText}</div>}
                >
                    {notAvailableIcon()}
                </OverlayStickOnHover>
            </>
        );
    }

    return (
        <span style={props.style} data-testid="not-available">
            {props.showNotAvailable ? <span>Not Available </span> : null}
            {props.linkUrl ? (
                <Card.Link
                    className="text-primary"
                    href={props.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <OverlayTooltip
                        tooltipText={tooltipText}
                        hoverElement={notAvailableIcon()}
                    />
                </Card.Link>
            ) : (
                <OverlayTooltip
                    tooltipText={tooltipText}
                    hoverElement={notAvailableIcon()}
                />
            )}
        </span>
    );
};
