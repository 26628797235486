import React, { useState, useEffect, useContext } from 'react';
import './Home.css';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap/';
//import defaultPicture from '../images/defaultprofilepicture.PNG';
import axios from 'axios';
import config from '../../config';
import { Link } from 'react-router-dom';
import { authHeaders } from '../../auth/authHeaders';
import { GlobalContext } from '../../context/GlobalState';
import { ProspectCards } from './ProspectCards';
import { AccountCards } from './AccountCards';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PageviewIcon from '@mui/icons-material/Pageview';
import UpdateIcon from '@mui/icons-material/Update';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import { Unauthorized } from '../Unauthorized';
import { showModifiedDate, filterModifiedDate } from '../Shared/Util';

export const Home = () => {
    const {
        following,
        followingAccounts,
        relationshipLead,
        getRelationshipLead,
        setUpdatedFollowing,
        setUpdatedFollowingAccounts,
        taggedRelationships,
        getTaggedRelationships,
    } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState(null);
    const [updatedFollowingState, setUpdatedFollowingState] = useState([]);
    const [showMoreFollowing, setShowMoreFollowing] = useState(false);
    const [showMoreUpdated, setShowMoreUpdated] = useState(false);
    const [updatedFollowingAccountsState, setUpdatedFollowingAccountsState] =
        useState([]);
    const [showMoreFollowingAccounts, setShowMoreFollowingAccounts] =
        useState(false);
    const [showMoreUpdatedAccounts, setShowMoreUpdatedAccounts] =
        useState(false);
    const [showMoreRelationshipLead, setShowMoreRelationshipLead] =
        useState(false);
    const [showMoreTaggedRelationships, setShowMoreTaggedRelationships] =
        useState(false);

    useEffect(() => {
        fetchData();
        getRelationshipLead();
        getTaggedRelationships();
    }, []);

    useEffect(() => {
        if (following.length !== 0) {
            let filtered = filterModifiedDate(following);
            setUpdatedFollowingState(filtered);
            setUpdatedFollowing(filtered);
        } else {
            setUpdatedFollowingState([]);
            setUpdatedFollowing([]);
        }
    }, [following]);

    useEffect(() => {
        if (followingAccounts.length !== 0) {
            let filtered = filterModifiedDate(followingAccounts);
            setUpdatedFollowingAccountsState(filtered);
            setUpdatedFollowingAccounts(filtered);
        } else {
            setUpdatedFollowingAccountsState([]);
            setUpdatedFollowingAccounts([]);
        }
    }, [followingAccounts]);

    const fetchData = async () => {
        let headers = await authHeaders();
        let url = config.azRedirectUri + '/api/user/userHistory';
        await axios
            .get(url, headers)
            .then(function (response) {
                setLoading(false);
                if (response.data.length === 0) {
                    setHistory(null);
                } else {
                    setHistory(response.data);
                }
            })
            .catch(function (error) {
                setLoading(false);
                setHistory(null);
            });
    };

    const handleShowMoreFollowing = () =>
        setShowMoreFollowing(!showMoreFollowing);
    const handleShowMoreUpdated = () => setShowMoreUpdated(!showMoreUpdated);
    const handleShowMoreFollowingAccounts = () =>
        setShowMoreFollowingAccounts(!showMoreFollowingAccounts);
    const handleShowMoreUpdatedAccounts = () =>
        setShowMoreUpdatedAccounts(!showMoreUpdatedAccounts);
    const handleShowMoreRelationshipLead = () =>
        setShowMoreRelationshipLead(!showMoreRelationshipLead);
    const handleShowMoreTaggedRelationships = () =>
        setShowMoreTaggedRelationships(!showMoreTaggedRelationships);

    const renderCards = (field, value) => {
        if (value && value.length !== 0) {
            let title = null;
            let icon = null;
            let showFlag = null;
            let handleShowMore = null;
            let cardSlice = null;
            let cardAll = null;
            let contactsUpdated = null;
            let accountsUpdated = null;
            let followHelpText = null;

            switch (field) {
                case 'history':
                    title = 'Profiles you have recently viewed';
                    icon = <PageviewIcon fontSize="large" />;
                    cardSlice = <ProspectCards cards={value} />; //show all history
                    break;
                case 'contacts':
                    title = 'Contacts You Follow';
                    icon = <PersonAddIcon fontSize="large" />;
                    cardSlice = <ProspectCards cards={value.slice(0, 4)} />;
                    cardAll = <ProspectCards cards={value} />;
                    showFlag = showMoreFollowing;
                    handleShowMore = handleShowMoreFollowing;
                    break;
                case 'contacts-updated':
                    title = 'Recently Updated Contacts You Follow';
                    followHelpText =
                        'This is the most recent date that a field within this page has been updated with new information from our systems.';
                    icon = <UpdateIcon fontSize="large" />;
                    contactsUpdated = showModifiedDate(value);
                    cardSlice = (
                        <ProspectCards cards={contactsUpdated.slice(0, 6)} />
                    );
                    cardAll = <ProspectCards cards={contactsUpdated} />;
                    showFlag = showMoreUpdated;
                    handleShowMore = handleShowMoreUpdated;
                    break;
                case 'accounts':
                    title = 'Accounts You Follow';
                    icon = <BusinessIcon fontSize="large" />;
                    cardSlice = <AccountCards cards={value.slice(0, 4)} />;
                    cardAll = <AccountCards cards={value} />;
                    showFlag = showMoreFollowingAccounts;
                    handleShowMore = handleShowMoreFollowingAccounts;
                    break;
                case 'accounts-updated':
                    title = 'Recently Updated Accounts You Follow';
                    followHelpText =
                        'This is the most recent date that a field within this page has been updated with new information from our systems.';
                    icon = <UpdateIcon fontSize="large" />;
                    accountsUpdated = showModifiedDate(value);
                    cardSlice = (
                        <AccountCards cards={accountsUpdated.slice(0, 6)} />
                    );
                    cardAll = <AccountCards cards={accountsUpdated} />;
                    showFlag = showMoreUpdatedAccounts;
                    handleShowMore = handleShowMoreUpdatedAccounts;
                    break;
                case 'relationshipLead':
                    title = 'Contacts You Lead In Salesforce';
                    followHelpText =
                        'These are contacts that you are tagged as the relationship lead for in Salesforce';
                    icon = <PersonAddIcon fontSize="large" />;
                    cardSlice = <ProspectCards cards={value.slice(0, 4)} />;
                    cardAll = <ProspectCards cards={value} />;
                    showFlag = showMoreRelationshipLead;
                    handleShowMore = handleShowMoreRelationshipLead;
                    break;
                case 'taggedRelationships':
                    title = 'My Relationships';
                    followHelpText =
                        'These are Salesforce contacts that you have a tagged relationship with.';
                    icon = <PeopleIcon fontSize="large" />;
                    cardSlice = <ProspectCards cards={value.slice(0, 6)} />;
                    cardAll = <ProspectCards cards={value} />;
                    showFlag = showMoreTaggedRelationships;
                    handleShowMore = handleShowMoreTaggedRelationships;
                    break;
                default:
                    title = null;
                    icon = null;
                    cardSlice = null;
                    cardAll = null;
                    showFlag = null;
                    handleShowMore = null;
                    followHelpText = null;
            }

            if (title !== null) {
                return (
                    <div style={{ marginBottom: '15px' }}>
                        <h5
                            style={{
                                color:
                                    field === 'contacts' ||
                                    field === 'accounts' ||
                                    field == 'relationshipLead'
                                        ? '#ffffff'
                                        : '#0f3e63',
                                marginBottom: '0px',
                                marginLeft: '5%',
                            }}
                        >
                            {icon} {title}
                        </h5>
                        {field === 'relationshipLead' ? (
                            <div
                                className="solution-subtitle"
                                style={{ color: 'white' }}
                            >
                                {' '}
                                {followHelpText}{' '}
                            </div>
                        ) : (
                            <div className="help-subtitle">
                                {' '}
                                {followHelpText}{' '}
                            </div>
                        )}
                        {cardSlice}
                        {field === 'history' ? null : (
                            <div
                                style={{
                                    textAlignLast: 'right',
                                }}
                            >
                                <Link to={`/view/${field}`}>
                                    <Button
                                        className="show-more-following-button"
                                        style={{
                                            marginRight:
                                                field === 'contacts' ||
                                                field === 'accounts' ||
                                                field === 'relationshipLead'
                                                    ? '7%'
                                                    : '6%',
                                            display:
                                                (value.length <= 6 &&
                                                    (field ===
                                                        'accounts-updated' ||
                                                        field ===
                                                            'contacts-updated')) ||
                                                value.length <= 4
                                                    ? 'none'
                                                    : null,
                                        }}
                                        variant="outline-secondary"
                                    >
                                        {'View More'}
                                    </Button>
                                </Link>

                                <Modal
                                    show={showFlag}
                                    onHide={handleShowMore}
                                    dialogClassName="custom-dialog"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            <h4 className="card-title-modal">
                                                {icon} {title}
                                            </h4>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                        style={{
                                            overflowX: 'none',
                                            paddingLeft: '0px',
                                            paddingRight: '0px',
                                            paddingTop: '40px',
                                            paddingBottom: '50px',
                                        }}
                                    >
                                        {cardAll}
                                    </Modal.Body>
                                </Modal>
                            </div>
                        )}
                    </div>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    if (loading) {
        return null;
    }

    return (
        <div data-testid="home-page">
            {history ||
            following.length !== 0 ||
            followingAccounts.length !== 0 ||
            relationshipLead.length !== 0 ? (
                <Container fluid className="search-result">
                    <Row className="row-results" style={{ minHeight: '800px' }}>
                        {following.length !== 0 ||
                        followingAccounts.length !== 0 ||
                        relationshipLead.length !== 0 ? (
                            <Col
                                lg={4}
                                className="filter-section"
                                style={{
                                    paddingTop: '30px',
                                    paddingBottom: '30px',
                                }}
                            >
                                {renderCards('contacts', following)}
                                {renderCards('accounts', followingAccounts)}
                                {renderCards(
                                    'relationshipLead',
                                    relationshipLead
                                )}
                            </Col>
                        ) : null}
                        {history ||
                        updatedFollowingState.length !== 0 ||
                        updatedFollowingAccountsState.length !== 0 ||
                        taggedRelationships.length !== 0 ? (
                            <Col className="search-result-section">
                                {renderCards('history', history)}
                                {renderCards(
                                    'contacts-updated',
                                    updatedFollowingState
                                )}
                                {renderCards(
                                    'accounts-updated',
                                    updatedFollowingAccountsState
                                )}
                                {renderCards(
                                    'taggedRelationships',
                                    taggedRelationships
                                )}
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            ) : (
                <Unauthorized welcome={true} />
            )}
        </div>
    );
};
