import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import './CompanyOverview.css';
import Card from 'react-bootstrap/Card';
import { NotAvailable } from '../HoverOver/NotAvailable';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
    getUrlLink,
    formatWebsite,
    provideLinkToCompanyPage,
} from '../Shared/Util';
import BusinessIcon from '@mui/icons-material/Business';
import WebIcon from '@mui/icons-material/Web';
import ListGroup from 'react-bootstrap/ListGroup';
import { ExternalDetail } from '../HoverOver/ExternalDetail';

export const CompanyTechnologies = () => {
    const { account } = useContext(GlobalContext);
    const [companyTechnologies, setCompanyTechnologies] = useState([]);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        if (
            account &&
            account.externalAccountView &&
            account.externalAccountView.protivitiPartners &&
            account.externalAccountView.protivitiPartners.length !== 0
        ) {
            setCompanyTechnologies(
                account.externalAccountView.protivitiPartners
            );
        }
    }, [account]);

    const showcompanyTechnologies = (protivitiPartners, model) => {
        return (
            <ListGroup>
                {protivitiPartners.map((protivitiPartner, i) => (
                    <ListGroup.Item
                        key={i}
                        style={
                            i % 2 === 0
                                ? {
                                      backgroundColor: 'rgba(0,0,0,.05)',
                                  }
                                : null
                        }
                    >
                        <div
                            style={{
                                display: 'flex',
                                marginTop: '10px',
                                marginBottom: '10px',
                            }}
                        >
                            {/* Removed Company Logo Here */}

                            <div
                                style={{
                                    flexGrow: '1',
                                    paddingLeft: '5px',
                                }}
                            >
                                {protivitiPartner.sfdcAccountId &&
                                provideLinkToCompanyPage(
                                    protivitiPartner.sfdcAccountId
                                ) ? (
                                    <Card.Link
                                        href={encodeURI(
                                            `${/company/}${
                                                protivitiPartner.sfdcAccountId
                                            }`
                                        )}
                                    >
                                        <h4
                                            className="card-title-company-name text-primary"
                                            style={{
                                                fontSize: 'large',
                                                marginBottom: '5px',
                                            }}
                                        >
                                            {protivitiPartner.name}
                                        </h4>
                                    </Card.Link>
                                ) : (
                                    <h4
                                        className="card-title-company-name"
                                        style={{
                                            fontSize: 'large',
                                            color: 'grey',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        {protivitiPartner.name}
                                    </h4>
                                )}
                                <div
                                    className="prospect-fields"
                                    style={{
                                        marginBottom: '0px',
                                    }}
                                >
                                    <span className="header-fields">
                                        Website:{' '}
                                    </span>
                                    {protivitiPartner.website ? (
                                        <Card.Link
                                            className="text-primary"
                                            href={formatWebsite(
                                                protivitiPartner.website
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {protivitiPartner.website}
                                        </Card.Link>
                                    ) : (
                                        <NotAvailable
                                            section="internal-field"
                                            linkUrl={getUrlLink(
                                                'sfdclightningAcc',
                                                protivitiPartner.sfdcAccountId
                                            )}
                                            showNotAvailable={true}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    };

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const showMoreModal = () => {
        return (
            <Modal
                show={showMore}
                onHide={handleShowMore}
                dialogClassName="custom-dialog"
            >
                <Modal.Header
                    closeButton
                    className="card-style"
                    style={{ borderTopColor: '#5D7B9A' }}
                >
                    <Modal.Title>
                        <div>
                            <h4 className="card-title-modal">
                                <WebIcon fontSize="large" /> Technologies{' '}
                                <span style={{ lineHeight: 2 }}>
                                    {' '}
                                    <ExternalDetail />{' '}
                                </span>
                            </h4>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        overflow: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingTop: '0px',
                    }}
                >
                    <div className="internal-connections-list">
                        {showcompanyTechnologies(companyTechnologies, true)}
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <Card>
            <Card.Body
                className="card-style"
                style={{
                    borderTopColor: '#5D7B9A',
                    overflowY: 'hidden',
                    overflowX: companyTechnologies.length > 0 ? 'auto' : 'none',
                }}
            >
                <div className="space-between">
                    <div>
                        <h4
                            className="card-title"
                            style={{
                                paddingBottom: '0px',
                                marginBottom: '5px',
                                alignSelf: 'center',
                            }}
                        >
                            <WebIcon
                                style={{
                                    fontSize: '38px',
                                    marginRight: '12px',
                                }}
                            />{' '}
                            Technologies{' '}
                            <span style={{ lineHeight: 2 }}>
                                {' '}
                                <ExternalDetail />{' '}
                            </span>{' '}
                            {companyTechnologies.length > 0 ? null : (
                                <NotAvailable
                                    section="external"
                                    showNotAvailable={false}
                                />
                            )}
                        </h4>
                    </div>
                </div>
                {companyTechnologies.length > 0 ? (
                    <div className="internal-connections-list">
                        {showcompanyTechnologies(
                            companyTechnologies.slice(0, 5),
                            false
                        )}
                        <Button
                            style={{
                                float: 'right',
                                margin: '2%',
                                display:
                                    companyTechnologies.length <= 5
                                        ? 'none'
                                        : null,
                            }}
                            onClick={handleShowMore}
                            variant="outline-secondary"
                        >
                            {'View More'}
                        </Button>
                        {showMoreModal()}
                    </div>
                ) : (
                    <>
                        <hr />
                        <div className="not-available">Not Available</div>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};
