import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import '../Prospect/Overview.css';
import './CompanyOverview.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { CompanyDescription } from '../Shared/CompanyDescription';
import { ProtivitiWork } from '../Shared/ProtivitiWork';
import { Opportunity } from '../Shared/Opportunity';
import { CompanyNews } from '../Shared/CompanyNews';
import { ProtivitiWorkSolution } from '../Shared/ProtivitiWorkSolution';
import { MarketingSolution } from '../Shared/MarketingSolution';
import { KeyBuyer } from './KeyBuyer';
import { ScoopsIntents } from '../Shared/ScoopsIntents';
import { Competitors } from './Competitors';
import { Alumni } from './Alumni';
import { Funding } from './Funding';
import { CompanyTechnologies } from './CompanyTechnologies';
import { ConnectedColleagues } from './ConnectedColleagues';
import { getUrlLink } from '../Shared/Util';

export const CompanyOverview = () => {
    const { account, getAccount, getValidation } = useContext(GlobalContext);
    const [prevPathSearch, setPrevPathSearch] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [marketingSolutionData, setMarketingSolutionData] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { accountId } = useParams();

    useEffect(() => {
        setLoaded(false);
        fetchData();

        if (
            location &&
            location.state &&
            location.state.prevPath.pathname === '/search'
        ) {
            setPrevPathSearch(true);
        } else {
            setPrevPathSearch(false);
        }
    }, []);

    useEffect(() => {
        if (account) {
            let marketingSolution = {
                id: account.id,
                marketingSolutionA: account.marketingSolutionA,
                marketingSolution1Year: account.marketingSolution1Year,
                campaignsActionsA: account.campaignsActionsA,
            };
            setMarketingSolutionData(marketingSolution);

            //if (account.id && account.relProAccountId) {
            //    getValidation(account.relProAccountId, 1);
            //}
            if (account.id && account.zoomInfoAccountId) {
                getValidation(account.zoomInfoAccountId, 1);
            }
        }
    }, [account]);

    const fetchData = async () => {
        if (accountId) {
            await getAccount(accountId);
            setLoaded(true);
        }
    };

    const goBackPrev = () => {
        navigate({
            pathname: location.state.prevPath.pathname,
            search: location.state.prevPath.search,
            state: { returnbutton: true },
        });
    };

    if (!account && loaded) {
        return (
            <div className="container-fluid search-result">
                <Row className="row-results">
                    <div className="no-result">
                        This record does not exist or has since been removed
                        from the ProConnect system. Please visit
                        <a
                            href={getUrlLink('sfdcLightningUrl', null)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Salesforce
                        </a>
                        <span> to add this record back into the system.</span>
                    </div>
                </Row>
            </div>
        );
    }

    // renders nothing until data is loaded
    if (!loaded) {
        return null;
    }

    return (
        <div
            className="container-fluid overview-style"
            data-testid="company-overview"
        >
            {window.history && window.history.length > 1 ? (
                prevPathSearch ? (
                    <div className="back-button" onClick={goBackPrev}>
                        <DoubleArrowIcon className="back-arrow" />
                        <span className="back-text">Return to Search</span>
                    </div>
                ) : (
                    <div
                        className="back-button"
                        onClick={(e) => {
                            navigate(-1);
                        }}
                    >
                        <DoubleArrowIcon className="back-arrow" />
                        <span className="back-text">
                            Return to Previous Page
                        </span>
                    </div>
                )
            ) : null}

            <Row>
                <Col lg={8}>
                    <CompanyDescription />
                </Col>
                <Col lg={4}>
                    <CompanyNews view="account" />
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <ProtivitiWorkSolution />
                </Col>
                <Col lg={8}>
                    <KeyBuyer />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <ProtivitiWork />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Opportunity />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <ScoopsIntents />
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <Funding />
                </Col>
                <Col lg={4}>
                    <Competitors />
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <Alumni />
                </Col>
                <Col lg={4}>
                    <CompanyTechnologies />
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <ConnectedColleagues />
                </Col>
                <Col lg={4}>
                    <MarketingSolution
                        marketingSolutionData={marketingSolutionData}
                    />
                </Col>
            </Row>
        </div>
    );
};
