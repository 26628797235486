import React from 'react';
import './OverlayTooltip.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import infoIcon from '../../images/i-icon.png';
import questionMark from '../../images/questionmark-icon.png';

// Hover Over Tooltip - CANNOT hover over popup, if you need to hover over popup, use OverlayStickOnHover
export const OverlayTooltip = (props) => {
    const image = (imageType) => {
        if (imageType && imageType === 'question') {
            return <img src={questionMark} alt="Question" />;
        } else {
            return <img src={infoIcon} alt="Information" />;
        }
    };

    return (
        <OverlayTrigger
            key="tooltip-bottom"
            placement="bottom"
            overlay={
                <Tooltip id="tooltip-bottom">
                    <span className="tooltiptext">
                        {props.imageType &&
                        props.imageType === 'none' ? null : (
                            <span className="icon-div">
                                {image(props.imageType)}
                            </span>
                        )}
                        <span className="text-div">{props.tooltipText}</span>
                    </span>
                </Tooltip>
            }
        >
            {props.hoverElement}
        </OverlayTrigger>
    );
};
