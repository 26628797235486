import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Overlay, Tooltip } from 'react-bootstrap';
import './OverlayTooltip.css';

// Hover Over Tooltip - CAN hover over popup, if you do not need to hover over popup, use OverlayTooltip
export const OverlayStickOnHover = ({
    delay,
    onMouseEnter,
    children,
    component,
    placement,
}) => {
    const [showPopover, setShowPopover] = useState(false);
    const childNode = useRef(null);
    let setTimeoutConst = null;

    useEffect(() => {
        return () => {
            if (setTimeoutConst) {
                clearTimeout(setTimeoutConst);
            }
        };
    });

    const handleMouseEnter = () => {
        setTimeoutConst = setTimeout(() => {
            setShowPopover(true);
            onMouseEnter();
        }, delay);
    };

    const handleMouseLeave = () => {
        clearTimeout(setTimeoutConst);
        setShowPopover(false);
    };

    const displayChild = React.Children.map(children, (child) =>
        React.cloneElement(child, {
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
            ref: (node) => {
                childNode.current = node;
                const { ref } = child;
                if (typeof ref === 'function') {
                    ref(node);
                }
            },
            style: { cursor: 'pointer' },
        })
    )[0];

    return (
        <>
            {displayChild}
            <Overlay
                key="tooltip-bottom"
                show={showPopover}
                placement={placement}
                target={childNode}
                shouldUpdatePosition
            >
                <Tooltip
                    id="tooltip-bottom"
                    onMouseEnter={() => {
                        setShowPopover(true);
                    }}
                    onMouseLeave={handleMouseLeave}
                >
                    <span className="tooltiptext">{component}</span>
                </Tooltip>
            </Overlay>
        </>
    );
};

OverlayStickOnHover.propTypes = {
    children: PropTypes.element.isRequired,
    delay: PropTypes.number,
    onMouseEnter: PropTypes.func,
    component: PropTypes.node.isRequired,
    placement: PropTypes.string.isRequired,
};

OverlayStickOnHover.defaultProps = {
    delay: 0,
    onMouseEnter: () => {},
};
