import React, { useContext, useState, useEffect, useRef } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import './Overview.css';
import '../Home/Home.css';
import '../SearchResult.css';
import { Link } from 'react-router-dom';
import { Card, Button, Row, Col, Container } from 'react-bootstrap/';
import DefaultPhoto from '../Shared/DefaultPhoto';
import LineTo from 'react-lineto';
import {
    getRelationshipStyle,
    asteriskHoverover,
    formatLastContacted,
    renderTaggedRelationship,
} from '../Shared/Util';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { NumberOfInteractions } from './NumberOfInteractions';

export const InternalConnectionsGraph = (props) => {
    const childRef = useRef();

    let { prospect, getProspect } = useContext(GlobalContext);
    let [internalConnections, setInternalConnections] = useState([]);
    let [lineToKey, setLineToKey] = useState(null);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (!prospect) {
            const {
                match: { params },
            } = props; //get params
            getProspect(params.prospectId);
        }
        if (prospect && prospect.connections) {
            setInternalConnections(prospect.connections.slice(0, 10)); // get first 10
            setPage(0);
        }
    }, [prospect]);

    useEffect(() => {
        // rerenders LineTo
        setLineToKey(Math.random());
    }, [internalConnections]);

    const changePage = (action) => {
        let newPage = 0;
        if (action === 'previous') {
            newPage = page - 1;
        } else {
            newPage = page + 1;
        }
        let cards = prospect.connections.slice(newPage * 10, newPage * 10 + 10);
        setInternalConnections(cards);
        setPage(newPage);
    };

    let renderEmployeeCard = (employee, lineToClass) => {
        return (
            <div className={`search-result-link-graph ${lineToClass}`}>
                <Card className="search-result-card" style={{ zIndex: '2' }}>
                    <Card.Body
                        className="graph-card-border"
                        style={{
                            borderColor: getRelationshipStyle(
                                employee.relationShipStrength
                            ),
                        }}
                    >
                        <div className="graph-card-style">
                            <div className="graph-profile-pic-wrapper">
                                {employee.employee.photoUrl ? (
                                    <div
                                        className="profile-pic"
                                        style={{
                                            backgroundImage: `url(https://hubemployees.azureedge.net${employee.employee.photoUrl})`,
                                            borderColor: getRelationshipStyle(
                                                employee.relationShipStrength
                                            ),
                                        }}
                                    ></div>
                                ) : (
                                    <DefaultPhoto />
                                )}
                                {employee.relationShipStrength ? (
                                    <div className="graph-relationship">
                                        {renderTaggedRelationship(
                                            employee.relationShipStrength
                                        )}
                                    </div>
                                ) : null}
                            </div>
                            <h5
                                className="prospect-name"
                                style={{ marginTop: '10px' }}
                            >
                                {employee.employee.isTerminated
                                    ? asteriskHoverover(
                                          employee.employee.name,
                                          false
                                      )
                                    : employee.employee.name}
                            </h5>
                            <div className="current-title">
                                {(employee.employee.title &&
                                employee.employee.title.indexOf(' (') > 0
                                    ? employee.employee.title.substring(
                                          0,
                                          employee.employee.title.indexOf(' (')
                                      )
                                    : employee.employee.title) +
                                    (employee.employee.title &&
                                    employee.employee.city
                                        ? ', '
                                        : '') +
                                    (employee.employee.city
                                        ? employee.employee.city + ' Office'
                                        : '')}
                            </div>
                            {employee.lastContactTime ? (
                                <div className="prospect-fields">
                                    <span className="header-fields">
                                        Last Connected:{' '}
                                    </span>
                                    {formatLastContacted(
                                        employee.lastContactTime,
                                        employee.lastContactType
                                    )}
                                </div>
                            ) : null}
                            <div className="prospect-fields">
                                <span className="header-fields">
                                    Number of Interactions:{' '}
                                </span>
                                {employee.numberOfInteractions ? (
                                    <a
                                        className="text-primary"
                                        onClick={() =>
                                            childRef.current.openModal(
                                                prospect.id,
                                                'prospect',
                                                employee.employeeId,
                                                employee.employee.name,
                                                '',
                                                ''
                                            )
                                        }
                                    >
                                        {employee.numberOfInteractions}
                                    </a>
                                ) : (
                                    <span>0</span>
                                )}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    };

    let renderProspectCard = () => {
        return (
            <div className="search-result-link-graph prospect-lineTo">
                <div>
                    <Card
                        className="search-result-card"
                        style={{ zIndex: '2' }}
                    >
                        <Card.Body
                            className="card-style"
                            style={{ borderTopColor: '#F6871F' }}
                        >
                            <div className="graph-card-style">
                                {prospect.photoUrl ? (
                                    <img
                                        className="profile-picture"
                                        src={prospect.photoUrl}
                                        alt="Profile Picture"
                                    />
                                ) : (
                                    <DefaultPhoto />
                                )}
                                <h5
                                    className="prospect-name"
                                    style={{
                                        marginTop: '10px',
                                    }}
                                >
                                    {prospect.name}
                                </h5>
                                <div className="current-title">
                                    {prospect.title}
                                </div>
                                <div
                                    className="company-name"
                                    style={{
                                        marginBottom: '10px',
                                    }}
                                >
                                    {prospect.company.name}
                                </div>
                                {prospect.phone ? (
                                    <div className="current-title">
                                        Work - {prospect.phone}
                                    </div>
                                ) : null}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    };

    let renderCol = (order, toClass) => {
        if (internalConnections.length >= order + 1) {
            return (
                <Col className="graph-col-style">
                    {renderEmployeeCard(internalConnections[order], toClass)}
                </Col>
            );
        } else {
            if (order === 4 || order === 5) {
                return <Col></Col>;
            }
            return null;
        }
    };

    let getBorderWidth = (connection) => {
        let width = 1;
        if (!connection) return width;
        switch (true) {
            case connection.numberOfInteractions > 50:
                width = 10;
                break;
            case connection.numberOfInteractions >= 25 &&
                connection.numberOfInteractions <= 50:
                width = 5;
                break;
            default:
                width = 1;
        }
        return width;
    };

    let checkInternalConnections = (connection) => {
        if (!connection) return null;
        return connection.relationShipStrength;
    };

    let getPrevPage = () => {
        if (!prospect) return '/';
        return `/prospect/${prospect.id}`;
    };

    if (!internalConnections || internalConnections.length === 0) return null;

    return (
        <Container fluid className="search-result">
            <div style={{ marginBottom: '10px' }}>
                <Link
                    className="back-button"
                    to={{
                        pathname: getPrevPage(),
                        state: { prevPath: props.location },
                    }}
                >
                    <DoubleArrowIcon className="back-arrow" />
                    <span className="back-text">Return to Prospect Page</span>
                </Link>
            </div>

            <div
                className="row-results"
                style={{ padding: '30px 10px 30px 10px' }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: '30px',
                        marginRight: '30px',
                    }}
                >
                    <h4 className="card-title" style={{ padding: '0px' }}>
                        <div>
                            <PeopleOutlineIcon fontSize="large" /> Internal
                            Connections
                        </div>
                        <div
                            className="project-subtitle"
                            style={{ width: '100%' }}
                        >
                            Note: This connection map only shows internal
                            employees with a contact relationship strength
                            indicated in Salesforce
                        </div>
                    </h4>
                    <div>
                        <div className="prospect-fields header-fields">
                            Number of Interactions
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div className="connections-legend">
                                <div
                                    className="connections-legend-line"
                                    style={{ height: '1px' }}
                                ></div>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    0 - 24
                                </div>
                            </div>
                            <div className="connections-legend">
                                <div
                                    className="connections-legend-line"
                                    style={{ height: '5px' }}
                                ></div>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    25 - 50
                                </div>
                            </div>
                            <div
                                className="connections-legend"
                                style={{ paddingRight: '0px' }}
                            >
                                <div
                                    className="connections-legend-line"
                                    style={{ height: '10px' }}
                                ></div>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    51+
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Row className="graph-row-style">
                    {renderCol(0, 'A')}
                    {renderCol(1, 'B')}
                    {renderCol(6, 'C')}
                    {renderCol(8, 'D')}
                </Row>
                <Row className="graph-row-style">
                    {renderCol(4, 'E')}
                    <Col lg={6} className="graph-col-style">
                        {renderProspectCard()}
                    </Col>
                    {renderCol(5, 'F')}
                </Row>
                <Row className="graph-row-style">
                    {renderCol(2, 'G')}
                    {renderCol(3, 'H')}
                    {renderCol(7, 'I')}
                    {renderCol(9, 'J')}
                </Row>

                <div className="search-result-paging">
                    {prospect.connections.length > 10 && page !== 0 ? (
                        <Button
                            variant="outline-secondary"
                            onClick={() => changePage('previous')}
                        >
                            Previous
                        </Button>
                    ) : (
                        <div></div>
                    )}
                    {prospect.connections.length > 10 &&
                    Math.ceil(prospect.connections.length / 10) !==
                        (page * 10 + 10) / 10 ? (
                        <Button
                            variant="outline-secondary"
                            onClick={() => changePage('next')}
                        >
                            Next
                        </Button>
                    ) : (
                        <div></div>
                    )}
                </div>

                <div key={lineToKey}>
                    <LineTo
                        from="prospect-lineTo"
                        to="A"
                        toAnchor="center 80%"
                        borderColor={getRelationshipStyle(
                            checkInternalConnections(internalConnections[0])
                        )}
                        borderWidth={getBorderWidth(internalConnections[0])}
                    />
                    <LineTo
                        from="prospect-lineTo"
                        to="B"
                        toAnchor="center 80%"
                        borderColor={getRelationshipStyle(
                            checkInternalConnections(internalConnections[1])
                        )}
                        borderWidth={getBorderWidth(internalConnections[1])}
                    />
                    <LineTo
                        from="prospect-lineTo"
                        to="C"
                        toAnchor="center 80%"
                        borderColor={getRelationshipStyle(
                            checkInternalConnections(internalConnections[6])
                        )}
                        borderWidth={getBorderWidth(internalConnections[6])}
                    />
                    <LineTo
                        from="prospect-lineTo"
                        to="D"
                        toAnchor="center 80%"
                        borderColor={getRelationshipStyle(
                            checkInternalConnections(internalConnections[8])
                        )}
                        borderWidth={getBorderWidth(internalConnections[8])}
                    />
                    <LineTo
                        from="prospect-lineTo"
                        to="E"
                        borderColor={getRelationshipStyle(
                            checkInternalConnections(internalConnections[4])
                        )}
                        borderWidth={getBorderWidth(internalConnections[4])}
                    />
                    <LineTo
                        from="prospect-lineTo"
                        to="F"
                        borderColor={getRelationshipStyle(
                            checkInternalConnections(internalConnections[5])
                        )}
                        borderWidth={getBorderWidth(internalConnections[5])}
                    />
                    <LineTo
                        from="prospect-lineTo"
                        to="G"
                        toAnchor="center 20%"
                        borderColor={getRelationshipStyle(
                            checkInternalConnections(internalConnections[2])
                        )}
                        borderWidth={getBorderWidth(internalConnections[2])}
                    />
                    <LineTo
                        from="prospect-lineTo"
                        to="H"
                        toAnchor="center 20%"
                        borderColor={getRelationshipStyle(
                            checkInternalConnections(internalConnections[3])
                        )}
                        borderWidth={getBorderWidth(internalConnections[3])}
                    />
                    <LineTo
                        from="prospect-lineTo"
                        to="I"
                        toAnchor="center 20%"
                        borderColor={getRelationshipStyle(
                            checkInternalConnections(internalConnections[7])
                        )}
                        borderWidth={getBorderWidth(internalConnections[7])}
                    />
                    <LineTo
                        from="prospect-lineTo"
                        to="J"
                        toAnchor="center 20%"
                        borderColor={getRelationshipStyle(
                            checkInternalConnections(internalConnections[9])
                        )}
                        borderWidth={getBorderWidth(internalConnections[9])}
                    />
                </div>
            </div>

            <NumberOfInteractions ref={childRef} />
        </Container>
    );
};
