import React, { useState, useEffect, useRef } from 'react';
import '../Prospect/Overview.css';
import '../Home/Home.css';
import '../SearchResult.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Card,
    Row,
    Container,
    DropdownButton,
    Dropdown,
} from 'react-bootstrap/';
import DefaultPhoto from '../Shared/DefaultPhoto';
import axios from 'axios';
import config from '../../config';
import { authHeaders } from '../../auth/authHeaders';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PhoneIcon from '@mui/icons-material/Phone';
import {
    setUpLinkedinUrl,
    renderOutlook,
    renderLoadingSpinner,
} from '../Shared/Util';
import salesforce from '../../images/salesforce-logo.png';
import { OrgChartSearch } from './OrgChartSearch';

export const OrgChart = (props) => {
    const navigate = useNavigate();
    const [clientSideSettings] = useState(
        JSON.parse(localStorage.getItem('ClientSideSettings'))
    );
    //const [relProAccountId, setRelProAccountId] = useState(null);
    const [zoomInfoAccountId, setZoomInfoAccountId] = useState(null);
    const [sfdcId, setSfdcId] = useState(null);
    const [department, setDepartment] = useState('C-Suite');
    const [sfdcJobfunction, setSfdcJobfunction] = useState('All');
    const [employees, setEmployees] = useState([]);
    const [page, setPage] = useState(1);
    const [totalResult, setTotalResult] = useState(0);
    const [noNextTier, setNoNextTier] = useState(false);
    const [loading, setLoading] = useState(false);
    const size = 50;
    const [searched, setSearched] = useState(false); // used to filter duplicates after search
    const childRef = useRef();
    const { accountId, externalId } = useParams();

    const zoomInfoOrgChartDepartments = [
        'C-Suite',
        'Finance',
        'Human Resources',
        'Sales',
        'Operations',
        'Information Technology',
        'Engineering & Technical',
        'Marketing',
        'Legal',
        'Medical & Health',
        'Other',
    ];
    const sfdcJobFunctions = {
        'C-Suite': [
            'Executive',
            'Marketing & Sales',
            'Accounting and Finance',
            'Human Resource Management',
            'IT - Systems and Applications',
            'Legal / General Counsel',
            'Innovation & Digital',
            'Operations',
            'Strategy and Corporate Development',
        ],
        Finance: [
            'Accounting and Finance',
            'Compliance',
            'Risk Management',
            'IT - Systems and Applications',
            'Purchasing and Procurement',
            'Strategy and Corporate Development',
            'Customer Service / Support',
        ],
        'Human Resources': [
            'Human Resource Management',
            'IT - Systems and Applications',
        ],
        Sales: [
            'Marketing & Sales',
            'Customer Service / Support',
            'Operations',
            'Accounting and Finance',
            'Strategy and Corporate Development',
        ],
        Operations: [
            'Customer Service / Support',
            'Purchasing and Procurement',
            'Operations',
            'Strategy and Corporate Development',
            'Legal / General Counsel',
            'Risk Management',
        ],
        'Information Technology': [
            'IT - Systems and Applications',
            'Customer Service / Support',
            'Data and Analytics',
            'Innovation & Digital',
            'Security and Privacy',
            'Purchasing and Procurement',
        ],
        'Engineering & Technical': [
            'Data and Analytics',
            'IT - Systems and Applications',
            'Innovation & Digital',
            'Research and Development (R&D)',
        ],
        Marketing: [
            'Marketing & Sales',
            'Innovation & Digital',
            'Strategy and Corporate Development',
            'Customer Service / Support',
        ],
        Legal: [
            'Compliance',
            'Security and Privacy',
            'Legal / General Counsel',
            'Research and Development (R&D)',
            'Strategy and Corporate Development',
        ],
        'Medical & Health': [
            'Research and Development (R&D)',
            'Operations',
            'IT - Systems and Applications',
        ],
        Other: [],
    };

    useEffect(() => {
        if (externalId) {
            setZoomInfoAccountId(externalId);
        }
        if (accountId) {
            setSfdcId(accountId);
        }
    }, []);

    useEffect(() => {
        if (zoomInfoAccountId) {
            getOrgChart(department, [0], sfdcJobfunction, 1);
        }
    }, [zoomInfoAccountId]);

    const getOrgChart = async (dept, tiers, sfdcJobFunc, pg) => {
        setLoading(true);

        if (sfdcJobFunc === 'All') sfdcJobFunc = '';

        // replace ampersand with %26
        let deptUri = dept.replace('&', '%26');
        let sfdcJobFuncUri = sfdcJobFunc.replace('&', '%26');

        let headers = await authHeaders();
        let uri = `${config.azRedirectUri}/api/orgchart?zoomInfoAccountId=${zoomInfoAccountId}&department=${deptUri}&sfdcJobFunction=${sfdcJobFuncUri}&page=${pg}&size=${size}`;

        for (let i = 0; i < tiers.length; i++) {
            uri += `&orgChartTier=${tiers[i]}`;
        }

        await axios
            .get(uri, headers)
            .then((response) => {
                if (response.data.employees !== null) {
                    let responseEmployees = response.data.employees;
                    if (pg === 1 && searched) {
                        // filter duplicate
                        let currentEmployeesIdSet = new Set(
                            employees.map((_) => _.id)
                        );
                        responseEmployees = responseEmployees.filter(
                            (_) => !currentEmployeesIdSet.has(_.id)
                        );
                    }
                    setEmployees((old) => [...old, ...responseEmployees]);
                    setNoNextTier(false);
                    setTotalResult(response.data.maxResults);
                    setPage(pg);
                } else {
                    setNoNextTier(true);
                }
                setLoading(false);
                setSearched(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const onDepartmentChange = (dept) => {
        // C-Suite -> tier 0 -> all
        // Everything else -> tier 2 first, if there are no tier 2, backend will call again w/ tier 3+
        setDepartment(dept);
        setEmployees([]);
        let tier = dept === 'C-Suite' ? [0] : [2];
        let jobFunction = 'All';
        setSfdcJobfunction(jobFunction);
        getOrgChart(dept, tier, jobFunction, 1);

        childRef.current.clearSearch();
    };

    const onJobFunctionChange = (jobFunction) => {
        // 0 tier when filtering job function -> show more results within inner tiers
        let tier =
            jobFunction === 'All' && department !== 'C-Suite' ? [2] : [0];

        setSfdcJobfunction(jobFunction);
        setEmployees([]);

        getOrgChart(department, tier, jobFunction, 1);

        childRef.current.clearSearch();
    };

    const nextPage = () => {
        let tier =
            department === 'C-Suite'
                ? [0]
                : [employees[employees.length - 1].orgChartTier];
        getOrgChart(department, tier, sfdcJobfunction, page + 1);
    };

    const nextTier = () => {
        let tier = [employees[employees.length - 1].orgChartTier + 1];
        getOrgChart(department, tier, sfdcJobfunction, 1);
    };

    const selectSearchResult = async (result) => {
        // child component -> OrgChartSearch
        if (result) {
            let idUri = result.id.replace('&', '%26');
            let headers = await authHeaders();
            let url = `${config.azRedirectUri}/api/orgchart/select?partitionId=${result.partitionId}&id=${idUri}`;
            await axios
                .get(url, headers)
                .then((response) => {
                    setSearched(true);
                    setEmployees([response.data]);

                    setNoNextTier(false);
                    setTotalResult(Infinity); // set high number so Next Page will always show
                    setPage(0); // set 0 so next page will be 1

                    setDepartment(response.data.department);
                    setSfdcJobfunction('All');
                })
                .catch(() => {});
        }
    };

    const renderEmployee = (employee, i) => {
        return (
            <div key={i}>
                {i === 0 ||
                employee.sfdcJobFunction !==
                    employees[i - 1].sfdcJobFunction ? (
                    <h6
                        className="card-title"
                        style={{
                            padding: '0px',
                            color: '#5d7b9a',
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                paddingLeft: `${
                                    employee.orgChartTier * 25 - 5
                                }px`,
                                marginRight: '5px',
                                borderTop: '2px solid #00acc4',
                            }}
                        ></span>
                        {employee.sfdcJobFunction}
                    </h6>
                ) : null}
                <Card
                    style={{
                        marginBottom: '20px',
                        marginLeft: `${employee.orgChartTier * 25}px`,
                    }}
                >
                    <Card.Body
                        className="card-style"
                        style={{
                            borderLeftColor: '#F6871F',
                            borderTopWidth: '0.5px',
                            borderLeftWidth: '3px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                padding: '15px 30px',
                                flexWrap: 'wrap',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: '1 1 auto',
                                }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        padding: '0px 10px',
                                    }}
                                >
                                    {employee.picture ? (
                                        <div
                                            className="profile-pic"
                                            style={{
                                                backgroundImage: `url(${employee.picture})`,
                                            }}
                                        ></div>
                                    ) : (
                                        <DefaultPhoto />
                                    )}
                                </div>
                                <div
                                    style={{
                                        marginLeft: '20px',
                                    }}
                                >
                                    <h4>
                                        {employee.sfdcContactId ? (
                                            <Link
                                                className="prospect-name"
                                                to={`/prospect/${employee.sfdcContactId}`}
                                            >{`${employee.firstName} ${employee.lastName}`}</Link>
                                        ) : (
                                            <div className="prospect-name">{`${employee.firstName} ${employee.lastName}`}</div>
                                        )}
                                    </h4>
                                    <div
                                        className="current-title"
                                        style={{
                                            fontSize: '15px',
                                        }}
                                    >
                                        {employee.title}
                                    </div>
                                    {employee.emailAddress ? (
                                        <span>
                                            <span
                                                style={{
                                                    fontSize: '20px',
                                                }}
                                            >
                                                {renderOutlook(
                                                    employee.emailAddress
                                                )}
                                            </span>
                                            <Card.Link
                                                className="text-primary"
                                                href={`mailto:${employee.emailAddress}`}
                                            >
                                                {employee.emailAddress}
                                            </Card.Link>
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            {employee.phone ||
                            employee.sfdcContactId ||
                            employee.linkedinUrl ? (
                                <div
                                    style={{
                                        float: 'right',
                                        borderLeft: '1px solid rgba(0,0,0,.1)',
                                        padding: '10px 30px',
                                        color: 'gray',
                                    }}
                                >
                                    {employee.phone ? (
                                        <div>
                                            <PhoneIcon
                                                fontSize="small"
                                                style={{
                                                    marginRight: '5px',
                                                }}
                                            />
                                            <div
                                                style={{
                                                    float: 'right',
                                                }}
                                            >
                                                <div>
                                                    {employee.phone} (Work)
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {employee.linkedinUrl ? (
                                        <span
                                            style={{
                                                fontSize: '20px',
                                                marginRight: '5px',
                                            }}
                                        >
                                            {setUpLinkedinUrl(
                                                employee.linkedinUrl
                                            )}
                                        </span>
                                    ) : null}
                                    {employee.sfdcContactId ? (
                                        <a
                                            href={encodeURI(
                                                `${clientSideSettings.sfdcUrl}${employee.sfdcContactId}`
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                style={{
                                                    height: '16px',
                                                    marginRight: '5px',
                                                }}
                                                src={salesforce}
                                            />
                                        </a>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    };

    return (
        <Container fluid className="search-result">
            {navigate.history &&
            navigate.history.length > 1 &&
            navigate.history.action !== 'POP' ? (
                <div
                    className="back-button"
                    style={{ marginBottom: '10px' }}
                    onClick={navigate.history.push(-1)}
                >
                    <DoubleArrowIcon className="back-arrow" />
                    <span className="back-text">Return to Previous Page</span>
                </div>
            ) : null}

            <div className="row-results" style={{ padding: '30px 0px' }}>
                <div
                    style={{
                        marginLeft: '30px',
                        marginRight: '30px',
                    }}
                >
                    <h4 className="card-title" style={{ padding: '0px' }}>
                        <div>
                            <SupervisedUserCircleOutlinedIcon fontSize="large" />{' '}
                            Organizational Chart
                        </div>
                    </h4>
                </div>

                <div
                    style={{
                        backgroundColor: '#00acc4',
                        padding: '10px 30px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    padding: '10px 20px',
                                }}
                            >
                                <div
                                    style={{
                                        color: 'white',
                                        alignSelf: 'center',
                                        marginRight: '10px',
                                    }}
                                >
                                    Department
                                </div>
                                <DropdownButton
                                    title={
                                        <span>
                                            {department}{' '}
                                            <KeyboardArrowDownIcon />
                                        </span>
                                    }
                                    id="dropdown-menu-align-right"
                                    className="sortby-dropdown"
                                    disabled={loading}
                                >
                                    {zoomInfoOrgChartDepartments.map(
                                        (item, i) => (
                                            <Dropdown.Item
                                                key={i}
                                                eventKey={i}
                                                onClick={() =>
                                                    onDepartmentChange(item)
                                                }
                                            >
                                                {item}
                                            </Dropdown.Item>
                                        )
                                    )}
                                </DropdownButton>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    padding: '10px 20px',
                                }}
                            >
                                <div
                                    style={{
                                        color: 'white',
                                        alignSelf: 'center',
                                        marginRight: '10px',
                                    }}
                                >
                                    Job Function
                                </div>
                                <DropdownButton
                                    title={
                                        <span>
                                            {sfdcJobfunction}{' '}
                                            <KeyboardArrowDownIcon />
                                        </span>
                                    }
                                    id="dropdown-menu-align-right"
                                    className="sortby-dropdown"
                                    disabled={loading}
                                >
                                    {[
                                        'All',
                                        ...sfdcJobFunctions[department],
                                    ].map((item, i) => (
                                        <Dropdown.Item
                                            key={i}
                                            eventKey={i}
                                            onClick={() =>
                                                onJobFunctionChange(item)
                                            }
                                        >
                                            {item}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                padding: '10px 20px',
                            }}
                        >
                            <OrgChartSearch
                                ref={childRef}
                                loading={loading}
                                zoomInfoAccountId={zoomInfoAccountId}
                                selectSearchResult={selectSearchResult}
                            />
                        </div>
                    </div>
                </div>

                <div style={{ padding: '30px 20px' }}>
                    <h5
                        className="card-title"
                        style={{ padding: '0px', color: '#5d7b9a' }}
                    >
                        {department}
                    </h5>
                    {!employees || employees.length === 0 ? (
                        loading ? (
                            renderLoadingSpinner()
                        ) : (
                            <Container fluid className="search-result">
                                <Row className="row-results">
                                    <div className="no-result">
                                        Sorry, there are no records found.
                                    </div>
                                </Row>
                            </Container>
                        )
                    ) : (
                        <div
                            style={{
                                borderLeft: '3px #00acc4 solid',
                            }}
                        >
                            {employees.map((employee, i) =>
                                renderEmployee(employee, i)
                            )}

                            {loading ? (
                                renderLoadingSpinner()
                            ) : (
                                <div
                                    style={{
                                        marginLeft: `${
                                            employees[employees.length - 1]
                                                .orgChartTier * 25
                                        }px`,
                                    }}
                                >
                                    {totalResult > size * page ? (
                                        <div>
                                            <span
                                                className="text-primary"
                                                onClick={nextPage}
                                            >
                                                Show More
                                            </span>
                                        </div>
                                    ) : null}
                                    {department !== 'C-Suite' && !noNextTier ? (
                                        <div>
                                            <span
                                                className="text-primary"
                                                onClick={nextTier}
                                            >
                                                Show Next Tier
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};
