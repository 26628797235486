import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import '../Prospect/Overview.css';
import '../Home/Home.css';
import '../SearchResult.css';
import '../Header.css';
import {
    Button,
    FormControl,
    InputGroup,
    Dropdown,
    Form,
} from 'react-bootstrap/';
import { Search } from '@mui/icons-material';
import axios from 'axios';
import config from '../../config';
import { authHeaders } from '../../auth/authHeaders';
import { renderLoadingSpinner } from '../Shared/Util';

export const OrgChartSearch = forwardRef((props, ref) => {
    const [search, setSearch] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [focusSearch, setFocusSearch] = useState(false);
    const [cursor, setCursor] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dontFetch, setDontFetch] = useState(false);

    useImperativeHandle(ref, () => ({
        clearSearch() {
            setSearch('');
            setSuggestions([]);
            setDontFetch(true);
        },
    }));

    useEffect(() => {
        if (!dontFetch) {
            const timeOut = setTimeout(() => {
                // delay recommendation results until user stops typing
                searchRecommendation(search);
            }, 400);
            return () => clearTimeout(timeOut);
        }
        setDontFetch(false);
    }, [search]);

    const searchRecommendation = async (searchValue) => {
        if (searchValue === '') {
            setSuggestions([]);
        } else {
            setLoading(true);
            let headers = await authHeaders();
            let url = `${config.azRedirectUri}/api/orgchart/search?keyword=${searchValue}&zoomInfoAccountId=${props.zoomInfoAccountId}`;
            await axios
                .get(url, headers)
                .then((response) => {
                    setSuggestions(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    setSuggestions([]);
                });
        }
    };

    const onBlur = () => {
        setFocusSearch(false);
        setCursor(null);
    };

    const onFocus = () => {
        setFocusSearch(true);
    };

    const handleKeyDown = (e, searchResults) => {
        //13 = enter, 38 = up, 40 = down
        if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode === 13) {
            e.preventDefault();
        }

        if (e.keyCode === 13 && cursor !== null) {
            selectResult(searchResults[cursor]);
        }

        if (e.keyCode === 40 && cursor === null) {
            setCursor(0);
        } else if (e.keyCode === 38 && cursor > 0) {
            setCursor((prevstate) => prevstate - 1);
        } else if (e.keyCode === 40 && cursor < searchResults.length - 1) {
            setCursor((prevstate) => prevstate + 1);
        }
    };

    const selectResult = (result) => {
        let suggestion = getFullName(
            result.firstName,
            result.middleName,
            result.lastName
        );
        setDontFetch(true);
        setSearch(suggestion);

        props.selectSearchResult(result); // pass to parent -> OrgChart.js

        document.getElementById('header-searchbar-id').blur();
        setCursor(null);
    };

    const getFullName = (firstName, middleName, lastName) => {
        firstName = firstName ? firstName + ' ' : '';
        middleName = middleName ? middleName + ' ' : '';
        lastName = lastName ? lastName : '';

        let fullName = firstName + middleName + lastName;
        return fullName;
    };

    const formatResult = (result) => {
        let fullName = getFullName(
            result.firstName,
            result.middleName,
            result.lastName
        );

        return (
            <div>
                <div>{getHighlight(fullName, result.keyword)}</div>
                <div style={{ color: '#5b7d9a', fontSize: '12px' }}>
                    {result.department}
                </div>
            </div>
        );
    };

    const getHighlight = (name, keyword) => {
        if (!name) return null;

        let lowerName = name.toLowerCase();
        let index = lowerName.indexOf(keyword);

        if (index === -1) return <span>{name}</span>;

        let endIndex = index + keyword.length;
        let formatted = name.split('');
        formatted.splice(index, 0, '<b>');
        formatted.splice(endIndex + 1, 0, '</b>');
        formatted = formatted.join('');

        return <span dangerouslySetInnerHTML={{ __html: formatted }} />;
    };

    return (
        <Form>
            <InputGroup>
                <Button
                    variant="light"
                    id="button-addon1"
                    style={{
                        backgroundColor: '#fff',
                        border: '0px',
                        color: '#f6871f',
                        borderRadius: '.25rem 0px 0px .25rem',
                        height: 'calc(1.5em + .75rem + 2px)',
                        cursor: 'default',
                    }}
                    disabled={props.loading}
                >
                    <Search />
                </Button>
                <FormControl
                    style={{
                        borderColor: '#fff',
                        borderRadius: '0px .25rem .25rem 0px',
                        width: '100% !important',
                    }}
                    placeholder="Search"
                    type="text"
                    id="header-searchbar-id"
                    className={
                        'mr-sm-2' +
                        (suggestions.length > 0 && focusSearch
                            ? ' header-searchbar-radius'
                            : '')
                    }
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onKeyDown={(event) =>
                        handleKeyDown(event, suggestions.slice(0, 5))
                    }
                    disabled={props.loading}
                />
                {loading ? (
                    <div
                        className="searchbar-result"
                        style={{
                            display: 'block',
                            padding: '10px',
                        }}
                    >
                        {renderLoadingSpinner()}
                    </div>
                ) : (
                    <div
                        className="searchbar-result"
                        style={{
                            display:
                                suggestions.length > 0 && focusSearch
                                    ? 'block'
                                    : 'none',
                        }}
                    >
                        {suggestions.slice(0, 5).map((result, index) => {
                            return (
                                <Dropdown.Item
                                    className={
                                        cursor === index ? 'active' : null
                                    }
                                    key={index}
                                    onMouseDown={() => selectResult(result)}
                                >
                                    {formatResult(result)}
                                </Dropdown.Item>
                            );
                        })}
                    </div>
                )}
            </InputGroup>
        </Form>
    );
});
