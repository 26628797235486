export default (state, action) => {
    switch (action.type) {
        case 'GET_PROSPECT':
            return {
                ...state,
                prospect: action.payload,
            };
        case 'GET_NEWSFEED':
            return {
                ...state,
                newsFeed: action.payload,
            };
        case 'SET_SEARCH_SETTING':
            return {
                ...state,
                searchSetting: action.payload,
            };
        case 'SET_FACET':
            return {
                ...state,
                searchFacet: action.payload,
            };
        case 'GET_FOLLOWING':
            return {
                ...state,
                following: action.payload,
            };
        case 'GET_FOLLOWING_ACCOUNTS':
            return {
                ...state,
                followingAccounts: action.payload,
            };
        case 'GET_CONTACTOWNER':
            return {
                ...state,
                contactOwner: action.payload,
            };
        case 'GET_RELATIONSHIPLEAD':
            return {
                ...state,
                relationshipLead: action.payload,
            };
        case 'GET_ACCOUNT':
            return {
                ...state,
                account: action.payload,
            };
        case 'SET_AUTHORIZED':
            return {
                ...state,
                authorized: action.payload,
            };
        case 'SET_UPDATED_FOLLOWING':
            return {
                ...state,
                updatedFollowing: action.payload,
            };
        case 'SET_UPDATED_FOLLOWING_ACCOUNTS':
            return {
                ...state,
                updatedFollowingAccounts: action.payload,
            };
        case 'SET_PROSPECT_PAGE':
            return {
                ...state,
                isProspectPage: action.payload,
            };
        case 'GET_TAGGEDRELATIONSHIPS':
            return {
                ...state,
                taggedRelationships: action.payload,
            };
        case 'SET_USER_EMAIL':
            return {
                ...state,
                userEmail: action.payload,
            };
        case 'GET_SCOOPS':
            return {
                ...state,
                scoops: action.payload,
            };
        case 'GET_INTENTS':
            return {
                ...state,
                intents: action.payload,
            };
        case 'GET_PROSPECT_VALIDATIONS':
            return {
                ...state,
                prospectValidations: action.payload,
            };
        case 'GET_ACCOUNT_VALIDATIONS':
            return {
                ...state,
                accountValidations: action.payload,
            };
        default:
            return state;
    }
};
