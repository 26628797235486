import React, { useState, useRef } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DefaultPhoto from '../Shared/DefaultPhoto';
import {
    asteriskHoverover,
    getRelationshipStyle,
    formatLastContacted,
} from '../Shared/Util';
import { EmployeeLinks } from '../Shared/EmployeeLinks';
import { NumberOfInteractions } from '../Prospect/NumberOfInteractions';

export const ShowInternalConnections = (connections, type, id) => {
    const childRef = useRef();
    const [clientSideSettings] = useState(
        JSON.parse(localStorage.getItem('ClientSideSettings'))
    );

    return (
        <>
            <ListGroup>
                {connections.map((connection, i) => (
                    <ListGroup.Item
                        key={i}
                        style={
                            i % 2 === 0
                                ? {
                                      backgroundColor: 'rgba(0,0,0,.05)',
                                  }
                                : null
                        }
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            <div
                                style={{
                                    marginRight: '15px',
                                    alignSelf: 'center',
                                    position: 'relative',
                                }}
                            >
                                {connection.employee.photoUrl ? (
                                    <img
                                        className="internal-connection-picture"
                                        style={{
                                            borderColor: getRelationshipStyle(
                                                connection.relationShipStrength
                                            ),
                                            width: '100px',
                                            minWidth: '100px',
                                        }}
                                        src={`https://hubemployees.azureedge.net${connection.employee.photoUrl}`}
                                        alt="Profile"
                                    />
                                ) : (
                                    <DefaultPhoto
                                        format="square"
                                        style={{
                                            borderColor: getRelationshipStyle(
                                                connection.relationShipStrength
                                            ),
                                            width: '100px',
                                            minWidth: '100px',
                                        }}
                                    />
                                )}
                                <span
                                    className="relationship-type"
                                    style={{
                                        background: getRelationshipStyle(
                                            connection.relationShipStrength
                                        ),
                                        display: connection.relationShipStrength
                                            ? null
                                            : 'none',
                                    }}
                                >
                                    {connection.relationShipStrength}
                                </span>
                            </div>
                            <div style={{ flexGrow: '1' }}>
                                <Row>
                                    <Col
                                        style={{
                                            marginRight: '15px',
                                            marginTop: '5px',
                                        }}
                                    >
                                        <h4
                                            className="prospect-name"
                                            style={{
                                                marginBottom: '0px',
                                            }}
                                        >
                                            {connection.employee.isTerminated
                                                ? asteriskHoverover(
                                                      connection.employee.name,
                                                      false
                                                  )
                                                : connection.employee.name}
                                            {connection.employee.company
                                                ? ', ' +
                                                  connection.employee.company
                                                : ''}
                                        </h4>
                                        <div>
                                            {(connection.employee.title &&
                                            connection.employee.title.indexOf(
                                                ' ('
                                            ) > 0
                                                ? connection.employee.title.substring(
                                                      0,
                                                      connection.employee.title.indexOf(
                                                          ' ('
                                                      )
                                                  )
                                                : connection.employee.title) +
                                                (connection.employee.title &&
                                                connection.employee.city
                                                    ? ', '
                                                    : '') +
                                                (connection.employee.city
                                                    ? connection.employee.city +
                                                      ' Office'
                                                    : '')}
                                        </div>
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    {!connection.employee.isTerminated ? (
                                        <Col
                                            lg={5}
                                            className="social-media-icon"
                                        >
                                            <EmployeeLinks
                                                data={connection.employee}
                                                isPeopleSearchLive={
                                                    clientSideSettings.isPeopleSearchLive
                                                }
                                                peopleSearchUrl={encodeURI(
                                                    clientSideSettings.peopleSearchUrl
                                                )}
                                            />
                                        </Col>
                                    ) : null}
                                    <Col
                                        style={{
                                            textAlign: 'right',
                                        }}
                                    >
                                        {connection.lastContactTime ? (
                                            <div className="prospect-fields">
                                                <span className="header-fields">
                                                    Last Connected:{' '}
                                                </span>
                                                {formatLastContacted(
                                                    connection.lastContactTime,
                                                    connection.lastContactType
                                                )}
                                            </div>
                                        ) : null}
                                        {connection.numberOfInteractions ? (
                                            <div
                                                className="prospect-fields"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                <span className="header-fields">
                                                    Number of Interactions:{' '}
                                                </span>
                                                <a
                                                    className="text-primary"
                                                    onClick={() =>
                                                        childRef.current.openModal(
                                                            id,
                                                            type,
                                                            connection.employeeId,
                                                            connection.employee
                                                                .name,
                                                            '',
                                                            ''
                                                        )
                                                    }
                                                >
                                                    {
                                                        connection.numberOfInteractions
                                                    }
                                                </a>
                                            </div>
                                        ) : (
                                            <div className="header-fields">
                                                This contact is tagged as a
                                                Relationship within Salesforce
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <NumberOfInteractions ref={childRef} />
        </>
    );
};
