import React, { useContext, useEffect, useState } from 'react';
import '../Prospect/Overview.css';
import { GlobalContext } from '../../context/GlobalState';
import VerticalSplitOutlinedIcon from '@mui/icons-material/VerticalSplitOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Card, DropdownButton, Dropdown } from 'react-bootstrap/';
import { NotAvailable } from '../HoverOver/NotAvailable';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import axios from 'axios';
import config from '../../config';
import { authHeaders } from '../../auth/authHeaders';
import { renderExternalNotAvailable } from '../Shared/Util';

export const MarketingSolution = (props) => {
    const { account } = useContext(GlobalContext);
    const solutionColor = [
        '#004068',
        '#00ACC4',
        '#F6871F',
        '#00A28F',
        '#D74B29',
        '#3C3D3F',
    ]; // first 6 colors predetermined
    const solutionTimeAll = [
        'All',
        '7 Days',
        '30 Days',
        '60 Days',
        '180 Days',
        'Rolling 12 Months',
    ];
    const { isProspectPage } = useContext(GlobalContext);
    const [graphData, setGraphData] = useState([]);
    const [actionTakenAll, setActionTakenAll] = useState(['All']);
    const [solutionTime, setSolutionTime] = useState('All');
    const [actionTaken, setActionTaken] = useState('All');

    useEffect(() => {
        if (
            props.marketingSolutionData &&
            props.marketingSolutionData.marketingSolutionA
        ) {
            if (
                props.marketingSolutionData.marketingSolution1Year &&
                props.marketingSolutionData.marketingSolution1Year.length > 0
            ) {
                setGraphData(
                    props.marketingSolutionData.marketingSolution1Year
                );
                setSolutionTime('Rolling 12 Months');
            } else {
                setGraphData(props.marketingSolutionData.marketingSolutionA);
            }
            setActionTakenAll([
                ...actionTakenAll,
                ...props.marketingSolutionData.campaignsActionsA,
            ]);
        }
    }, [props.marketingSolutionData]);

    const getMarketingSolution = async (time, action) => {
        time = time === 'All' ? '' : time;
        action = action === 'All' ? '' : encodeURIComponent(action);

        let headers = await authHeaders();
        let url = `${config.azRedirectUri}/api/prospects/marketingsolution/${
            props.marketingSolutionData.id
        }?time=${time}&action=${action}&account=${!isProspectPage}`;

        await axios
            .get(url, headers)
            .then((res) => {
                setGraphData(res.data);
            })
            .catch(() => {
                setGraphData([]);
            });
    };

    const onActionChange = (action) => {
        setActionTaken(action);
        getMarketingSolution(solutionTime, action);
    };

    const onTimeChange = (time) => {
        setSolutionTime(time);
        getMarketingSolution(time, actionTaken);
    };

    const getSolutionColor = (i) => {
        if (i < 6) {
            return solutionColor[i];
        }
        return (
            'rgb(' +
            (Math.floor(Math.random() * 255) + 1) +
            ',' +
            (Math.floor(Math.random() * 255) + 1) +
            ',' +
            (Math.floor(Math.random() * 255) + 1) +
            ')'
        );
    };

    const CustomTooltip = (e) => {
        if (!e.active) {
            return null;
        }
        return <div className="solution-tooltip">{e.payload[0].value}</div>;
    };

    return (
        <Card>
            <Card.Body
                className="card-style"
                style={{ borderTopColor: '#F6871F' }}
            >
                <h4
                    className="card-title"
                    id="card-title-id"
                    style={{
                        paddingBottom: '0px',
                        marginBottom: '0px',
                    }}
                >
                    <VerticalSplitOutlinedIcon fontSize="large" /> Marketing
                    Campaigns by Solution
                </h4>
                {!isProspectPage ? (
                    <div
                        className="solution-subtitle"
                        style={{ paddingBottom: '0px' }}
                    >
                        Protiviti marketing topics that employees from this
                        account <b>have interacted</b> with.
                    </div>
                ) : (
                    <div
                        className="solution-subtitle"
                        style={{
                            paddingBottom: '0px',
                            paddingLeft: '50px',
                        }}
                    >
                        Protiviti marketing topics that this prospect interacted
                        with.
                    </div>
                )}
                {props &&
                props.marketingSolutionData &&
                props.marketingSolutionData.marketingSolutionA &&
                props.marketingSolutionData.marketingSolutionA.length > 0 ? (
                    <>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                    flexFlow: 'wrap',
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: '30px',
                                    }}
                                >
                                    <div>Action Taken</div>
                                    <div>
                                        <DropdownButton
                                            align="end"
                                            title={
                                                <span>
                                                    {actionTaken}{' '}
                                                    <KeyboardArrowDownIcon />
                                                </span>
                                            }
                                            id="dropdown-menu-align-right"
                                            className="sortby-dropdown"
                                        >
                                            {actionTakenAll.map((action, i) => (
                                                <Dropdown.Item
                                                    key={i}
                                                    eventKey={i}
                                                    onClick={() =>
                                                        onActionChange(action)
                                                    }
                                                >
                                                    {action}
                                                </Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        marginRight: '30px',
                                    }}
                                >
                                    <div>Time Period</div>
                                    <div>
                                        <DropdownButton
                                            align="end"
                                            title={
                                                <span>
                                                    {solutionTime}{' '}
                                                    <KeyboardArrowDownIcon />
                                                </span>
                                            }
                                            id="dropdown-menu-align-right"
                                            className="sortby-dropdown"
                                        >
                                            {solutionTimeAll.map((time, i) => (
                                                <Dropdown.Item
                                                    key={i}
                                                    eventKey={i}
                                                    onClick={() =>
                                                        onTimeChange(time)
                                                    }
                                                >
                                                    {time}
                                                </Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {graphData.length > 0 ? (
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: '20px',
                                }}
                            >
                                <div className="marketing-solution-graph">
                                    <ResponsiveContainer
                                        width="100%"
                                        height={graphData.length * 100}
                                    >
                                        <BarChart
                                            data={graphData}
                                            layout="vertical"
                                            margin={{
                                                top: 10,
                                                right: 20,
                                                left: 30,
                                                bottom: 10,
                                            }}
                                            barSize={50}
                                        >
                                            <XAxis
                                                type="number"
                                                tickCount={graphData.length}
                                                hide={true}
                                            />
                                            <YAxis
                                                type="category"
                                                dataKey="solutionCount"
                                                hide={true}
                                            />
                                            <Bar dataKey="solutionCount">
                                                {graphData.map((x, i) => (
                                                    <Cell
                                                        key={i}
                                                        fill={
                                                            x.solution !==
                                                            'Not Available'
                                                                ? getSolutionColor(
                                                                      i
                                                                  )
                                                                : '#c8c9c7'
                                                        }
                                                    />
                                                ))}
                                            </Bar>
                                            <Tooltip
                                                content={CustomTooltip}
                                                cursor={{
                                                    fill: 'rgba(0, 0, 0, 0)',
                                                }}
                                            />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="marketing-solution-legend">
                                    {graphData.map((x, i) => (
                                        <div
                                            key={i}
                                            style={{
                                                height: '50px',
                                            }}
                                        >
                                            {x.solution}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <>
                                <hr />
                                <div className="not-available">{`There are no marketing campaigns that this ${
                                    isProspectPage ? 'prospect' : 'account'
                                } has taken action on for the time period selected.`}</div>
                            </>
                        )}
                    </>
                ) : account.isExternalOnly ? (
                    renderExternalNotAvailable('account')
                ) : (
                    <>
                        <hr />
                        <div className="not-available">Not Available</div>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};
