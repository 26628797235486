import React, { useContext, useState, useEffect } from 'react';
import '../Prospect/Overview.css';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import { mapValidationField, getUrlLink } from '../Shared/Util';
import { GlobalContext } from '../../context/GlobalState';
import { OverlayTooltip } from '../HoverOver/OverlayTooltip';
import { OverlayStickOnHover } from '../HoverOver/OverlayStickOnHover';

export const ExternalDetail = (props) => {
    const { prospectValidations, accountValidations, setValidation } =
        useContext(GlobalContext);
    const [validationState, setValidationState] = useState(null); // thumbsup = 0, thumbsdown = 1

    useEffect(() => {
        let validation = null;
        if (props.type === 0) validation = prospectValidations;
        else if (props.type === 1) validation = accountValidations;

        if (validation !== null && validation.length > 0) {
            let found = validation.find(
                (_) => _.field === mapValidationField(props.field, false)
            );
            if (found && (found.state === 0 || found.state === 1))
                setValidationState(found.state);
            else setValidationState(null);
        } else {
            setValidationState(null);
        }
    }, [prospectValidations, accountValidations]);

    const handleOnClick = (state) => {
        setValidation(
            props.relProId,
            props.type,
            state,
            mapValidationField(props.field, false)
        );
    };

    const renderThumbs = () => {
        return (
            <span>
                {validationState === 0 ? (
                    <OverlayTooltip
                        tooltipText="Use this to validate that the data provided is accurate"
                        hoverElement={
                            <ThumbUpIcon
                                fontSize="small"
                                className="validation-thumb"
                                onClick={() => handleOnClick(0)}
                            />
                        }
                        imageType="none"
                    />
                ) : (
                    <OverlayTooltip
                        tooltipText="Use this to validate that the data provided is accurate"
                        hoverElement={
                            <ThumbUpOutlinedIcon
                                fontSize="small"
                                className="validation-thumb"
                                onClick={() => handleOnClick(0)}
                            />
                        }
                        imageType="none"
                    />
                )}
                {validationState === 1 ? (
                    <OverlayTooltip
                        tooltipText="Use this to validate that the data provided is NOT accurate"
                        hoverElement={
                            <ThumbDownIcon
                                fontSize="small"
                                className="validation-thumb"
                                onClick={() => handleOnClick(1)}
                            />
                        }
                        imageType="none"
                    />
                ) : (
                    <OverlayTooltip
                        tooltipText="Use this to validate that the data provided is NOT accurate"
                        hoverElement={
                            <ThumbDownOutlinedIcon
                                fontSize="small"
                                className="validation-thumb"
                                onClick={() => handleOnClick(1)}
                            />
                        }
                        imageType="none"
                    />
                )}
            </span>
        );
    };

    return (
        <OverlayStickOnHover
            placement="bottom"
            onMouseEnter={() => {}}
            delay={200}
            component={
                <div>
                    <span className="vendor-hover"> Vendor </span>
                    <span> {props.data ? renderThumbs() : null} </span>
                    <div>
                        This data is provided by an external data source. Please
                        visit{' '}
                        <a
                            href={getUrlLink('sfdcLightningUrl', null)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Salesforce
                        </a>
                        , if you would like to update Salesforce with the
                        external data presented.
                    </div>
                </div>
            }
        >
            <span className="vendor-icon">v</span>
        </OverlayStickOnHover>
    );
};
