import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Overview } from './components/Prospect/Overview';
import { GlobalProvider } from './context/GlobalState';
import { Header } from './components/Header';
import { SearchResult } from './components/SearchResult';
import { Home } from './components/Home/Home';
import { ViewMore } from './components/Home/ViewMore';
import ScrollToTop from '../src/components/ScrollToTop';
import { CompanyOverview } from './components/Account/CompanyOverview';
import { InternalConnectionsGraph } from './components/Prospect/InternalConnectionsGraph';
import { OrgChart } from './components/Account/OrgChart';
import { useIdleTimer } from 'react-idle-timer';
import { CircularProgress, LinearProgress } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import PrivateRoute from '../src/PrivateRoute';
import { Unauthorized } from '../src/components/Unauthorized';
import Cookies from 'js-cookie';
import { getAppInsights } from './telemetry/TelemetryService';
import TelemetryProvider from './telemetry/telemetry-provider';

export const App = (props) => {
    let appInsights = null;
    const [showIdleWarning, setShowIdleWarning] = useState(false);
    const [clientSideSettings] = useState(
        JSON.parse(localStorage.getItem('ClientSideSettings'))
    );
    let applicationInsightKey = clientSideSettings['instrumentationKey'];
    const [sessionWarningToLogoutSeconds] = useState(
        clientSideSettings['sessionWarningToLogoutSeconds']
    );
    const [seconds, setSeconds] = useState(
        clientSideSettings['sessionWarningToLogoutSeconds']
    );
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                if (seconds === 0) logout();
                else setSeconds((seconds) => seconds - 1);
            }, 1000);
        } else if (!isActive && seconds !== 0 && seconds !== null) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    const handleOnIdle = (event) => {
        if (clientSideSettings.sessionWarningMS !== null)
            setShowIdleWarning(true);
        setIsActive(true);
    };

    const handleOnActive = (event) => {
        setShowIdleWarning(false);
        setSeconds(clientSideSettings.sessionWarningToLogoutSeconds);
        setIsActive(false);
    };

    const handleOnAction = (e) => {
        setShowIdleWarning(false);
        setSeconds(clientSideSettings.sessionWarningToLogoutSeconds);
        setIsActive(false);
    };
    useIdleTimer({
        timeout: clientSideSettings.sessionWarningMS,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
    });

    const logout = () => {
        //clear msal from localstorage
        var arr = [];
        for (var i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i).includes('msal')) {
                arr.push(localStorage.key(i));
            }
        }
        for (var j = 0; j < arr.length; j++) {
            localStorage.removeItem(arr[j]);
        }
        //clear all cookies
        const cookies = Cookies.get();
        for (const cookie in cookies) {
            Cookies.remove(cookie, { path: '' });
        }

        if (appInsights != null) {
            appInsights.trackEvent({ name: 'Logout from ProConnect.' });
        }
        window.open(
            'https://login.microsoftonline.com/16532572-d567-4d67-8727-f12f7bb6aed3/oauth2/logout' /*?client_id=&post_logout_redirect_uri=" + encodeURI(window.location.origin)*/,
            '_self'
        );
    };

    return (
        <GlobalProvider authData={props.authData}>
            <div>
                <Router>
                    <TelemetryProvider
                        authData={props.authData}
                        instrumentationKey={applicationInsightKey}
                        after={() => {
                            appInsights = getAppInsights();
                        }}
                    >
                        <ScrollToTop />
                        <Header />
                        <Routes>
                            <Route
                                path="/unauthorized"
                                element={<Unauthorized />}
                            />
                            <Route
                                exact
                                path="/"
                                element={
                                    <PrivateRoute>
                                        <Home />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/prospect/:prospectId"
                                element={
                                    <PrivateRoute>
                                        <Overview />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/company/:accountId"
                                element={
                                    <PrivateRoute>
                                        <CompanyOverview />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/search"
                                element={
                                    <PrivateRoute>
                                        <SearchResult />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/view/:field"
                                element={
                                    <PrivateRoute>
                                        <ViewMore />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/connections/:prospectId"
                                element={
                                    <PrivateRoute>
                                        <InternalConnectionsGraph />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/orgchart/:accountId/:externalId"
                                element={
                                    <PrivateRoute>
                                        <OrgChart />
                                    </PrivateRoute>
                                }
                            />
                        </Routes>
                        {showIdleWarning ? (
                            <Modal show={true} dialogClassName="custom-dialogx">
                                <Modal.Title>
                                    <h4
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        {' '}
                                        Session Timeout Warning{' '}
                                    </h4>
                                </Modal.Title>
                                <Modal.Body
                                    style={{
                                        overflowX: 'auto',
                                        paddingLeft: '1%',
                                        paddingRight: '1%',
                                        paddingTop: '0px',
                                        paddingBottom: '80px',
                                    }}
                                >
                                    <p
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        {' '}
                                        You will be timed out in {seconds}{' '}
                                        seconds{' '}
                                    </p>
                                    <CircularProgress> </CircularProgress>
                                    <LinearProgress
                                        variant="determinate"
                                        value={
                                            ((sessionWarningToLogoutSeconds -
                                                seconds) *
                                                100) /
                                            sessionWarningToLogoutSeconds
                                        }
                                    />
                                </Modal.Body>
                            </Modal>
                        ) : null}
                    </TelemetryProvider>
                </Router>
            </div>
        </GlobalProvider>
    );
};
