import { authProvider } from './authProvider';
import Cookies from 'js-cookie';

export const authHeaders = async () => {
    let getCookie = (cname) => {
        return Cookies.get(cname) ?? '';
    };

    let token = await authProvider.getAccessToken();
    let headers = {
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': getCookie('X-REQUEST-TOKEN'),
        },
    };

    return headers;
};
