import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from '@musicstory/react-bootstrap-table-next';
import paginationFactory from '@musicstory/react-bootstrap-table2-paginator';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { NotAvailable } from '../HoverOver/NotAvailable';
import { OverlayTooltip } from '../HoverOver/OverlayTooltip';
import {Export} from './Export';

import {
    getUrlLink,
    formatDate,
    renderSortArrow,
    formatText,
    triggerFilter,
    handleOnFilterTable,
    asteriskHoverover,
    getFormatCompanyLink,
    getOnStaticOptionsUpdate,
    renderExternalNotAvailable,
} from './Util';
import { FilterMultiSelect } from '../Shared/FilterMultiSelect';
import filterFactory, {
    customFilter,
    FILTER_TYPES,
} from '@musicstory/react-bootstrap-table2-filter';
import salesforce from '../../images/salesforce-logo.png';
import { Link } from 'react-router-dom';

export const Opportunity = () => {
    const { account, prospect } = useContext(GlobalContext);
    const [opportunityState, setOpportunityState] = useState('open');

    const [openOpportunities, setOpenOpportunities] = useState([]);
    const [allOpportunities, setAllOpportunities] = useState([]);
    const [showMore, setShowMore] = useState(false);

    const [showFilters, setShowFilters] = useState(true);
    const [showFiltersModal, setShowFiltersModal] = useState(true);
    const [clientSideSettings] = useState(
        JSON.parse(localStorage.getItem('ClientSideSettings'))
    );
    const [currentFilters, setCurrentFilters] = useState({});
    const [staticOption, setStaticOption] = useState({});
    const [filteredData, setFilteredData] = useState([]);

    // needed for getFilter to clear when switching between all & open
    let nameFilter,
        closeDateFilter,
        oppMdFilter,
        pkbFilter,
        solutionFilter,
        serviceFilter,
        stageFilter,
        reasonFilter,
        explanationFilter,
        emFilter;

    useEffect(() => {
        if (
            (allOpportunities &&
                opportunityState === 'all' &&
                account.allOpportunity &&
                account.allOpportunity.length > 2) ||
            (openOpportunities &&
                opportunityState === 'open' &&
                account.openOpportunity &&
                account.openOpportunity.length > 2)
        ) {
            setShowFilters(true);
        } else {
            setShowFilters(false);
        }
    }, [opportunityState]);

    useEffect(() => {
        if (account) {
            if (account.openOpportunity) {
                setOpenOpportunities(account.openOpportunity);
            }
            if (account.allOpportunity) {
                setAllOpportunities(account.allOpportunity);
            }
            setFilteredData(account.openOpportunity);
        }
    }, [account]);

    useEffect(() => {
        if (
            (opportunityState === 'open' &&
                openOpportunities &&
                openOpportunities.length > 0) ||
            (opportunityState === 'all' &&
                allOpportunities &&
                allOpportunities.length > 0)
        ) {
            clearAllFilters();
        }
    }, [opportunityState]);

    const onStaticOptionsUpdate = (staticColumn, filterKey, noFilters) => {
        if (noFilters || !staticOption.hasOwnProperty(filterKey))
            setStaticOption(
                getOnStaticOptionsUpdate(
                    staticColumn,
                    filterKey,
                    noFilters,
                    staticOption
                )
            );
    };

    const afterFilter = (newResult, newFilters) => {
        setCurrentFilters((prevData) => {
            if (JSON.stringify(prevData) !== JSON.stringify(newFilters)) {
                return newFilters;
            }
            return prevData;
        });

        setFilteredData((prevData) => {
            if (JSON.stringify(prevData) !== JSON.stringify(newResult)) {
                return newResult;
            }
            return prevData;
        });
    };

    const formatOppStage = (stage) => {
        let oppStage = stage;
        switch (stage) {
            case 'Opportunity Qualified':
                oppStage = 'Opp. Qual.';
                break;
            case 'Potential Opportunity Identified':
                oppStage = 'Pot. Opp. Id.';
                break;
            case 'Proposed Approach / Pricing':
                oppStage = 'Prop. Appr.';
                break;
            case 'Client Negotiation / Review':
                oppStage = 'Client Neg.';
                break;
            default:
                oppStage = stage;
        }
        return oppStage;
    };

    const renderKeyBuyerLink = (value, row, currentUrl) => {
        let pageUrl = currentUrl ? currentUrl : window.location.pathname;
        if (!row.pkbIsNotProspect) {
            //if (pageUrl.startsWith('/company/'))
            //    return <a href={`${config.azRedirectUri}/prospect/${row.primaryKeyBuyerId}`}>{value}</a>;
            //else if (prospect && row.primaryKeyBuyerId === prospect.id)
            //    return value;
            //else
            //    return <a href={`${config.azRedirectUri}/prospect/${row.primaryKeyBuyerId}`}>{value}</a>;

            if (
                (prospect && row.primaryKeyBuyerId !== prospect.id) ||
                pageUrl.startsWith('/company/')
            )
                return (
                    <Link to={`/prospect/${row.primaryKeyBuyerId}`}>
                        {value}
                    </Link>
                );
            else return value;
        } else return asteriskHoverover(value, true);
    };

    const OpportunityTableColumns = [
        {
            dataField: 'name',
            text: 'Opportunity',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (
                <React.Fragment>
                    {formatText(value, 30).length > 30 ? (
                        <OverlayTooltip
                            tooltipText={value}
                            hoverElement={
                                row.opportunityKey ? (
                                    <Card.Link
                                        className="text-primary"
                                        href={encodeURI(
                                            clientSideSettings.iManageUrl +
                                                row.opportunityKey
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {formatText(value, 30)}
                                    </Card.Link>
                                ) : (
                                    <Card.Link
                                        className="text-primary"
                                        href={encodeURI(
                                            clientSideSettings.sfdcLightningOpportunity +
                                                row.id +
                                                '/view'
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {formatText(value, 30)}
                                    </Card.Link>
                                )
                            }
                        />
                    ) : (
                        <span>
                            {row.opportunityKey ? (
                                <Card.Link
                                    className="text-primary"
                                    href={encodeURI(
                                        clientSideSettings.iManageUrl +
                                            row.opportunityKey
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {formatText(value, 30)}
                                </Card.Link>
                            ) : (
                                <Card.Link
                                    className="text-primary"
                                    href={encodeURI(
                                        clientSideSettings.sfdcLightningOpportunity +
                                            row.id +
                                            '/view'
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {formatText(value, 30)}
                                </Card.Link>
                            )}
                        </span>
                    )}
                </React.Fragment>
            ),
            headerStyle: { fontWeight: '500', width: '110px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    nameFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'name'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={
                        opportunityState === 'open'
                            ? openOpportunities
                            : allOpportunities
                    }
                    filterKey={'name'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'opportunityCloseDate',
            text: 'Opportunity Close Date',
            formatter: (value, row) =>
                value && value.substring(0, 4) !== '0001' ? (
                    formatDate(value)
                ) : (
                    <NotAvailable
                        section="internal-field"
                        showNotAvailable={true}
                        linkUrl={getUrlLink(
                            row.opportunityKey ? 'iManage' : 'sfdclightningOpp',
                            row.opportunityKey ? row.opportunityKey : row.id
                        )}
                    />
                ),
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '105px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    closeDateFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(
                        filterArr,
                        data,
                        'opportunityCloseDate'
                    ),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={
                        opportunityState === 'open'
                            ? openOpportunities
                            : allOpportunities
                    }
                    filterKey={'opportunityCloseDate'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    format={'date'}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
            csvFormatter: (value, row) =>
                value && value.substring(0, 4) !== '0001'
                    ? formatDate(value)
                    : "Not Applicable/Not Available"
        },
        {
            dataField: 'opportunityManagingDirector',
            text: 'Opportunity MD/D',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    row.opportunityManagingDirectorIsDeleted ? (
                        asteriskHoverover(value, false)
                    ) : (
                        value
                    )
                ) : (
                    <NotAvailable
                        section="internal-field"
                        showNotAvailable={true}
                        linkUrl={getUrlLink(
                            row.opportunityKey ? 'iManage' : 'sfdclightningOpp',
                            row.opportunityKey ? row.opportunityKey : row.id
                        )}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '105px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    oppMdFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(
                        filterArr,
                        data,
                        'opportunityManagingDirector'
                    ),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={
                        opportunityState === 'open'
                            ? openOpportunities
                            : allOpportunities
                    }
                    filterKey={'opportunityManagingDirector'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
        },
        {
            dataField: 'primaryKeyBuyer',
            text: 'Primary Key Buyer',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    renderKeyBuyerLink(value, row)
                ) : (
                    <NotAvailable
                        section="internal-field"
                        showNotAvailable={true}
                        linkUrl={getUrlLink(
                            row.opportunityKey ? 'iManage' : 'sfdclightningOpp',
                            row.opportunityKey ? row.opportunityKey : row.id
                        )}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '105px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    pkbFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'primaryKeyBuyer'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={
                        opportunityState === 'open'
                            ? openOpportunities
                            : allOpportunities
                    }
                    filterKey={'primaryKeyBuyer'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
            csvFormatter: (value, row) =>
                    value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'solution',
            text: 'Solution',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable
                        section="internal-field"
                        showNotAvailable={true}
                        linkUrl={getUrlLink(
                            row.opportunityKey ? 'iManage' : 'sfdclightningOpp',
                            row.opportunityKey ? row.opportunityKey : row.id
                        )}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '105px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    solutionFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'solution'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={
                        opportunityState === 'open'
                            ? openOpportunities
                            : allOpportunities
                    }
                    filterKey={'solution'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
            csvFormatter: (value, row) =>
                    value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'serviceOffering',
            text: 'Service Name',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    value
                ) : (
                    <NotAvailable
                        section="internal-field"
                        showNotAvailable={true}
                        linkUrl={getUrlLink(
                            row.opportunityKey ? 'iManage' : 'sfdclightningOpp',
                            row.opportunityKey ? row.opportunityKey : row.id
                        )}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '120px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    serviceFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'serviceOffering'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={
                        opportunityState === 'open'
                            ? openOpportunities
                            : allOpportunities
                    }
                    filterKey={'serviceOffering'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
            csvFormatter: (value, row) =>
                    value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'opportunityStage',
            text: 'Stage',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    formatOppStage(value)
                ) : (
                    <NotAvailable
                        section="internal-field"
                        showNotAvailable={true}
                        linkUrl={getUrlLink(
                            row.opportunityKey ? 'iManage' : 'sfdclightningOpp',
                            row.opportunityKey ? row.opportunityKey : row.id
                        )}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '105px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    stageFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'opportunityStage'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={
                        opportunityState === 'open'
                            ? openOpportunities
                            : allOpportunities
                    }
                    filterKey={'opportunityStage'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
            csvFormatter: (value, row) =>
                    value ? formatOppStage(value) : "Not Applicable/Not Available"
        },
        {
            dataField: 'reasonForLoss',
            text: 'Reason',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                row.opportunityStage === 'Closed - Lost' ? (
                    value ? (
                        value
                    ) : (
                        <NotAvailable
                            section="internal-field"
                            showNotAvailable={true}
                            linkUrl={getUrlLink(
                                row.opportunityKey
                                    ? 'iManage'
                                    : 'sfdclightningOpp',
                                row.opportunityKey ? row.opportunityKey : row.id
                            )}
                        />
                    )
                ) : (
                    'Not Applicable'
                ),
            headerStyle: { fontWeight: '500', width: '105px' },
            hidden: opportunityState === 'open' ? true : false,
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    reasonFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'reasonForLoss'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={
                        opportunityState === 'open'
                            ? openOpportunities
                            : allOpportunities
                    }
                    filterKey={'reasonForLoss'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
            csvFormatter: (value, row) =>
                    value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'winLossExplanation',
            text: 'Reason Explanation',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                row.opportunityStage === 'Closed - Lost' ? (
                    value ? (
                        value
                    ) : (
                        <NotAvailable
                            section="internal-field"
                            showNotAvailable={true}
                            linkUrl={getUrlLink(
                                row.opportunityKey
                                    ? 'iManage'
                                    : 'sfdclightningOpp',
                                row.opportunityKey ? row.opportunityKey : row.id
                            )}
                        />
                    )
                ) : (
                    'Not Applicable'
                ),
            headerStyle: { fontWeight: '500', width: '105px' },
            hidden: opportunityState === 'open' ? true : false,
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    explanationFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'winLossExplanation'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={
                        opportunityState === 'open'
                            ? openOpportunities
                            : allOpportunities
                    }
                    filterKey={'winLossExplanation'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
            csvFormatter: (value, row) =>
                    value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'engagementManager',
            text: 'EM',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) =>
                value ? (
                    row.engagementManagerIsDeleted ? (
                        asteriskHoverover(value, false)
                    ) : (
                        value
                    )
                ) : (
                    <NotAvailable
                        section="internal-field"
                        showNotAvailable={true}
                        linkUrl={getUrlLink(
                            row.opportunityKey ? 'iManage' : 'sfdclightningOpp',
                            row.opportunityKey ? row.opportunityKey : row.id
                        )}
                    />
                ),
            headerStyle: { fontWeight: '500', width: '105px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    emFilter = filter;
                },
                onFilter: (filterArr, data) =>
                    handleOnFilterTable(filterArr, data, 'engagementManager'),
            }),
            filterRenderer: (onFilter, column) => (
                <FilterMultiSelect
                    onFilter={onFilter}
                    column={column}
                    options={
                        opportunityState === 'open'
                            ? openOpportunities
                            : allOpportunities
                    }
                    filterKey={'engagementManager'}
                    showFilters={showMore ? showFiltersModal : showFilters}
                    currentFilters={currentFilters}
                    staticOption={staticOption}
                    onStaticOptionsUpdate={onStaticOptionsUpdate}
                    filteredData={filteredData}
                />
            ),
            csvFormatter: (value, row) =>
                    value ? value : "Not Applicable/Not Available"
        },
        {
            dataField: 'id',
            text: 'key',
            hidden: true,
            csvExport: false
        },
    ];

    const handleShowMore = () => {
        setShowMore(!showMore);
        clearAllFilters();
    };

    const handleOpportunityStateChange = (from) => {
        if (opportunityState !== from) {
            if (opportunityState === 'open') {
                setOpportunityState('all');
                setOpenOpportunities(account.openOpportunity);
            } else {
                setOpportunityState('open');
                setAllOpportunities(account.allOpportunity);
            }
        }
    };

    const clearAllFilters = () => {
        if (opportunityState !== 'open') {
            // reason & explanation only exist from all -> open
            reasonFilter([]);
            explanationFilter([]);
        }

        // clear filters when switching between all & open
        nameFilter([]);
        closeDateFilter([]);
        oppMdFilter([]);
        pkbFilter([]);
        solutionFilter([]);
        serviceFilter([]);
        stageFilter([]);
        emFilter([]);

        setCurrentFilters({});
        setStaticOption({});
    };

    const showMoreModal = () => {
        return (
            <Modal
                show={showMore}
                onHide={handleShowMore}
                dialogClassName="custom-dialog"
                style={{ minHeight: 600 }}
            >
                <Modal.Header
                    closeButton
                    className="card-style"
                    style={{ borderTopColor: '#00A28F' }}
                >
                    <Modal.Title>
                        {opportunityState === 'open' ? (
                            account && account.name ? (
                                <h4 className="card-title-modal">
                                    <span>Open Opportunities </span>
                                    {account.openOpportunity.length >
                                    0 ? null : (
                                        <NotAvailable
                                            section="internal-section"
                                            showNotAvailable={false}
                                        />
                                    )}
                                </h4>
                            ) : null
                        ) : account && account.name ? (
                            <h4 className="card-title-modal">
                                <span>All Opportunities </span>
                                {account.allOpportunity.length > 0 ? null : (
                                    <NotAvailable
                                        section="internal-section"
                                        showNotAvailable={false}
                                    />
                                )}
                            </h4>
                        ) : null}
                    </Modal.Title>
                    <div className="trigger-filter-modal">
                        <div className="filter-button">
                            <div>
                                {triggerFilter(
                                    showFiltersModal,
                                    setShowFiltersModal
                                )}
                            </div>
                            <div className="clear-filter">
                                <div
                                    className="text-primary"
                                    onClick={clearAllFilters}
                                    style={{
                                        visibility: showFiltersModal
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                >
                                    Clear Filters
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sfdc-contact-owner">
                        {getFormatCompanyLink(account.id, account.name)}
                        <div className="employee-name">
                            Total Open Opps: {account.numberOfOpenOpportunity}
                        </div>
                        <div className="employee-name">
                            Total Opps: {account.numberOfAllOpportunity}
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body
                    style={{
                        overflowX: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingTop: '0px',
                        paddingBottom: '80px',
                    }}
                >
                    <BootstrapTable
                        keyField="id"
                        data={
                            opportunityState === 'open'
                                ? openOpportunities
                                : allOpportunities
                        }
                        columns={OpportunityTableColumns}
                        striped={true}
                        bordered={false}
                        hover={true}
                        filter={filterFactory({ afterFilter })}
                    />
                    {(opportunityState === 'open' &&
                        account.numberOfOpenOpportunity > 25) ||
                    (opportunityState === 'all' &&
                        account.numberOfAllOpportunity > 25) ? (
                        <div style={{ textAlign: 'center' }}>
                            <span>
                                For additional opportunities, see Salesforce.
                            </span>
                            <Button
                                className="hubBtn"
                                style={{
                                    fontSize: '90%',
                                    marginLeft: '10px',
                                    width: '94px',
                                }}
                                href={encodeURI(
                                    `${clientSideSettings.sfdcLightningOpportunity}${account.id}/related/Opportunities/view`
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    style={{ height: '16px' }}
                                    src={salesforce}
                                    alt={'salesforce'}
                                />
                            </Button>
                        </div>
                    ) : null}
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <Card>
            <Card.Body
                className="card-style"
                style={{
                    borderTopColor: '#00A28F',
                    overflowY: 'hidden',
                    overflowX:
                        (account.openOpportunity &&
                            account.openOpportunity.length > 0) ||
                        (account.allOpportunity && account.allOpportunity)
                            ? 'auto'
                            : 'none',
                }}
            >
                {account && account.name ? (
                    <>
                        <div
                            className="space-between"
                            style={{ fontFamily: 'inherit' }}
                        >
                            <div className="card-title">
                                <AssignmentOutlinedIcon
                                    fontSize="large"
                                    style={{ float: 'left' }}
                                />
                                <button
                                    className="opportunity-title"
                                    onClick={(e) =>
                                        handleOpportunityStateChange('open', e)
                                    }
                                    style={{
                                        float: 'left',
                                        fontWeight:
                                            opportunityState === 'open'
                                                ? 'bold'
                                                : null,
                                        borderBottom:
                                            opportunityState === 'open'
                                                ? '3px solid #F6871F'
                                                : 'none',
                                        color: '#004068',
                                    }}
                                >
                                    <span>Open Opportunities </span>
                                    {(account.openOpportunity &&
                                        account.openOpportunity.length > 0) ||
                                    account.isExternalOnly ? null : (
                                        <NotAvailable
                                            section="internal-section"
                                            showNotAvailable={false}
                                            linkUrl={getUrlLink(
                                                'imanageFilter',
                                                account.id
                                            )}
                                        />
                                    )}
                                </button>
                                <div
                                    style={{
                                        height: 60,
                                        width: 1,
                                        background: '#000',
                                        float: 'left',
                                        marginLeft: 30,
                                        marginRight: 30,
                                        marginTop: -10,
                                    }}
                                ></div>
                                <button
                                    className="opportunity-title"
                                    onClick={(e) =>
                                        handleOpportunityStateChange('all', e)
                                    }
                                    style={{
                                        float: 'center',
                                        fontWeight:
                                            opportunityState === 'all'
                                                ? 'bold'
                                                : null,
                                        borderBottom:
                                            opportunityState === 'all'
                                                ? '3px solid #F6871F'
                                                : 'none',
                                        marginLeft: '0',
                                        color: '#004068',
                                    }}
                                >
                                    <span>All Opportunities </span>
                                    {(account.allOpportunity &&
                                        account.allOpportunity.length > 0) ||
                                    account.isExternalOnly ? null : (
                                        <NotAvailable
                                            section="internal-section"
                                            showNotAvailable={false}
                                            linkUrl={getUrlLink(
                                                'imanageFilter',
                                                account.id
                                            )}
                                        />
                                    )}
                                </button>
                            </div>
                            <div style={{ display: 'flex' }}>   
                                {opportunityState === 'open' && 
                                    account.openOpportunity &&
                                    account.openOpportunity.length > 0 
                                    ? (<Export 
                                        keyField="id"
                                        data={openOpportunities}
                                        columns={OpportunityTableColumns}
                                        accountName={account.name}
                                        exportTableType="OpenOpportunities"
                                        >
                                    </Export>)
                                    : null}
                                {opportunityState === 'all' && 
                                    account.allOpportunity &&
                                    account.allOpportunity.length > 0 
                                    ? (<Export 
                                        keyField="id"
                                        data={allOpportunities}
                                        columns={OpportunityTableColumns}
                                        accountName={account.name}
                                        exportTableType="AllOpportunities"
                                        >
                                    </Export>)
                                    : null}

                                <div className="filter-button">
                                    <div>
                                        {opportunityState === 'open'
                                            ? account.openOpportunity &&
                                              account.openOpportunity.length > 1
                                                ? triggerFilter(
                                                      showFilters,
                                                      setShowFilters
                                                  )
                                                : null
                                            : account.allOpportunity &&
                                              account.allOpportunity.length > 1
                                            ? triggerFilter(
                                                  showFilters,
                                                  setShowFilters
                                              )
                                            : null}
                                    </div>
                                    <div className="clear-filter">
                                        <div
                                            className="text-primary"
                                            onClick={clearAllFilters}
                                            style={{
                                                visibility:
                                                    ((opportunityState ===
                                                        'open' &&
                                                        account.openOpportunity &&
                                                        account.openOpportunity
                                                            .length > 0) ||
                                                        (opportunityState ===
                                                            'all' &&
                                                            account.allOpportunity &&
                                                            account
                                                                .allOpportunity
                                                                .length > 0)) &&
                                                    showFilters
                                                        ? 'visible'
                                                        : 'hidden',
                                            }}
                                        >
                                            Clear Filters
                                        </div>
                                    </div>
                                </div>
                                <div className="sfdc-contact-owner">
                                    {getFormatCompanyLink(
                                        account.id,
                                        account.name
                                    )}
                                    <div className="employee-name">
                                        Total # of Open Opps:{' '}
                                        {account.numberOfOpenOpportunity}
                                    </div>
                                    <div className="employee-name">
                                        Total # of Opps:{' '}
                                        {account.numberOfAllOpportunity}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
                {opportunityState === 'open' ? (
                    account.openOpportunity &&
                    account.openOpportunity.length > 0 ? (
                        <div className="result-table">
                            <BootstrapTable 
                                        keyField="id"
                                        data={openOpportunities}
                                        columns={OpportunityTableColumns}
                                        striped={true}
                                        bordered={false}
                                        hover={true}
                                        pagination={paginationFactory({
                                            page: 1,
                                            sizePerPage: 5,
                                            hideSizePerPage: true,
                                            hidePageListOnlyOnePage: true,
                                        })}
                                        filter={filterFactory({
                                            afterFilter,
                                        })}
                                        />
                            <Button
                                style={{
                                    float: 'right',
                                    margin: '2%',
                                    display:
                                        openOpportunities.length <= 5
                                            ? 'none'
                                            : null,
                                }}
                                onClick={handleShowMore}
                                variant="outline-secondary"
                            >
                                {'View More'}
                            </Button>
                            {showMoreModal()}
                        </div>
                    ) : account.isExternalOnly ? (
                        renderExternalNotAvailable('account')
                    ) : (
                        <>
                            <hr />
                            <div className="not-available">Not Available</div>
                        </>
                    )
                ) : account.allOpportunity &&
                  account.allOpportunity.length > 0 ? (
                    <div className="result-table">
                        <BootstrapTable
                            keyField="id"
                            data={allOpportunities}
                            columns={OpportunityTableColumns}
                            striped={true}
                            bordered={false}
                            hover={true}
                            pagination={paginationFactory({
                                page: 1,
                                sizePerPage: 5,
                                hideSizePerPage: true,
                                hidePageListOnlyOnePage: true,
                            })}
                            filter={filterFactory({
                                afterFilter,
                            })}
                        />
                        <Button
                            style={{
                                float: 'right',
                                margin: '2%',
                                display:
                                    allOpportunities.length <= 5
                                        ? 'none'
                                        : null,
                            }}
                            onClick={handleShowMore}
                            variant="outline-secondary"
                        >
                            {'View More'}
                        </Button>
                        {showMoreModal()}
                    </div>
                ) : account.isExternalOnly ? (
                    renderExternalNotAvailable('account')
                ) : (
                    <>
                        <hr />
                        <div className="not-available">Not Available</div>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};
